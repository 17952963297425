/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget-richcontent-with-image__imagecopyright {
  font-size: 15px;
  line-height: 18px;
  color: #767676;
}

.widget--background .widget-image__copyrightholder {
  color: #FFFFFF;
}

figure.widget-richcontent-with-image__imagewrapper {
  margin: 0;
}

@media (max-width: 640px) {
  .widget-richcontent-with-image {
    grid-column: 1/span 27;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-richcontent-with-image__wrapper {
    grid-column: 3/26;
  }
  .widget-richcontent-with-image__title {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .widget-richcontent-with-image__imagewrapper {
    margin-bottom: 15px;
  }
  .widget-richcontent-with-image__image {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 641px) and (max-width: 1023px) {
  .widget-richcontent-with-image {
    grid-column: 1/span 27;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-richcontent-with-image__wrapper {
    grid-column: 3/26;
  }
  .widget-richcontent-with-image__title {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .widget-richcontent-with-image__imagewrapper {
    float: right;
    width: 211px;
    margin-left: 30px;
    margin-bottom: 20px;
  }
  .widget-richcontent-with-image__image {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 1024px) {
  .widget-richcontent-with-image {
    grid-column: 1/span 27;
  }
  .widget-richcontent-with-image__wrapper {
    grid-column: 1/span 27;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-richcontent-with-image__title {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .widget-richcontent-with-image--focusleft .widget-richcontent-with-image__imagewrapper {
    grid-column: 3/span 9;
    grid-row: 1/span 2;
  }
  .widget-richcontent-with-image--focusleft .widget-richcontent-with-image__image {
    width: 100%;
  }
  .widget-richcontent-with-image--focusleft h2 {
    grid-column: 13/span 13;
    grid-row: 1;
  }
  .widget-richcontent-with-image--focusleft .widget-richcontent-with-image__content {
    grid-column: 13/span 13;
    grid-row: 2;
  }
  .widget-richcontent-with-image--focusright .widget-richcontent-with-image__imagewrapper {
    grid-column: 17/span 9;
    grid-row: 1/span 2;
  }
  .widget-richcontent-with-image--focusright .widget-richcontent-with-image__image {
    width: 100%;
  }
  .widget-richcontent-with-image--focusright h2 {
    grid-column: 3/span 13;
    grid-row: 1;
  }
  .widget-richcontent-with-image--focusright .widget-richcontent-with-image__content {
    grid-column: 3/span 13;
    grid-row: 2;
    width: 100%;
  }
}