.w-details {
  color: var(--tagcolor-darkpurple);
}
.w-details + .w-details {
  margin-top: 4px;
}
.w-details summary {
  background: var(--color-grey-bg);
  padding: 20px 16px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  transition: background 0.3s;
}
.w-details summary:hover {
  background: #dad6dd;
}
.w-details summary::-webkit-details-marker {
  display: none;
}
.w-details summary::marker {
  display: none;
}
.w-details summary:after {
  /*
  @include fontawesome-solid;
  content: $fa-angle-down;
  */
  font: var(--fontawesome-solid);
  content: var(--fa-angle-down);
  font-size: 23px;
  position: absolute;
  right: 12px;
  height: 20px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.w-details[open] summary {
  margin-bottom: 0;
}
.w-details[open] summary:after {
  content: var(--fa-angle-up);
}
.w-details__block,
.w-details .widget--rovid__download, .w-details.rovid-subtitles .w-details__content {
  background: #F3F2F4;
  padding: 13px 16px;
}
.w-details.rovid-subtitles .w-details__content {
  margin-top: 3px;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}
.w-details__block--link {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: background 0.3s;
}
.w-details__block--link:hover {
  background: #e6e4e8;
}
.w-details__block--link:hover .w-details__title, .w-details__block--link:hover .w-details__details {
  transform: translateX(2px);
}
.w-details__block + .w-details__block {
  margin-top: 1px;
}
.w-details .w-details__title, .w-details .w-details__details {
  transition: transform 0.3s;
}
.w-details__title {
  font-size: 18px;
  color: var(--tagcolor-darkpurple);
  line-height: 28px;
  font-weight: 700;
}
.w-details__details {
  font-size: 18px;
  line-height: 24px;
  color: var(--rtd-text-color-on-grey);
}
.w-details__details--srt {
  font-size: 20px;
  color: #333333;
  line-height: 28px;
}