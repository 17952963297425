/*
FIXME: make .regio__readmore also use this;
*/

  /*.regio__readmore*/
/*
  .whcomponent-showmoretoggle
  {

    // DON'T use display: none;, it'll destroy the keyboard focus which is on this button
    //display: none;
    //visibility: hidden; // This returns the focus back to <body> while retaining the position in the tab stack
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
}
*/

/*
// By using the existing focus we prevent the screenreader from having to announce a new focus
// and instead can just announce the new aria-expanded state
.regio__readmore:focus-visible + .regio__description
{
  @include default-focus-effect;
  outline-offset: 5px;
}
*/

/*.regio__readmore*/
.whcomponent-showmoretoggle
{
  /*@include clearbuttonelementstyling;*/
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;



  cursor: pointer;

  margin-top: 5px;

  display: flex;
  align-items: baseline;

  color: var(--tagcolor-darkpink);
  font: 14px/24px var(--rtd-text-fontfamily);
  text-transform: uppercase;
}

.whcomponent-showmoretoggle::before
{
  /*font-size: 14px;*/
  /*@include fontawesome-regular;*/
  font: 14px var(--font-awesome);
  /*content: $fa-plus;*/
  content:  var(--fa-plus);

  width: 24px;
}

.whcomponent-showmoretoggle[aria-expanded="true"]::before
{
  content: var(--fa-minus);
}


  .pvoverview-filters__morebutton[aria-expanded="true"]
, .pvoverview-filters__lessbutton[aria-expanded="false"]
{
  width:  100%;
  height: 1px;
  margin-top: -1px;

  color: transparent;
  font-size: 0;
}

  .pvoverview-filters__morebutton[aria-expanded="true"]::before
, .pvoverview-filters__lessbutton[aria-expanded="false"]::before
{
  color: transparent;
  font-size: 0;
}
