
/*
NOTES:

States:
- .podcastwidget--playing
*/

.widget--podcast
{
  --podcast-pad-h: 15px;
  --podcast-pad-v: 15px;
  --podcast-border: 2px solid var(--themecolor-pink);

  --podcast-small-font:           15px/22px var(--rtd-text-fontfamily);

  --podcast-series-title-color:   var(--color-theme);
  --podcast-series-title-font:    var(--podcast-small-font);

  --podcast-episode-title-color:  var(--color-theme);
  --podcast-episode-title-font:   var(--rtd-heading-weight-bold) 23px/24px var(--rtd-heading-fontfamily);

  --podcast-descriptionpanel-background: #F2F2F2;
  --podcast-descriptionpanel-font: 17px/24px var(--rtd-text-fontfamily);



  --podcast-thumbnail-size:    150px;
  --podcast-thumbnail-radius:  6px;

  --podcast-playbutton-background: var(--themecolor-pink);
  --podcast-playbutton-hover-background: var(--themecolor-pink-hover);

  --podcast-slider-height:     12px;
  --podcast-slider-radius:     6px;
  --podcast-slider-background: #D9D9D9;

  --podcast-slider-thumb-size: 24px;
  --podcast-slider-thumb-background: var(--themecolor-pink);
/*  --podcast-slider-thumb-boxshadow: 0 0 0 10px #5F0EAE24;*/
/*  --podcast-slider-thumb-boxshadow: 0 0 0 10px rgba(95, 14, 174, 0.14);*/
  --podcast-slider-thumb-boxshadow: none;

  /* NOTE: Safari uses bg color #EFEFEF with border #B4B4B4 */
  --podcast-playback-position-background-color: #D9D9D9;
  --podcast-playback-position-border-color:     #D9D9D9; /* The border must ensure a 3:1 contrast (#949494 on #FFFFFF is 3:1) */
  --podcast-playback-progress-background:       var(--themecolor-pink);


  --icon-applepodcast-color: var(--themecolor-pink);

  --contentpanels-radius-small: 0px;
}

.podcastwidget__description
{
  --podcast-pad-h: 17px; /* padding + difference in border width */
}



.podcastwidget__content
{
  position: relative; /* position so we are drawn over the "description"-panel that's moved to be behind us */
  background: #FFFFFF;

  grid-column: var(--pagegrid-col-default);

  border: var(--podcast-border);
  border-radius: var(--contentpanels-radius-small);

  padding: var(--podcast-pad-v) var(--podcast-pad-h);
}

.podcastwidget__image
{
  flex: none;
  width:  var(--podcast-thumbnail-size);
  height: var(--podcast-thumbnail-size);
  border-radius: var(--podcast-thumbnail-radius);
  margin-right: 20px;
}

/* placeholder */
div.podcastwidget__image
{
  background-color: #F0F0F0;
}



.podcastwidget__podcasttitle
{
  color: #767676;
  font: var(--podcast-series-title-font);
}

.podcastwidget__publicationdate
{
  font: var(--podcast-small-font);

  display: inline-block;
  white-space: nowrap;
}

.podcastwidget__episodetitle
{
  color: var(--podcast-episode-title-color);
  font: var(--podcast-episode-title-font);
}

.podcastwidget__playback
{
  display: flex;
}

  .podcastwidget__playbutton
  {
    border: 0;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    background: var(--podcast-playbutton-background);
    transition: background 0.2s;

    cursor: pointer;

    outline-offset: 4px;

    margin-right: 15px;
  }

  .podcastwidget__playbutton::before
  {
    content: var(--fa-play);
    font: var(--fontawesome-solid);
    font-size: 17px;
    color: #FFFFFF;

    margin-left: 4px;
    margin-top: 1px;
  }

  .podcastwidget--playing .podcastwidget__playbutton::before
  {
    font: var(--fontawesome-solid);
    content: var(--fa-pause);
    font-size: 20px;

    margin-left: 0;
    margin-top: 2px;
   }

  .podcastwidget__playbutton:hover
  {
    background: var(--podcast-playbutton-hover-background);
  }

  .podcastwidget__playback__time
  {
    flex: 1 0 0;

    align-self: center;
  }

      .podcastwidget__timeline
      {
        margin: 10px 0 0 0;

        display: block; /* otherwise it'll be inline and you'll get the line-height (and whitespace) around it */
        width: 100%;
        height: 12px;
        border-radius: 6px;

        cursor: pointer;

        border-left: 1px solid var(--podcast-playback-position-border-color);
        border-right: 1px solid var(--podcast-playback-position-border-color);

        border-radius: 5px;

        /*
        background-color: var(--podcast-playback-position-background-color);
        background-size:  var(--podcast-playback-position-percentage) 100%;
        background-image: linear-gradient(var(--color-theme), var(--color-theme));
        */

        background-repeat: no-repeat;

        /*
        NOTE:
        - background 1 - shows the playback progress (purple bar which gets larger by using the background-size to stretch the width)
        - background 2 - emulate an 1px border, however the first background is drawn over this border
        */
        background-size: var(--podcast-playback-position-percentage) 100%
                       , 100% 100%;
        background-image:
                    linear-gradient( var(--podcast-playback-progress-background), var(--podcast-playback-progress-background) )
                  , linear-gradient( var(--podcast-playback-position-border-color) 0px
                                   , var(--podcast-playback-position-background-color) 1px
                                   , var(--podcast-playback-position-background-color) calc(100% - 1px)
                                   , var(--podcast-playback-position-border-color) 100%
                                   );
      }

      .podcastwidget--hasbeenplaying .podcastwidget__timeline
      {
/*          border-left-width: 0px;*/
      }

      .podcastwidget__volume_and_time
      {
        display: flex;
        align-items: baseline;

        line-height: 100%;
      }

          .podcastwidget__time
          {
            margin-left: auto;
            margin-top: 5px;

            display: flex;
            align-items: baseline;
          }

                .podcastwidget__time-current
              , .podcastwidget__time-duration
              {
                color: #767676;
                font: var(--podcast-small-font)
              }

              .podcastwidget__time-divider
              {
                margin-left:  4px;
                margin-right: 4px;
                opacity: 0.4;
                font-size: 80%;
              }
              .podcastwidget__time-divider::before
              {
                content: "|";
              }


.podcastwidget__resources
{
  display: flex;
  align-items: baseline;

  flex-wrap: wrap; /* zorg dat "View transcript" and "Also on:" are on seperate lines if there don't fit on a single line without wrapping text */
  column-gap: 30px;
  row-gap: 15px;
  justify-content: space-between; /* when on the same line push the content to the sides */
}

    .podcastwidget__downloadtranscript
    {
      color: var(--rtd-link-color);
      font: var(--podcast-small-font);
      text-decoration: none;
      text-underline-offset: 3px;
    }

    .podcastwidget__downloadtranscript:hover
    {
      color: var(--rtd-link-hover-color);
      text-decoration: underline;
    }

    .podcastwidget__externalproviders
    {
      color: #767676;
      font: var(--podcast-small-font);

      flex: none;

      display: flex;
      align-items: baseline;
    }

    .podcastwidget__externalproviders__items
    {
      display: flex;
    }

    .podcastwidget__externalproviders span
    {
      margin-right: 15px;
    }

    .podcastwidget__externalproviders a
    {
      padding: 5px; /* increate hitarea and size of our hover background */
      cursor: pointer;
    }

    .podcastwidget__externalproviders a:last-child
    {
      margin-right: -5px; /* fix alignment with content above */
    }

    .podcastwidget__externalproviders a:hover
    {
      background-color: #F0F0F0;
      border-radius: 3px;
    }

    .podcastwidget__externalproviders svg
    {
      vertical-align: middle;
      width:  21px;
      height: 21px;
      --icon-color:        var(--component-sharepage-button-color);
      --icon-within-color: #FFFFFF;

      --icon-color-g1: #0066D9;
      --icon-color-g2: #4285F4;
      --icon-color-g3: #EA4335;
      --icon-color-g4: #34A853;
      --icon-color-g5: #FAB908;
    }

    .podcastwidget__externalproviders:hover svg
    {
/*
      // --icon-color:        var(--component-sharepage-button-hover-color);
      // --icon-within-color: var(--component-sharepage-button-hover-color-inside);
*/
    }

        .podcastwidget__googlepodcast
        {
          margin-left: 30px;
        }

        .podcastwidget__spotify-podcasts svg
        {
          --icon-color: #1ED760;
        }

.podcastwidget__description
{
  margin-top: calc(-1 * var(--contentpanels-radius-small));

  border-radius: 0 0 var(--contentpanels-radius-small) var(--contentpanels-radius-small);

  background: var(--podcast-descriptionpanel-background);

  padding: calc(15px + var(--contentpanels-radius-small))
           var(--podcast-pad-h)
           15px
           var(--podcast-pad-h)
           ;

  font: var(--podcast-descriptionpanel-font);
  --rtd-text-font: var(--podcast-descriptionpanel-font);
}

/* Temporary workaround for CSS issue up till about Webhare v5.6.1 */
.wh-rtd .podcastwidget__description
{
  padding: 15px var(--podcast-pad-h);
}

.podcast-description-ellipsis .podcastwidget__description .rtdcontent
{
  /* NOTE: Safari applies the line-clamp to all paragraphs within this element
           instead of the combined text lines of content/paragraphs within.
           There for Safari we need to set limit on the visual height. */
  max-height: calc(24px * 3 + 6px);

/* // ONLY works with -webkit-box*/
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.podcastwidget__description__readmorebutton
{
  border: 0;
  padding: 0;
  background: none;
/*  font: inherit;*/
  font: 500 14px var(--rtd-heading-fontfamily);
  text-transform: uppercase;

  color: var(--rtd-link-color);

  box-shadow: none; /* Tollium styles native <button> element */

  margin-top: 3px;
  display: flex;
  align-items: center;

  cursor: pointer;
}

.widget--podcast.widget--readmore-activated .podcastwidget__description .rtdcontent
{
  -webkit-line-clamp: initial;
  max-height: none;
}

.widget--podcast.widget--readmore-activated .podcastwidget__description__readmorebutton
{
  display: none;
}

.podcastwidget__description__readmorebutton::before
{
  content: var(--fa-plus);
  font: var(--fontawesome-regular);
  font-size: 13px;
  margin-right: 6px;

  text-decoration: none;
}

.podcastwidget__description__readmorebutton .buttontext
{
  text-underline-offset: 3px;
}

.podcastwidget__description__readmorebutton:hover .buttontext
{
  text-decoration: underline;
}

.widget--podcast input[type=range]
{
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
}

.widget--podcast input[type=range]:focus
{
  outline-offset: 4px;
}

/*
.podcastwidget__podcasttitle
{
  font-size: 0;
  color: transparent;
}

/ * If the seperator character is on the first line it'll be shown too * /
.podcastwidget__podcasttitle:first-line
{
  color: #767676;
  font: var(--podcast-series-title-font);
}

  .podcastwidget__podcasttitle__title
, .podcastwidget__publicationdate
{
  color: #767676;
  font: var(--podcast-series-title-font);
}

.part--withdate
{
  white-space: nowrap;
}
*/



/* Inner layout of the podcast widget for tablet and desktop */
@media (min-width: 668px)
{
  .podcastwidget__content
  {
    display: flex;
  }

  .podcastwidget__rightside
  {
    flex: 1 0 0;

    /* If there's not much text we would like to have our content perfectly
       aligned to the image next to it. */
    display: flex;
    flex-direction: column;
  }

      /* Define the minimum distance */
        .podcastwidget__playback
      , .podcastwidget__resources
      {
        margin-top: 12px;
      }

      /* When the image beside us takes more room than we need
         (for our content and "default" margin) distribute the extra space. */
        .podcastwidget__meta
      , .podcastwidget__playback
      {
        margin-bottom: auto;
      }

      .podcastwidget__resources
      {
        margin-bottom: -8px; /* align the underline of the "view transcript" link to the bottom of the image */
      }

      .podcastwidget__resources:not(.includes-transcript) .podcastwidget__externalproviders
      {
        margin-left: auto;
      }


/* TRAIT - Center timeline playbutton and slider AND keep time (progress/duration) indicator in bottom right corner)
   FIXME: have this in a container query in the future ?
*/
      .podcastwidget__timeline
      {
        margin-top: 0 !important;
      }

      .podcastwidget__playback
      {
        position: relative;
      }

      /* So the time doesn't stretch .podcastwidget__playback__time
         so the progress slider is vertically centered. */
      .podcastwidget__time.podcastwidget__time
      {
        margin-top: 0;

        position: absolute;
        right: 0;
        bottom: -5px;
        margin: 0;
      }
/**********************************/
}

/*
@media (max-width: 767px)
{
  .podcastwidget__content
  {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content min-content;
  }

  .podcastwidget__rightside
  {
    display: contents;
  }

  .podcastwidget__image
  {
    grid-column: 1;
    grid-row: 1 / span 2;
    max-width: 30vw;
    aspect-ratio: 1 / 1;
    height: auto;
  }

  .podcastwidget__meta
  {
    grid-column: 2;
    grid-row: 1;
  }

  .podcastwidget__resources
  {
    grid-column: 2;
    grid-row: 2;
  }

  .podcastwidget__playback
  {
    margin-top: 15px;
    grid-column: 1 / span 2;
    grid-row: 3;
  }
}

@media (max-width: 500px)
{
  .podcastwidget__resources
  {
    margin-left: 0;
    flex-direction: column;
  }

  .podcastwidget__externalproviders
  {
    grid-column: 1;
    grid-row: 3;
  }

  .podcastwidget__externalproviders span
  {
    display: none;
  }
/ *
  .podcastwidget__downloadtranscript
  {
    color: #767676 !important;
  }
* /
}
*/


/* Mobile layout and tablets smaller than an iPad */
@media (max-width: 667px)
{
  .podcastwidget__content
  {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content min-content min-content;
  }

  .podcastwidget__rightside
  {
    display: contents;
  }

  .podcastwidget__meta
  {
    display: contents;
  }

  .podcastwidget__podcasttitle
  {
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  .podcastwidget__episodetitle
  {
    grid-column: 2;
    grid-row: 2 / span 2;
    align-self: center;
  }

  .podcastwidget__image
  {
    grid-column: 1;
    grid-row: 2 / span 2;
    max-width: 30vw;
    aspect-ratio: 1 / 1;
    height: auto;
  }

  .podcastwidget__podcasttitle
  {
    margin-bottom: 15px;
  }

  .podcastwidget__playback
  {
    margin-top: 15px;
    grid-column: 1 / span 2;
    grid-row: 4;
  }

  .podcastwidget__resources
  {
    margin-top: 15px;
    grid-column: 1 / span 2;
    grid-row: 5;
  }

/* TRAIT - Center timeline playbutton and slider AND keep time (progress/duration) indicator in bottom right corner)
   FIXME: have this in a container query in the future ?
*/
      .podcastwidget__timeline
      {
        margin-top: 0 !important;
      }

      .podcastwidget__playback
      {
        position: relative;
      }

      /* So the time doesn't stretch .podcastwidget__playback__time
         so the progress slider is vertically centered. */
      .podcastwidget__time.podcastwidget__time
      {
        margin-top: 0;

        position: absolute;
        right: 0;
        bottom: -5px;
        margin: 0;
      }
/**********************************/
}


/* For mobile screens */
@media (max-width: 500px)
{
  .podcastwidget__externalproviders
  {
    grid-column: 1;
    grid-row: 3;

    display: flex;
    flex-direction: column;
  }

  .podcastwidget__externalproviders span
  {
    margin-right: 0;
  }
/*
  .podcastwidget__downloadtranscript
  {
    color: #767676 !important;
  }
*/
}

