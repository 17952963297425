/** @short The active menu selection will look like a tab from which the dropdown extends.
 *  @long Use this mixin on the .spc-menubar container
 */
/** @short Dropdown appears slightly below the menubar item with an arrow pointing to the menu item it belongs to
 * (FIXME: test)
*/
/** @short Dropdown appears slightly below the menubar item with the top of the dropdown having kind of a half arrow pointing to the menu item the dropdown belongs to
 * (FIXME: test)
*/
.spc-menubar .spc-menubar__item--hassubitems {
  position: relative;
}
.spc-menubar .spc-menubar__pulldown {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

/*
.header-menubar-background
{
  border-bottom: 1px solid var(--color-purple3);
}
*/
ul.spc-menubar__level2 > li {
  border-bottom: 1px solid #C5C5C5;
}

ul.spc-menubar__level2 > li:last-child {
  border-bottom: 0;
}

.spc-menubar-branch--horizontal > li > .spc-menubar__item > .spc-menubar__item__togglebutton {
  visibility: hidden;
}

.spc-menubar-branch--horizontal > li > .spc-menubar__item:focus-within > .spc-menubar__item__togglebutton {
  visibility: visible;
}

.spc-menubar__pulldown {
  --menubar-bar-toggler-color: #333300;
  --menubar-bar-toggler-hover-color: #c10061;
  --menubar-bar-item-hover-background: #e4dce2;
  --menubar-bar-item-hover-color: #333300;
  --menubar-bar-item-hover-decoration: none; /* underline 3px var(--color-salmon); */
  --menubar-level2-toggle-font: 400 11px "Font Awesome 5 Pro"; /* solid */
}

.spc-menubar__pulldown > ul.spc-menubar__level2 {
  max-height: calc(100vh - var(--topbar-height) - var(--menubar-height));
  overflow: auto;
}

html {
  --menubar-level1-openbyhover: true;
  --menubar-autoclose-siblings: false;
  /* NEW settings (SU + ACOi/ZVI) */
  /*
    --sidebar-menu-itemhover-background:     transparent;
    --sidebar-menu-itemhover-textcolor:      var(--color-purple);
    --sidebar-menu-itemhover-textdecoration: underline;


    --site-searchpanel-background: var(--color-purple1);
    --site-menubar-grid-column:    var(--pagegrid-col-verywide-outsidegutter-text);
    // --site-menubar-padding-top:    0;
    // --site-menubar-padding-bottom: 0;
    --site-menubar-logo-width:     142px;
    // --site-menubar-logo-height:    clamp(44px, 11.5vw, 67px);
    --site-menubar-logo-height:    clamp(32px, 8vw, 67px);
    --site-menubar-icon-color:     var(--color-theme);
    --site-menubar-icon-size:      24px;
  */
  --site-menubar-button-padding: 10px 8px; /* to increate the hitarea */
  --menubar-level1-font: 400 20px "Alegreya Sans";
  --menubar-level1-padding: 10px 8px;
  --menubar-level1-padding-left: 8px;
  --menubar-level1-padding-right: 8px;
  --menubar-level1-padding-top: 10px;
  --menubar-level1-padding-bottom: 10px;
  --menubar-level1-spacing: 0px;
  --menubar-level1-spacing-min: 0px;
  --menubar-bar-item-borderadius: 2px;
  --menubar-bar-item-color: #FFFFFF;
  --menubar-bar-item-decoration: none; /* underline 3px transparent; */
  --menubar-bar-item-hover-background: #9F0A4A;
  --menubar-bar-item-hover-color: #FFFFFF;
  --menubar-bar-item-hover-decoration: none; /* underline 3px var(--color-salmon); */
  --menubar-bar-toggler-width: 22px;
  --menubar-bar-toggler-margin: 5px;
  --menubar-bar-toggler-extrahitarea: 7px;
  --menubar-bar-toggler-shifty: 4px;
  --menubar-bar-toggler-font: var(--fontawesome-solid);
  --menubar-bar-toggler-size: 12px;
  --menubar-bar-toggler-content: var(--fa-chevron-down);
  --menubar-bar-toggler-color: #FFFFFF;
  --menubar-bar-toggler-hover-color: #FFFFFF;
  --menubar-pulldown-zindex: 10;
  --menubar-pulldown-minwidth: 250px;
  --menubar-pulldown-maxwidth: 320px;
  --menubar-pulldown-anim-open: menuanim_fadein 0.2s;
  --menubar-pulldown-border: none;
  --menubar-pulldown-border-radius: 0px;
  --menubar-pulldown-padding: 15px;
  --menubar-pulldown-bgcolor: #FFFFFF;
  --menubar-pulldown-box-shadow: 0 0 7px 6px rgba(0,0,0,0.15);
  --menubar-pulldown-itemhover-bgcolor: #E4DCE2;
  --menubar-pulldown-itemhover-textcolor: #333300;
  --menubar-pulldown-itemhover-textdecoration: none;
  --menubar-level2-color: #333333;
  --menubar-level2-font: 500 16px "Alegreya Sans";
  --menubar-level2-padding-left: 10px;
  --menubar-level2-padding-right: 10px;
  --menubar-level2-padding-top: 10px;
  --menubar-level2-padding-bottom: 9px;
  --menubar-level2-expandicon: var(--fa-chevron-right);
  --menubar-level2-collapseicon: var(--fa-chevron-down);
  --menubar-level2-toggle-font: 400 14px "Font Awesome 5 Pro"; /* solid */
  --menubar-level3-color: #333333;
  --menubar-level3-font: 400 16px "Alegreya Sans";
  --menubar-level3-padding-left: 25px;
  --menubar-level3-padding-right: 10px;
  --menubar-level3-padding-top: 10px;
  --menubar-level3-padding-bottom: 9px;
  --menubar-level3-expandicon: var(--fa-plus);
  --menubar-level3-collapseicon: var(--fa-minus);
  --menubar-level3-toggle-font: 400 14px "Font Awesome 5 Pro"; /* regular */
  --menubar-level4-color: var(--color-theme);
  --menubar-level4-font: 300 15px/20px "Alegreya Sans";
  --menubar-level4-padding-left: 40px;
  --menubar-level4-padding-right: 10px;
  --menubar-level4-padding-top: 6px;
  --menubar-level4-padding-bottom: 5px;
}