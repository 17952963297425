/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
.page--newsletter .mailplusform
{
  @include layout--wide;
}
*/
.mailplusform label {
  cursor: pointer;
}

.mpFormTable {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mpQuestionTable + .mpQuestionTable {
  margin-top: 20px;
}

.mpQuestionTable {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.mpQuestionTable:focus-within .mpFormLabel {
  color: var(--tagcolor-darkpurple);
}

.mpFormLabel {
  flex: 0 0 170px;
  padding-right: 30px;
}

.mpFormField {
  flex: 1 1 0px;
}

.mpFormField > ul {
  margin: 0;
  padding: 0;
}

.mpFormField > input[type=text],
.mpFormField > input[type=email] {
  width: 100%;
}

.mpFormField li {
  display: flex;
  align-items: center;
}

.mpFormField li input + label {
  flex: none;
  margin-right: 10px;
}

.mpFormField li + li {
  margin-top: 10px;
}

.mpMultipleLabel::after {
  position: absolute;
  left: 0;
}

.mpErrorRow {
  width: 100%;
  padding-left: 100px;
  color: #A00;
}

.mpQuestionTable .mpFormField ul {
  list-style: none;
  margin: 0;
}

.mpLabelRow {
  display: flex;
  width: 100%;
}

.mpLabelRow > .submitCell {
  margin-left: auto;
}

.submitButton {
  margin-top: 30px;
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  outline-offset: var(--button-focus-outline-offset);
  cursor: pointer;
  background: var(--button-background);
  color: #FFFFFF;
  border: 0;
}
.submitButton:focus {
  outline: var(--button-focus-outline);
}
.submitButton:hover {
  background: var(--button-hover-background);
  color: #FFFFFF;
  text-decoration: none;
}
.submitButton .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.submitButton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px;
}

@media (max-width: 640px) {
  .mpQuestionTable + .mpQuestionTable {
    margin-top: 18px;
  }
  .mpFormLabel {
    flex: 0 0 100%;
    padding-bottom: 3px;
    font-weight: bold;
  }
}