/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.widget-newsletter__title {
  color: var(--tagcolor-darkpink);
}

.widget-newsletter__heading {
  color: var(--tagcolor-darkpurple);
}

.widget-newsletter__subscribeform {
  position: relative;
}

.widget-newsletter__subscribeform__email {
  height: 49px;
  border: 1px solid #979797;
  border-radius: 3px;
  width: 100%;
  font: 17px var(--rtd-text-fontfamily);
  padding-left: 18px;
  padding-right: 50px;
}

.widget-newsletter__subscribeform__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  font: 17px var(--rtd-text-fontfamily);
  color: var(--tagcolor-darkpink);
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 15px;
}

.widget-newsletter__subscribeform__submit .icon {
  font-size: 20px;
}

.widget-newsletter__moreinfo {
  font: 17px var(--rtd-text-fontfamily);
  font-style: italic;
  color: #6A6A6A;
}

/* LinkedIn */
.widget-newsletter__followbutton {
  text-decoration: none;
  height: 49px;
  background-color: #0078B5;
  color: #FFFFFF;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  cursor: pointer;
  font: 16px/18px var(--rtd-text-fontfamily);
}

.widget-newsletter__followbutton .text {
  padding-top: 5px;
}

.widget-newsletter__followbutton img {
  margin-left: auto;
  width: 102px;
  height: 26px;
}

.widget-newsletter__followbutton:hover {
  background-color: #0089C6;
}

@media (max-width: 639px) {
  .widget-newsletter {
    grid-column: 1/span 27;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 34px;
  }
  .widget-newsletter__image {
    display: none;
  }
  .widget-newsletter__title {
    order: 0;
  }
  .widget-newsletter__subscribeform {
    order: 1;
  }
  .widget-newsletter__moreinfo {
    order: 2;
  }
  .widget-newsletter__follow {
    order: 3;
  }
  .widget-newsletter__title {
    font: var(--rtd-heading-weight-bold) 21px/24px var(--rtd-heading-fontfamily);
    margin-bottom: 20px;
  }
  .widget-newsletter__heading {
    font: var(--rtd-heading-weight-medium) 20px/24px var(--rtd-heading-fontfamily);
    margin-bottom: 12px;
  }
  .widget-newsletter__moreinfo {
    margin-top: 14px;
  }
  .widget-newsletter__follow {
    margin-top: 20px;
  }
  .widget-newsletter__followbutton {
    display: inline-flex;
  }
  .widget-newsletter__followbutton img {
    margin-left: 30px;
  }
}
@media (min-width: 640px) {
  .widget-newsletter {
    grid-column: 1/span 27;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-newsletter::before {
    content: "";
    grid-column: 1/span 27;
    grid-row: 1/span 3;
    background-color: #F7F7F7;
    height: 100%;
  }
  .widget-newsletter__image {
    margin-top: 20px;
    grid-column: 3/span 7;
    grid-row: 1/span 4;
    width: 107%;
    justify-self: end;
    height: auto;
    z-index: 1;
  }
  .widget-newsletter__title,
  .widget-newsletter__moreinfo {
    grid-column-start: 11;
    grid-column-end: -3;
  }
  .widget-newsletter__title {
    grid-row: 1;
  }
  .widget-newsletter__subscribe {
    grid-column: 11/span 7;
    grid-row: 2;
    align-self: end;
  }
  .widget-newsletter__follow {
    grid-column: 19/span 5;
    grid-row: 2;
    align-self: end;
  }
  .widget-newsletter__moreinfo {
    grid-row: 3;
  }
  .widget-newsletter__title {
    padding-top: 65px;
    padding-bottom: 25px;
    font: var(--rtd-heading-weight-bold) 36px/40px var(--rtd-heading-fontfamily);
  }
  .widget-newsletter__heading {
    font: var(--rtd-heading-weight-medium) 23px/28px var(--rtd-heading-fontfamily);
    margin-bottom: 20px;
  }
  .widget-newsletter__moreinfo {
    margin-top: 28px;
    padding-bottom: 45px;
  }
}
@media (min-width: 640px) and (max-width: 1000px) {
  .widget-newsletter__follow {
    grid-column: 19/span 7;
    grid-row: 2;
    align-self: end;
  }
  .widget-newsletter__heading {
    font: 21px/25px var(--rtd-heading-fontfamily);
  }
  .widget-newsletter__title {
    padding-top: 40px;
  }
  .widget-newsletter__moreinfo {
    margin-top: 28px;
    padding-bottom: 40px;
  }
}
@media (min-width: 640px) and (max-width: 800px) {
  .widget-newsletter::before {
    grid-row: 1/span 4;
  }
  .widget-newsletter__subscribe {
    grid-column: 11/span 15;
    grid-row: 2;
    align-self: end;
  }
  .widget-newsletter__follow {
    grid-column: 11/span 15;
    grid-row: 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
  }
  .widget-newsletter__follow > .widget-newsletter__heading {
    margin-bottom: 0;
  }
  .widget-newsletter__moreinfo {
    margin-top: 10px;
  }
  .widget-newsletter__image {
    margin-top: -25px;
    grid-column: 3/span 7;
    grid-row: 1/span 4;
    width: 107%;
  }
}