/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
html:not(.viewmode--map) .praktijkvoorbeelden__map {
  display: none;
}

html.viewmode--map .praktijkvoorbeelden__results {
  display: none;
}
html.viewmode--map .page__balloon {
  display: none;
}

.filteredoverview__feedbackandviewbar {
  grid-column: var(--pagegrid-col-verywide);
}

.filteredoverview__feedback {
  color: inherit;
}

html {
  --overview-items-gap-v: 20px;
  --overview-item-padding-h: 15px;
}

.page--praktijkvoorbeelden {
  --pvheading-margintop: 26px;
  --pvheading-height: 40px;
  --pvheading-marginbottom: 27px;
  --pagegrid-col-pvoverview-right: 9 / 26;
  --pagegrid-col-pvoverview-right-to-edge: 9 / 28;
}
@media (max-width: 799px) {
  .page--praktijkvoorbeelden {
    --pvheading-marginbottom: 20px;
  }
}
.page--praktijkvoorbeelden .page__contentarea {
  grid-template-rows: min-content 1fr;
}
.page--praktijkvoorbeelden .filteredoverview__feedbackandviewbar__columns {
  display: flex;
  align-items: baseline;
}
.page--praktijkvoorbeelden .filteredoverview__left {
  margin-right: auto;
}
.page--praktijkvoorbeelden .filteredoverview__feedback.filteredoverview__feedback {
  font: 16px var(--rtd-text-fontfamily);
}
.page--praktijkvoorbeelden .resultscount__highlight {
  font-weight: bold;
  color: #000000;
}
.page--praktijkvoorbeelden .filteredoverview__right {
  flex: none;
  display: flex;
}
.page--praktijkvoorbeelden .filteredoverview__sortoption {
  white-space: nowrap;
}
.page--praktijkvoorbeelden .filteredoverview__sortoption .labeltext {
  padding-right: 12px;
}

@media (max-width: 799px) {
  .page--praktijkvoorbeelden {
    /* in case we have enough room whe'll show the map fullwidth */
  }
  .page--praktijkvoorbeelden .pvoverview-filters {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    z-index: 29;
    padding: 15px;
    padding-bottom: calc(var(--filteredoverview-teaserbar-height) + 35px);
  }
  .page--praktijkvoorbeelden .filteredoverview__sortoption {
    margin-right: 22px;
    width: 180px;
    max-width: 18vw;
  }
  .page--praktijkvoorbeelden .filteredoverview__sort {
    width: 100%;
  }
  .page--praktijkvoorbeelden.filteredoverview--showfilters, .page--praktijkvoorbeelden.filteredoverview--showfilters body {
    overflow: hidden;
  }
  .page--praktijkvoorbeelden.filteredoverview--showfilters .pvoverview-filters {
    display: block;
    overflow: auto;
  }
  .page--praktijkvoorbeelden .filteredoverview__feedbackandviewbar {
    grid-column: var(--pagegrid-col-verywide);
    --controls_height: 40px;
    margin-top: 0;
    margin-bottom: var(--pvheading-marginbottom);
  }
  .page--praktijkvoorbeelden .praktijkvoorbeelden__results {
    grid-column: var(--pagegrid-col-verywide);
  }
  .page--praktijkvoorbeelden .praktijkvoorbeelden__map {
    grid-column: var(--pagegrid-col-fullwidth);
    height: 60vw;
  }
}
@media (min-width: 800px) {
  html.viewmode--map .pvoverview-filters {
    padding-bottom: 80px;
  }
  .page--praktijkvoorbeelden .pvoverview-filters {
    grid-column: 3/8;
    grid-row: 1/span 2;
  }
  .page--praktijkvoorbeelden .filteredoverview__sortoption {
    margin-right: 22px;
  }
  .page--praktijkvoorbeelden .filteredoverview__sort {
    width: 180px;
    max-width: 14vw;
    min-width: min-content;
  }
  .page--praktijkvoorbeelden .filteredoverview__feedbackandviewbar {
    grid-column: var(--pagegrid-col-pvoverview-right);
    grid-row: 1;
    --controls_height: 40px;
    margin-top: 0;
    margin-bottom: var(--pvheading-marginbottom);
  }
  .page--praktijkvoorbeelden .praktijkvoorbeelden__results {
    grid-column: var(--pagegrid-col-pvoverview-right);
    grid-row: 2;
  }
  .page--praktijkvoorbeelden .praktijkvoorbeelden__map {
    grid-column: var(--pagegrid-col-pvoverview-right-to-edge);
    grid-row: 2;
    position: sticky;
    top: 73px;
    height: calc(100vh - 73px);
    align-self: top;
    margin-top: 0;
  }
}
html.viewmode--map .filteredoverview__sortoption {
  opacity: 0.4;
}
html.viewmode--map .filteredoverview__viewmode {
  margin-left: auto;
}

@media (max-width: 970px) {
  .filteredoverview__sortoption .labeltext {
    display: none;
  }
}
@media (max-width: 500px) {
  .page--praktijkvoorbeelden .filteredoverview__sortoption {
    margin-right: 0;
    width: 100%;
    max-width: none;
  }
  .page--praktijkvoorbeelden .filteredoverview__sort {
    width: 100%;
  }
  .page--praktijkvoorbeelden .filteredoverview__viewmode {
    display: none;
  }
  .page--praktijkvoorbeelden .praktijkvoorbeelden__map {
    display: none;
  }
  .page--praktijkvoorbeelden .praktijkvoorbeelden__results {
    display: block !important;
  }
}
@media (max-width: 600px) {
  .page--praktijkvoorbeelden .filteredoverview__sortoption {
    flex: 1 1 0;
    margin-right: 20px;
    max-width: none;
  }
  .page--praktijkvoorbeelden .filteredoverview__sort {
    width: 100%;
  }
  .page--praktijkvoorbeelden .filteredoverview__feedbackandviewbar {
    margin-top: 0;
  }
  .page--praktijkvoorbeelden .filteredoverview__feedbackandviewbar__columns {
    flex-direction: column;
  }
  .page--praktijkvoorbeelden .filteredoverview__right {
    width: 100%;
    order: -1;
    margin-bottom: 20px;
  }
  .page--praktijkvoorbeelden .filteredoverview__sortoption.filteredoverview__sortoption {
    position: relative;
    width: 100%;
  }
  .page--praktijkvoorbeelden .filteredoverview__sortoption .labeltext {
    display: block;
    background-color: #FFFFFF;
    line-height: 100%;
    position: absolute;
    left: 10px;
    top: 0;
    transform: translateY(-50%);
    padding: 0 5px;
  }
  .page--praktijkvoorbeelden .filteredoverview__sort.filteredoverview__sort {
    width: 100%;
    padding-top: 5px;
    border-color: var(--color-theme);
  }
}
@media (max-width: 699px) {
  .praktijkvoorbeeld + .praktijkvoorbeeld {
    margin-top: var(--overview-items-gap-v);
  }
}
@media (min-width: 700px) {
  .page--praktijkvoorbeelden .praktijkvoorbeeld {
    display: flex;
    margin-bottom: 26px;
    box-shadow: none;
    border: 1px solid #B3B3B3;
  }
  .page--praktijkvoorbeelden .praktijkvoorbeeld__meta {
    flex: 1 1 0;
  }
  .page--praktijkvoorbeelden .praktijkvoorbeeld__top {
    order: 5;
    padding-top: 0;
    margin-bottom: 0;
    width: 17vw;
    min-width: 250px;
    aspect-ratio: 3/2;
    min-height: 100%;
  }
  .page--praktijkvoorbeelden .praktijkvoorbeeld__meta {
    padding: 20px 0;
  }
}