/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
Implementation notes:
- by using grid we can flatten the DOM, which is needed because
  mix-blend-mode affects the parent element
*/
.header__filterbar {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.header__filterbar__content {
  grid-column: var(--pagegrid-col-verywide);
}

.header__filterbar__querycontainer {
  position: relative;
  display: grid;
}

.header__filterbar__query.header__filterbar__query {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 18px;
  width: 100%;
  background-color: #FFFFFF;
  height: calc(var(--header-filterpanel-rowheight) + var(--header-filterpanel-border-width) * 2);
  padding-left: 26px;
  border: var(--header-filterpanel-border-width) solid transparent;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  border-radius: var(--header-filterpanel-radius);
  transition: background-color 0.25s, height 0.25s;
  grid-column: 1;
  grid-row: 1;
}

.header__filterbar__query.header__filterbar__query:focus,
.header__filterbar__query.header__filterbar__query:focus-within,
.header__filterbar__querycontainer:hover .header__filterbar__query,
.page--search .header__filterbar__query.header__filterbar__query {
  outline: 0;
  border: var(--header-filterpanel-border-width) solid rgba(0, 0, 0, 0.5);
}

.header__filterbar__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  align-self: center;
  justify-self: end;
  height: calc(100% - 8px);
  grid-column: 1;
  grid-row: 1;
  margin-right: 4px;
  width: 72px;
  background-color: var(--color-theme);
  color: #FFFFFF;
  border-radius: 25px;
  cursor: pointer;
}

.header__filterbar__submit:hover {
  background-color: var(--color-theme);
}

.header__filterbar__submit .fas {
  font-size: 23px;
}

.header__filterbar__togglefilters {
  display: flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  margin-top: var(--header-filterpanel-border-width);
  width: 200px;
  height: var(--header-filterpanel-rowheight);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  background-color: rgba(86, 20, 95, 0.6);
  transition: background-color 0.25s, height 0.25s;
  border: 1px solid #FFFFFF;
  border-radius: var(--header-filterpanel-radius);
  font-size: 16px; /* was 20px eerder */
  text-align: left;
  color: #FFFFFF;
  cursor: pointer;
}

.header__filterbar__togglefilters:hover {
  background-color: rgba(86, 20, 95, 0.8);
}

.header__filterbar__togglefilters::after {
  margin-left: auto;
  /*
    font: var(--fontawesome-solid);
    content: var(--fa-angle-down);
    font-size: 25px;
  */
  font: var(--fontawesome-regular);
  content: var(--fa-sliders-h);
  font-size: 20px;
}

.header__filterbar__foldout {
  display: none;
  color: #000000;
}

html.filteredoverview--showfilters .header__filterbar__foldout {
  display: block;
}

html.filteredoverview--showfilters .page__body,
html.filteredoverview--showfilters .footer {
  display: none;
}

html.filteredoverview--showfilters body {
  padding-bottom: var(--filteredoverview-teaserbar-height);
}