/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget-richcontent {
  grid-column: 1/span 27;
}
.widget-richcontent.widget--background-blue, .widget-richcontent.widget--background-pink, .widget-richcontent.widget--background-green, .widget-richcontent.widget--background-purple {
  padding-top: var(--panels-padding-top);
  padding-bottom: var(--panels-padding-bottom);
}
.widget-richcontent.widget--background-blue p, .widget-richcontent.widget--background-blue ol, .widget-richcontent.widget--background-blue ul, .widget-richcontent.widget--background-blue h1, .widget-richcontent.widget--background-blue h2, .widget-richcontent.widget--background-blue h3, .widget-richcontent.widget--background-pink p, .widget-richcontent.widget--background-pink ol, .widget-richcontent.widget--background-pink ul, .widget-richcontent.widget--background-pink h1, .widget-richcontent.widget--background-pink h2, .widget-richcontent.widget--background-pink h3, .widget-richcontent.widget--background-green p, .widget-richcontent.widget--background-green ol, .widget-richcontent.widget--background-green ul, .widget-richcontent.widget--background-green h1, .widget-richcontent.widget--background-green h2, .widget-richcontent.widget--background-green h3, .widget-richcontent.widget--background-purple p, .widget-richcontent.widget--background-purple ol, .widget-richcontent.widget--background-purple ul, .widget-richcontent.widget--background-purple h1, .widget-richcontent.widget--background-purple h2, .widget-richcontent.widget--background-purple h3 {
  color: #FFFFFF;
}
.widget-richcontent.widget--background-blue {
  background-color: #3a7aa5;
}
.widget-richcontent.widget--background-pink {
  background-color: #c10061;
}
.widget-richcontent.widget--background-green {
  background-color: #428619;
}
.widget-richcontent.widget--background-purple {
  background-color: #55095f;
}

.widget-richcontent__content {
  display: grid;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

@media (max-width: 599px) {
  .widget-richcontent__content {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
  .widget-richcontent__column + .widget-richcontent__column {
    margin-top: var(--rtd-spacing-default);
  }
  .widget-richcontent__column:first-child > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column:last-child > *:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 600px) {
  .widget-richcontent .widget-richcontent__column > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent .widget-richcontent__column > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent__column:nth-child(1) {
    grid-column: 3/14;
  }
  .widget-richcontent__column:nth-child(2) {
    grid-column: 15/26;
  }
}
@media (min-width: 1000px) {
  .widget-richcontent .widget-richcontent__column:nth-child(1) {
    grid-column: 7/span 7;
  }
  .widget-richcontent .widget-richcontent__column:nth-child(2) {
    grid-column: 15/span 7;
  }
  .widget-richcontent--equalheight .widget-richcontent__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.widget-richcontent .widget-richcontent .widget-richcontent__column.widget-richcontent__column:nth-child(1) {
  grid-column: 3/14;
}
.widget-richcontent .widget-richcontent .widget-richcontent__column.widget-richcontent__column:nth-child(2) {
  grid-column: 15/26;
}

.rtdcontent-nested.rtdcontent-nested .widget-richcontent__content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.rtdcontent-nested.rtdcontent-nested .widget-richcontent__content .widget-richcontent__column.widget-richcontent__column:nth-child(1) {
  flex: 0 0 auto;
  width: calc(50% - 20px);
  min-width: 0;
}
.rtdcontent-nested.rtdcontent-nested .widget-richcontent__content .widget-richcontent__column.widget-richcontent__column:nth-child(2) {
  flex: 0 0 auto;
  width: calc(50% - 20px);
  min-width: 0;
}