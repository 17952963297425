/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.page__contentarea {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

@media (max-width: 1023px) {
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--autoalign > * {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--autoalign > * {
    grid-column: var(--pagegrid-col-default);
  }
}
.page__contentarea--formwebtool {
  padding-top: 50px;
}

.page--formwebtool .page__contentarea > .wh-form {
  grid-column: var(--pagegrid-col-verywide);
}

.page--formwebtool .page__contentarea > .page-header__title {
  grid-column: var(--pagegrid-col-verywide);
}

/*

.page__contentarea--formwebtool > .wh-form
{
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
}
*/
.page__body--disablegridlayout {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

.page__body--disablegridlayout > .page__contentarea {
  display: block;
  grid-column: var(--pagegrid-col-default);
}

/* Support grid layout for formwebtool documents

   - Don't use a wrapper, becauce it will override the specificy and therefore overriden the layout of all widgets!
   - However in the future we may need to change the widgets CSS (to have a higher specificy) so we can support other .wh-form usage too

   - In the future we may be able to use :where(.page__contentarea > .wh-form), because the selectors in :where have 0 specificy

*/
@media (max-width: 1023px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-default);
  }
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page,
.wh-form--webtoolform .wh-form__fieldgroup--richtext {
  grid-column: var(--pagegrid-col-fullwidth);
}

.wh-form--webtoolform .wh-form__richtext {
  width: 100%;
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page--visible,
.wh-form--webtoolform .wh-form__richtext {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

@media (max-width: 1024px) {
  html.pageheader--inflow .deeplinks-wrapper {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1025px) {
  html.pageheader--inflow .page__contentarea {
    padding-top: 50px;
  }
}

@media (max-width: 1024px) {
  html.pageheader--block .deeplinks-wrapper {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1025px) {
  html.pageheader--block .page__contentarea--rtddoc > :first-child {
    margin-top: 50px;
  }
  html.pageheader--block .page__contentarea--rtddoc > .deeplinks-wrapper > .deeplinks {
    /*
    NOTES:
    - don't use margin-top or padding-top on .deeplinks_wrapper, it'll stretch the first row creating a white space
    - by applied padding-top (or margin-top) to the deeplinks (which are taken out of flow) we can create a distance and still allow the first widget in the RTD to stick to the header
    */
    padding-top: 50px;
  }
}
html.pageheader--block .page__contentarea:not(.page__contentarea--rtddoc) {
  padding-top: 50px;
}
html.pageheader--block.page--eventsoverview .page__contentarea, html.pageheader--block.page--newsoverview .page__contentarea, html.pageheader--block.page--resourcesoverview .page__contentarea {
  padding-top: 0;
}

.page__contentarea .page__balloon {
  grid-column: var(--pagegrid-col-fullwidth);
  height: 80px;
}
@media (max-width: 500px) {
  .page__contentarea .page__balloon {
    height: 30px;
  }
}

.widget--opaque + .page__balloon {
  display: none;
}

body > .page__balloon {
  flex: 1 0 auto;
  height: 80px;
}
@media (max-width: 500px) {
  body > .page__balloon {
    height: 30px;
  }
}

.page__contentarea.headerisopaque > .widget--opaque:first-child,
.page--withfilterpanel > .widget--opaque:first-child,
html.pageheader--block .page__contentarea .widget--opaque:first-child {
  margin-top: 0;
}

html.pageheader--inflow .page__contentarea > :first-child,
html.pageheader--block .page__contentarea > :first-child {
  margin-top: 30px;
}

.page__contentarea .widget--opaque:last-child {
  margin-bottom: 0;
}

.widget--opaque + .widget--opaque {
  margin-top: 0;
}