/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.widget-praktijkvoorbeelden {
  grid-column: 1/span 27;
  padding-top: var(--panels-padding-top);
  padding-bottom: vaR(--panels-padding-bottom);
  background-color: #F7F7F7;
}

.widget-pv__itemswrapper {
  grid-column: 3/26;
}

.widget-pv__items {
  grid-row: 2;
}

.widget-pv__bullets {
  margin-top: 5px;
  grid-row: 3;
  grid-column: 3/26;
  justify-content: center;
}

.widget-pvt__bullets > .carrouselbullet::before {
  background-color: var(--tagcolor-darkpurple);
}

.widget-pv__introblock {
  display: grid;
  align-self: start;
  grid-template-columns: 100%;
}

.widget-pv__introblock::before {
  grid-column: 1;
  grid-row: 1;
  content: "";
}

.widget-pv__introblock__content {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
}

.widget-pv__introblock__heading {
  color: var(--tagcolor-darkpink);
  font: var(--rtd-heading-weight-bold) 36px/40px var(--rtd-heading-fontfamily);
}

.widget-pv__introblock__text {
  margin-top: 20px;
  font: 20px/28px var(--rtd-text-fontfamily);
  margin-bottom: 30px;
}

.praktijkvoorbeelden__introblock__button {
  margin-top: auto;
}

.widget-pv__items .praktijkvoorbeeld {
  padding: 0;
}
.widget-pv__items .praktijkvoorbeeld__title {
  padding: 0;
}

.praktijkvoorbeeld--maplink .praktijkvoorbeeld__title {
  color: var(--tagcolor-darkpink);
  display: flex;
  align-items: center;
  font-weight: normal;
  text-decoration: underline;
}

.praktijkvoorbeeld--maplink .praktijkvoorbeeld__title span {
  margin-right: 10px;
}

.praktijkvoorbeeld--maplink .praktijkvoorbeeld__title span::before {
  display: inline-block;
}

@media (min-width: 800px) {
  .widget-praktijkvoorbeelden {
    grid-column: 1/span 27;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-pv__mobile-heading,
  .widget-pv__mobile-text {
    display: none;
  }
  .widget-pv__itemswrapper > .widget-pv__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
    gap: 30px;
  }
  .widget-pv__overviewlinkbutton-mobile.widget-pv__overviewlinkbutton-mobile,
  .widget-pv__overviewmaplinkbutton-mobile.widget-pv__overviewmaplinkbutton-mobile {
    display: none;
  }
}
.widget-pv__topbar {
  padding-top: 20px;
  padding-bottom: 20px;
}

.widget-pv__title {
  font: var(--rtd-heading-weight-bold) 21px/24px var(--rtd-heading-fontfamily);
}

@media (max-width: 799px) {
  .widget-pv__mobile-heading {
    color: var(--tagcolor-darkpink);
    font: var(--rtd-heading-weight-bold) 21px/24px var(--rtd-heading-fontfamily);
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
    margin-bottom: 14px;
  }
  .widget-pv__mobile-text {
    color: var(--tagcolor-darkpurple);
    font: 18px/24px var(--rtd-text-fontfamily);
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
    margin-bottom: 20px;
  }
  .widget-pv__overviewlinkbutton-mobile.widget-pv__overviewlinkbutton-mobile,
  .widget-pv__overviewmaplinkbutton-mobile.widget-pv__overviewmaplinkbutton-mobile {
    display: flex;
    margin-left: var(--pagegrid-gutteroutside);
    margin-right: var(--pagegrid-gutteroutside);
    font: 18px var(--rtd-text-fontfamily);
  }
  .widget-pv__overviewlinkbutton-mobile.widget-pv__overviewlinkbutton-mobile {
    margin-top: 14px;
  }
  .widget-pv__overviewmaplinkbutton-mobile.widget-pv__overviewmaplinkbutton-mobile {
    margin-top: 20px;
    padding-left: 15px;
    color: var(--tagcolor-darkpink);
  }
  .widget-pv__overviewmaplinkbutton-mobile.widget-pv__overviewmaplinkbutton-mobile span {
    display: block;
    margin-right: 10px;
  }
  .widget-pv__overviewmaplinkbutton-mobile.widget-pv__overviewmaplinkbutton-mobile span::before {
    display: inline-block;
  }
  .widget-pv__itemswrapper {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
    scroll-behavior: smooth;
  }
  .widget-pv__itemswrapper::-webkit-scrollbar {
    width: 0 !important;
  }
  .widget-pv__items {
    display: flex;
  }
  .widget-pv__items::after {
    content: "";
    flex: 0 0 30px;
  }
  .widget-pv__items > * {
    flex: 0 0 100%;
    scroll-snap-align: start;
    scroll-snap-margin-left: var(--pagegrid-gutteroutside);
    scroll-snap-margin-right: var(--pagegrid-gutteroutside);
    scroll-margin-left: var(--pagegrid-gutteroutside);
    scroll-margin-right: var(--pagegrid-gutteroutside);
  }
  .widget-pv__items > *:nth-child(1n+6) {
    display: none;
  }
  .widget-pv__items > * + * {
    margin-left: 30px;
  }
  .widget-pv__introblock,
  .praktijkvoorbeeld--maplink {
    display: none;
  }
  .widget-pv__introblock + * {
    margin-left: 0;
  }
}