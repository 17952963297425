/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.page-header--bighoneycomb {
  --pageheader-panel-minheight: none;
}
.page-header--bighoneycomb .page-header__textcontent {
  padding-top: var(--pageheader-textpanel-padding-top);
  padding-bottom: var(--pageheader-textpanel-padding-bottom);
}
.page-header--bighoneycomb .page-header__textcontent h1 {
  color: #ca005d;
}
.page-header--bighoneycomb .page-header__textcontent__text.page-header__textcontent__text > * {
  color: var(--tagcolor-darkpurple);
}
.page-header--bighoneycomb .page-header__textcontent .widget-button {
  margin-top: 20px;
}
.page-header--bighoneycomb .page-header__title {
  margin-bottom: 0px;
  font: var(--pageheader-title-font);
}
.page-header--bighoneycomb .page-header__title + * {
  margin-top: 15px;
}

.page-header__sitesearch-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.page-header__sitesearch-wrapper .wh-autocomplete-container {
  width: 100%;
  z-index: 1;
}

.page-header__sitesearch {
  background: var(--themecolor-pink);
  border: 2px solid var(--themecolor-pink);
  z-index: 2;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  height: var(--pageheader-sitesearch-input-height);
  border-radius: calc(var(--pageheader-sitesearch-input-height) / 2);
  padding-left: 15px;
  padding-right: 8px;
}

.page-header__sitesearch > .icon {
  color: #FFFFFF;
}

.page-header__sitesearch input {
  flex: 1 1 0px;
  min-width: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #FFFFFF !important;
  outline: 0;
  box-shadow: none !important;
  font: 20px var(--rtd-text-fontfamily);
}

.page-header__sitesearch input::placeholder {
  color: #FFFFFF;
}

.page-header__sitesearch button {
  width: var(--pageheader-sitesearch-submitbutton-size);
  height: var(--pageheader-sitesearch-submitbutton-size);
  flex: none;
  background: #FFFFFF;
  border: none;
  border-radius: 50%;
  color: var(--themecolor-pink);
  text-align: center;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s;
}

.page-header__sitesearch button .icon {
  margin-left: 2px;
  margin-top: 2px;
  font-size: var(--pageheader-sitesearch-icon-size);
}

.page-header__sitesearch__infotext {
  margin-top: 15px;
  font: var(--pageheader-sitesearch-infotext-font);
  font-style: italic;
  color: var(--tagcolor-darkpurple);
}

.page-header__sitesearch:focus-within,
.page-header__sitesearch:hover,
.page-header--withsitesearch-inline .page-header__sitesearch {
  background: #FFFFFF;
}
.page-header__sitesearch:focus-within > .icon,
.page-header__sitesearch:hover > .icon,
.page-header--withsitesearch-inline .page-header__sitesearch > .icon {
  color: var(--themecolor-pink);
  font-size: var(--pageheader-sitesearch-icon-size);
}
.page-header__sitesearch:focus-within input,
.page-header__sitesearch:hover input,
.page-header--withsitesearch-inline .page-header__sitesearch input {
  color: #000000 !important;
}
.page-header__sitesearch:focus-within input::placeholder,
.page-header__sitesearch:hover input::placeholder,
.page-header--withsitesearch-inline .page-header__sitesearch input::placeholder {
  color: #757575;
}
.page-header__sitesearch:focus-within button,
.page-header__sitesearch:hover button,
.page-header--withsitesearch-inline .page-header__sitesearch button {
  background: var(--themecolor-pink);
  color: #FFFFFF;
}

.page-header__sitesearch button:hover .icon {
  transform: scale(1.2);
}

@media (max-width: 767px) {
  .page-header--bighoneycomb {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content min-content;
    --pageheader-textpanel-padding-top: 15px;
    --pageheader-textpanel-padding-bottom: 30px;
    --pageheader-title-font: var(--rtd-heading-weight) 24px/28px var(--rtd-heading-fontfamily);
    --pageheader-sitesearch-input-height: 49px;
    --pageheader-sitesearch-input-radius: 24.5px;
    --pageheader-sitesearch-submitbutton-size: 36px;
    --pageheader-sitesearch-icon-size: 16px;
    --pageheader-sitesearch-infotext-font: 15px/18px var(--rtd-text-fontfamily);
  }
  .page-header--bighoneycomb.page-header--withsitesearch {
    --pageheader-textpanel-padding-top: 20px;
    --pageheader-textpanel-padding-bottom: 20px;
    --pageheader-title-font: var(--rtd-heading-weight) 33px/37px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 767px) and (max-width: 350px) {
  .page-header--bighoneycomb.page-header--withsitesearch {
    --pageheader-title-font: var(--rtd-heading-weight) 26px/37px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 767px) {
  .page-header--bighoneycomb::before {
    content: "";
    grid-row: 1/span 2;
    grid-column: 1/-1;
    background-color: var(--pageheader-background-neutral);
  }
  .page-header--bighoneycomb .page-header__image {
    grid-column: 3/26;
    width: 100%;
  }
  .page-header--bighoneycomb .page-header--withsitesearch .page-header__image {
    display: none;
  }
  .page-header--bighoneycomb .page-header__image--desktop {
    display: none;
  }
  .page-header--bighoneycomb .page-header__text {
    font: 18px/24px var(--rtd-text-fontfamily);
  }
  .page-header--bighoneycomb .page-header__image {
    grid-row: 1;
  }
  .page-header--bighoneycomb .page-header__textcontent {
    grid-row: 2;
    grid-column: 3/26;
  }
  .page-header--bighoneycomb .hexagon_pink {
    display: none;
  }
  .page-header--bighoneycomb.page-header--withsitesearch .page-header__image {
    display: none;
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted .page-header__textcontent {
    padding-bottom: calc(var(--pageheader-textpanel-padding-bottom) + var(--pageheader-sitesearch-input-height) / 2);
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted .page-header__sitesearch-wrapper {
    grid-column: 3/-3;
    grid-row: 3;
    margin-top: -27px;
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted .page-header__sitesearch__infotext {
    margin-top: 15px;
    text-align: center;
  }
  .page-header--bighoneycomb .page-header__sitesearch {
    width: 100%;
    padding-right: 6px;
  }
  .page-header--bighoneycomb .page-header__sitesearch__infotext {
    margin-top: 15px;
  }
}
@media (max-width: 480px) {
  .page-header--bighoneycomb .widget-button .simplebutton {
    width: 100%;
    justify-content: center;
  }
  .page-header--bighoneycomb .widget-button a.simplebutton::before {
    display: none;
  }
  .page-header--bighoneycomb.page-header--withsitesearch .page-header__image {
    display: none;
  }
}
@media (min-width: 768px) {
  .page-header--bighoneycomb + .page__body > .page__contentarea > :first-child {
    margin-top: 80px;
  }
  .page-header--bighoneycomb {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content min-content;
    --pageheader-textpanel-padding-top: 30px;
    --pageheader-textpanel-padding-bottom: 30px;
    --pageheader-title-font: var(--rtd-heading-weight) 36px/40px var(--rtd-heading-fontfamily);
    --pageheader-sitesearch-input-height: 60px;
    --pageheader-sitesearch-icon-size: 18px;
    --pageheader-sitesearch-submitbutton-size: 38px;
    --pageheader-sitesearch-infotext-font: 16px/20px var(--rtd-text-fontfamily);
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted {
    --pageheader-panel-minheight: 190px;
    --pageheader-title-font: var(--rtd-heading-weight) 52px/55px var(--rtd-heading-fontfamily);
    --pageheader-title-font: var(--rtd-heading-weight) clamp(33px,3.5vw,52px)/110% var(--rtd-heading-fontfamily);
  }
  .page-header--bighoneycomb.page-header--withsitesearch-inline {
    --pageheader-title-font: var(--rtd-heading-weight) 52px/55px var(--rtd-heading-fontfamily);
    --pageheader-title-font: var(--rtd-heading-weight) clamp(33px,3.5vw,52px)/110% var(--rtd-heading-fontfamily);
  }
  .page-header--bighoneycomb::before {
    content: "";
    grid-row: 1;
    grid-column: 1/-1;
    background-color: #F3F2F4;
    min-height: var(--pageheader-panel-minheight);
  }
  .page-header--bighoneycomb .page-header__text {
    font: 20px/28px var(--rtd-text-fontfamily);
  }
  .page-header--bighoneycomb .page-header__textcontent {
    grid-column-start: 3;
    grid-column-end: 14;
    grid-row: 1;
    align-self: center;
  }
  .page-header--bighoneycomb .page-header__image {
    grid-column: -14/span 11;
    grid-row: 1;
    margin-top: -10%;
    margin-bottom: -15%;
    width: 100%;
    height: auto;
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted .page-header__image {
    grid-column: -11/span 8;
    grid-row: 1/span 2;
  }
  .page-header--bighoneycomb.page-header--withsitesearch-inline .page-header__image {
    grid-column: -11/span 8;
  }
  .page-header--bighoneycomb .page-header__image--mobile {
    display: none;
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted .page-header__sitesearch-wrapper {
    grid-column: 3/span 12;
    grid-row: 2;
    max-width: 520px;
    margin-top: -27px;
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted .page-header__textcontent {
    align-self: end;
    padding-bottom: calc(30px + var(--pageheader-sitesearch-input-height) / 2);
  }
  .page-header--bighoneycomb.page-header--withsitesearch-shifted .page-header__sitesearch__infotext {
    padding-left: 45px;
  }
  .page-header--bighoneycomb .page-header__sitesearch {
    width: 100%;
  }
  .page-header--bighoneycomb .page-header__sitesearch__infotext {
    margin-top: 15px;
  }
}