/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
.spccomp-search {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

/*
@media (max-width: $minwidth-show-searchicon - 1px)
{
  .spccomp-search
  {
    display: none;
  }
}
*/
.spccomp-search:focus-within {
  z-index: 100;
}

#site-mainmenu__togglesearch {
  position: relative;
  z-index: 1;
}

#site-mainmenu__togglesearch .far {
  font-size: 22px;
  color: #c10061;
}

.spccomp-searchpanel {
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
  position: absolute;
  top: 50%;
  transform: translate(15px, -50%);
  right: 0;
  width: 500px;
}

.spccomp-search:focus-within .spccomp-searchpanel,
html.page--search .spccomp-searchpanel {
  opacity: 1;
  pointer-events: auto;
}

.spccomp-searchpanel__query.spccomp-searchpanel__query {
  width: 100%;
  height: 46px;
  font: 19px var(--rtd-text-fontfamily);
  border-radius: 2px;
  padding-left: 10px;
}

.spccomp-searchpanel__query.spccomp-searchpanel__query {
  padding-left: 56px;
}

.spccomp-searchpanel__query__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 22px;
  color: #c10061;
  position: absolute;
  left: 20px;
  top: 9px;
}

.spccomp-searchpanel__query__clear {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 24px;
  color: #767676;
  position: absolute;
  right: 25px;
  top: 9px;
}

.spccomp-searchpanel__query:focus {
  outline: 0;
  border: 2px solid #FFFFFF;
}

html.mainmenu-search-active #site-mainmenu::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
html.mainmenu-search-active main::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10;
}