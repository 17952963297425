/*

SPC-Menubar 2023

*/
.spc-menubar > ul {
  display: flex;
}

.spc-menubar ul {
  -webkit-user-select: none;
  user-select: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.spc-menubar__item {
  display: flex;
  align-items: baseline;
}

/* either:
   - a seperate button.spc-menubar__item__toggle.spc-menubar__item__togglebutton
   - a button which includes both the title and symbol (button.spc-menubar__item__toggle.spc-menubar__item__title)
*/
.spc-menubar__item__toggle {
  /* reset <button> styling */
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: inherit;
  cursor: pointer;
}

.spc-menubar__item__togglebutton {
  width: var(--menubar-bar-toggler-width);
  padding: var(--menubar-bar-toggler-extrahitarea);
  --negate: calc(-1 * var(--menubar-bar-toggler-extrahitarea));
  margin: var(--negate) 0 var(--negate) var(--negate);
}

.spc-menubar.spc-menubar > ul > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__togglebutton {
  margin-left: calc(var(--menubar-bar-toggler-margin) - var(--menubar-bar-toggler-extrahitarea));
}

.spc-menubar.spc-menubar > ul > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__title.spc-menubar__item__toggle::after {
  margin-left: var(--menubar-bar-toggler-margin);
}

/*
FIXME: This confuses the menu-overflow-detection-code!

.spc-menubar-branch--horizontal > li:last-child > .spc-menubar__item
{
  // make the padding-right extend outside the right side on the contentarea
  margin-right: calc(-1 * var(--menubar-level1-padding-right));
}
*/
.spc-menubar.spc-menubar > ul > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__toggle::after {
  /* any type defining a block formatting context allows us
     to disable or define our own underline
    (as long as our parent doesn't use flex or grid)
  */
  display: inline-block;
  text-decoration: none;
  font: var(--menubar-bar-toggler-font);
  font-size: var(--menubar-bar-toggler-size);
  content: var(--menubar-bar-toggler-content);
  margin-top: var(--menubar-bar-toggler-shifty);
}

.spc-menubar__spacer {
  width: var(--menubar-level1-spacing);
  min-width: var(--menubar-level1-spacing-min);
}

.spc-menubar > ul > li:first-child {
  margin-left: calc(-1 * var(--menubar-level1-padding-left));
}

.spc-menubar > ul > li > .spc-menubar__item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: var(--menubar-bar-item-color);
  font: var(--menubar-level1-font);
}

.spc-menubar > ul > li > .spc-menubar__item > .spc-menubar__item__title {
  text-decoration: var(--menubar-bar-item-decoration);
  color: inherit;
  font: inherit;
  padding-left: var(--menubar-level1-padding-left);
  padding-right: var(--menubar-level1-padding-right);
  padding-top: var(--menubar-level1-padding-top);
  padding-bottom: var(--menubar-level1-padding-bottom);
  outline-offset: 4px;
}

.spc-menubar > ul > li.spc-menubar__currentpagebranch > .spc-menubar__item a {
  color: var(--menubar-bar-item-selected-color);
}

.spc-menubar.spc-menubar > ul > li > a:focus {
  position: relative;
  z-index: 1; /* ensure the focus outline isn't overlapped by the next item */
}

.spc-menubar__pulldown {
  position: absolute;
  animation: var(--menubar-pulldown-anim-close);
  animation-fill-mode: forwards;
  /*
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
  */
}

.spc-menubar ul .spc-menubar__item--expand > .spc-menubar__item a,
.spc-menubar > ul > li:not(.spc-menubar__item--hassubitems) > .spc-menubar__item a:hover,
.spc-menubar:not([role=menubar]) > ul > li.spc-menubar__item--hassubitems:hover > .spc-menubar__item a {
  color: var(--menubar-bar-item-hover-color);
  text-decoration: var(--menubar-bar-item-hover-decoration);
  background: var(--menubar-bar-item-hover-background);
}

.spc-menubar__pulldown .spc-menubar__pulldown[hidden] {
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.spc-menubar ul .spc-menubar__item--expand > .spc-menubar__pulldown,
.spc-menubar:not([role=menubar]) > ul > li.spc-menubar__item--hassubitems:hover .spc-menubar__pulldown {
  display: block;
  height: auto;
  overflow: visible;
  visibility: visible;
  animation: var(--menubar-pulldown-anim-open);
  animation-fill-mode: forwards;
}

ul.spc-menubar-branch--horizontal > li.spc-menubar__item--expand > .spc-menubar__item {
  background: var(--menubar-bar-item-hover-background);
  border-radius: var(--menubar-bar-item-borderadius);
}

.spc-menubar__pulldown .spc-menubar__item--expand > .spc-menubar__item {
  background: var(--menubar-bar-item-hover-background);
}

/*
, .spc-menubar > ul > li:not(.spc-menubar__item--hassubitems) > .spc-menubar__item:has(a:hover) // for items without submenu use the normal hover
, .spc-menubar:not([role="menubar"]) > ul > li.spc-menubar__item--hassubitems:hover > .spc-menubar__item a // fallback if JS menu code wasn't initialized
{
background-color: #F00;
}

*/