/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.widget-pagehighlights {
  grid-column: 1/span 27;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-pagehighlights__topbar {
  grid-row: 1;
  grid-column: 3/26;
}

.widget-pagehighlights__title {
  color: var(--tagcolor-darkpink);
}
@media (max-width: 639px) {
  .widget-pagehighlights__title {
    font: var(--rtd-heading-weight-bold) 21px/24px var(--rtd-heading-fontfamily);
    margin-bottom: 11px;
  }
}
@media (min-width: 640px) {
  .widget-pagehighlights__title {
    font: var(--rtd-heading-weight-bold) 36px/40px var(--rtd-heading-fontfamily);
    margin-bottom: 33px;
  }
}

.widget-pagehighlights__itemswrapper {
  grid-row: 2;
}

/*

.widget-pagehighlights__items
{
  @include layout--verywide;
  grid-row: 2;

  display: flex;
}
*/
.widget-pagehighlights__items > * {
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.1);
}

.widget-pagehighlights__bottombar {
  margin-top: 10px;
  grid-row: 3;
  grid-column: 3/26;
  display: none;
}

.carrouselcontent--multiplepages .widget-pagehighlights__bottombar {
  display: flex;
}

.widget-pagehighlights__previousbutton .widget-pagehighlights__nextbutton {
  flex: none;
}

.widget-pagehighlights__previousbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: grid;
  color: var(--tagcolor-darkpink);
  cursor: pointer;
  fill: rgba(0, 0, 0, 0) !important; /* workaround for "ARC Toolkit" */
}
.widget-pagehighlights__previousbutton svg {
  grid-column: 1;
  grid-row: 1;
}
.widget-pagehighlights__previousbutton::after {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
}
.widget-pagehighlights__previousbutton svg {
  fill: #FFFFFF;
  stroke: var(--tagcolor-darkpink);
}
.widget-pagehighlights__previousbutton:focus {
  outline: 0;
  color: var(--tagcolor-darkpurple);
}
.widget-pagehighlights__previousbutton:focus svg {
  stroke: var(--tagcolor-darkpurple);
  stroke-width: 2px;
}
.widget-pagehighlights__previousbutton:hover {
  color: #FFFFFF;
}
.widget-pagehighlights__previousbutton:hover svg {
  fill: var(--tagcolor-darkpurple);
  stroke: var(--tagcolor-darkpurple);
}
.widget-pagehighlights__previousbutton[disabled] {
  color: #CCCCCC;
}
.widget-pagehighlights__previousbutton[disabled] svg {
  fill: #FFFFFF;
  stroke: #CCCCCC;
}
.widget-pagehighlights__previousbutton::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f104";
  font-size: 28px;
  margin-right: 2px;
}

.widget-pagehighlights__nextbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: grid;
  color: var(--tagcolor-darkpink);
  cursor: pointer;
  fill: rgba(0, 0, 0, 0) !important; /* workaround for "ARC Toolkit" */
}
.widget-pagehighlights__nextbutton svg {
  grid-column: 1;
  grid-row: 1;
}
.widget-pagehighlights__nextbutton::after {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
}
.widget-pagehighlights__nextbutton svg {
  fill: #FFFFFF;
  stroke: var(--tagcolor-darkpink);
}
.widget-pagehighlights__nextbutton:focus {
  outline: 0;
  color: var(--tagcolor-darkpurple);
}
.widget-pagehighlights__nextbutton:focus svg {
  stroke: var(--tagcolor-darkpurple);
  stroke-width: 2px;
}
.widget-pagehighlights__nextbutton:hover {
  color: #FFFFFF;
}
.widget-pagehighlights__nextbutton:hover svg {
  fill: var(--tagcolor-darkpurple);
  stroke: var(--tagcolor-darkpurple);
}
.widget-pagehighlights__nextbutton[disabled] {
  color: #CCCCCC;
}
.widget-pagehighlights__nextbutton[disabled] svg {
  fill: #FFFFFF;
  stroke: #CCCCCC;
}
.widget-pagehighlights__nextbutton::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f105";
  font-size: 28px;
  margin-left: 1px;
}

.widget-pagehighlights__bullets {
  flex: 1 1 0px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 639px) {
  .widget-pagehighlights__items > *:nth-child(1n+4) {
    display: none;
  }
  .widget-pagehighlights .praktijkvoorbeeld__title {
    font: 500 20px/24px var(--rtd-heading-fontfamily);
  }
  .widget-pagehighlights .praktijkvoorbeeld__description {
    font: 17px/24px var(--rtd-text-fontfamily);
  }
  .widget-pagehighlights .praktijkvoorbeeld__top,
  .widget-pagehighlights .praktijkvoorbeeld__top__image {
    padding-top: 65.87%;
  }
  .widget-pagehighlights__previousbutton,
  .widget-pagehighlights__nextbutton {
    display: none;
  }
}
@media (min-width: 640px) {
  .widget-pagehighlights .praktijkvoorbeeld__title {
    font: 500 23px/28px var(--rtd-heading-fontfamily);
  }
  .widget-pagehighlights .praktijkvoorbeeld__description {
    font: 17px/24px var(--rtd-text-fontfamily);
  }
  .widget-pagehighlights .praktijkvoorbeeld__top,
  .widget-pagehighlights .praktijkvoorbeeld__top__image {
    padding-top: 66.28%;
  }
}
.widget-pagehighlights__itemswrapper {
  grid-column: 1/span 27;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
  padding-top: 5px;
  padding-bottom: 8px;
}
.widget-pagehighlights__itemswrapper::-webkit-scrollbar {
  width: 0 !important;
}

/*
Method 1
*/
@media (max-width: 1169px) {
  .widget-pagehighlights__itemswrapper {
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
  .widget-pagehighlights__items > * {
    scroll-snap-margin-left: var(--pagegrid-gutteroutside);
    scroll-margin-left: var(--pagegrid-gutteroutside);
    margin-top: 0 !important; /* we never need margin in carrousel mode */
  }
}
/*
.widget-pagehighlights__itemswrapper
{
  @include spc-contentgrid;
}
.widget-pagehighlights__items
{
  @include layout--verywide;
}
*/
/*

Disabled.. CarrouselContent doesn't properly support this yet, Safari may have trouble with it, IE ??

Most pretty way though.
It scrolls over the full width of the viewport, but keeps whitespace at the sides so
we always only have the X items of the pages visible.



@media(min-width: 1170px) // 1170 is contentarea including padding
{
  .widget-pagehighlights__items
  {
    max-width: 1140px;
  }
  .widget-pagehighlights__items > *:first-child
  {
    margin-left: calc((100vw - 1110px) / 2);
  }
  .widget-pagehighlights__items > *:nth-child(3n)
  {
    margin-right: calc((100vw - 1140px) / 2);
  }
  .widget-pagehighlights__items > *
  {
    scroll-snap-margin-left: calc((100vw - 1110px) / 2); // iOS 11 till 14.4
    scroll-margin-left: calc((100vw - 1110px) / 2);
  }
}
*/
@media (min-width: 1170px) {
  .widget-pagehighlights__itemswrapper {
    grid-column: 2/27;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
  .widget-pagehighlights__items > * {
    scroll-snap-margin-left: var(--pagegrid-gutteroutside);
    scroll-margin-left: var(--pagegrid-gutteroutside);
  }
  .widget-pagehighlights__items > *:nth-child(3n+1) {
    margin-left: 38px;
  }
}
.widget-pagehighlights__items {
  display: flex;
}

.widget-pagehighlights__items::after {
  content: "";
  flex: 0 0 100%;
}

.widget-pagehighlights__items > * {
  scroll-snap-align: start;
}
@media (max-width: 640px) {
  .widget-pagehighlights__items > * {
    flex: 0 0 100%;
  }
}
@media (min-width: 640px) and (max-width: 800px) {
  .widget-pagehighlights__items > * {
    flex: 0 0 auto;
    width: calc((100% - 30px) / 2);
  }
}
@media (min-width: 800px) {
  .widget-pagehighlights__items > * {
    flex: 0 0 auto;
    width: calc((100% - 60px) / 3);
  }
}

.widget-pagehighlights__items > * + * {
  margin-left: 30px;
}