.carrouselbullets {
  display: flex;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.carrouselbullets > * {
  padding: 5px;
  -webkit-tap-highlight-color: transparent;
}

.carrouselbullets > *::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--tagcolor-darkerpink);
  opacity: 0.5;
  transform: scale(0.4);
}

.carrouselbullets > *.carrouselbullet--selected::before {
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 639px) {
  .carrouselbullets > * {
    padding: 4px;
  }
  .carrouselbullets > *::before {
    width: 16px;
    height: 16px;
  }
}
.widget-pagehighlights__bullets > .carrouselbullet::before,
.widget-pv__bullets > .carrouselbullet::before {
  background-color: var(--tagcolor-darkpurple);
}