/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb
{
  -webkit-appearance: none;

  width:  var(--podcast-slider-thumb-size);
  height: var(--podcast-slider-thumb-size);

/*  margin-top: calc(-1 * (var(--podcast-slider-thumb-size) - var(--podcast-slider-height)) / 2); */
/* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background: var(--podcast-slider-thumb-background);
  cursor: pointer;

  opacity: 0;
}

  input[type=range]:hover::-webkit-slider-thumb
, input[type=range]:focus-visible::-webkit-slider-thumb
{
  opacity: 1;
}

input[type=range]:focus::-webkit-slider-thumb
{
  box-shadow: var(--podcast-slider-thumb-boxshadow);
}


/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb
{
  width:  var(--podcast-slider-thumb-size);
  height: var(--podcast-slider-thumb-size);

  margin-top: calc(-1 * (var(--podcast-slider-thumb-size) - var(--podcast-slider-height)) / 2); /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  border-radius: 50%;
  background: var(--podcast-slider-thumb-background);
  cursor: pointer;

  opacity: 0;
}

input[type=range]:hover::-moz-range-thumb
{
  opacity: 1;
}

input[type=range]:focus::-moz-range-thumb
{
  box-shadow: var(--podcast-slider-thumb-boxshadow);
}
