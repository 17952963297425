/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.jzojpmapitem {
  display: grid;
  cursor: pointer;
}

.jzojpmapitem img {
  grid-column: 1;
  grid-row: 1;
}

.jzojpmapitem div {
  color: #FFFFFF;
  font: bold 19px var(--rtd-text-fontfamily);
  width: 35px;
  height: 39px;
  text-align: center;
  line-height: 37px;
  grid-column: 1;
  grid-row: 1;
}

.praktijkvoorbeelden__map {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
}

.praktijkvoorbeelden__map::before {
  grid-column: 1;
  grid-row: 1;
}

.widget-map__container__map {
  grid-column: 1;
  grid-row: 1;
  position: relative;
  z-index: 0;
}

.praktijkvoorbeelden__map .leaflet-popup-content-wrapper {
  border-radius: 0;
  min-width: 150px;
}

.map__buttons {
  grid-column: 1;
  grid-row: 1;
  align-self: end;
  justify-self: end;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  right: 55px;
  bottom: 10px;
  padding: 25px 0;
}
@media (max-width: 1023px) {
  .map__buttons {
    right: var(--pagegrid-gutteroutside);
  }
}

.map__zoomin,
.map__zoomout {
  position: relative;
  display: grid;
  cursor: pointer;
}

.map__buttons > * + * {
  margin-top: 10px;
}

.map__zoomin svg,
.map__zoomin span,
.map__zoomout svg,
.map__zoomout span {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
}

.map__zoomin svg,
.map__zoomout svg {
  fill: #FFFFFF;
}

.map__zoomin span,
.map__zoomout span {
  color: var(--tagcolor-darkpink);
}

.map__zoomin:hover svg,
.map__zoomout:hover svg {
  fill: var(--tagcolor-darkpink);
}

.map__zoomin:hover span,
.map__zoomout:hover span {
  color: #FFFFFF;
}

.leaflet-popup-close-button.leaflet-popup-close-button.leaflet-popup-close-button {
  color: #000000;
  padding: 10px;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  font-size: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-popup-close-button.leaflet-popup-close-button.leaflet-popup-close-button::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: var(--fa-times);
  font-size: 16px;
  line-height: 0;
}

.location__location {
  grid-column: 1/span 2;
  grid-row: 1;
}

.location__name.location__name {
  color: var(--tagcolor-darkpurple);
  font: var(--rtd-heading-weight-bold) 16px var(--rtd-heading-fontfamily);
  text-decoration: none;
}

.location__location,
.location__name {
  padding-right: 10px; /* prevent overlap with popup close button */
}

.location--pv {
  display: grid;
  grid-template-columns: 1fr min-content;
}
.location--pv .location__location {
  grid-column: 1/span 2;
  grid-row: 1;
}
.location--pv a.location__name.location__name {
  display: block;
  grid-column: 1;
  grid-row: 2;
}
.location--pv .location__image {
  grid-column: 2;
  grid-row: 2;
  width: 56px;
  height: 56px;
  margin-left: 30px;
}

.location--transformatieplan .location__buttonbar {
  display: flex;
  column-gap: 15px;
  margin-top: 8px;
}

.leaflet-control-attribution.leaflet-control-attribution.leaflet-control-attribution {
  padding: 1px 6px;
}
@media (max-width: 1023px) {
  .leaflet-control-attribution.leaflet-control-attribution.leaflet-control-attribution {
    font-size: 11px;
  }
}
@media (min-width: 1024px) {
  .leaflet-control-attribution.leaflet-control-attribution.leaflet-control-attribution {
    font-size: 11.5px;
  }
}

.leaflet-control-attribution.leaflet-control-attribution a {
  color: var(--tagcolor-darkpurple);
}