html {
  /* "Velden met een sterretje(*) zijn verplicht om in te vullen." */
  --forms-infopanel-background: var(--color-green-light-background);
  --forms-infopanel-color: #121212;
  --forms-infopanel-font: var(--rtd-table-font);
  --forms-infopanel-padding: 16px 18px;
  --forms-infopanel-icon-font: var(--fontawesome-regular);
  --forms-infopanel-icon-size: 20px;
  --formlayout-fieldgroup-info-color: #333333;
  --formlayout-fieldgroup-info-font: var(--common-caption-font);
}