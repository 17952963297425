/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
input[type=search] {
  -webkit-appearance: none;
  appearance: none;
}

input::placeholder {
  opacity: 1;
  color: var(--textcontrols_placeholdercolor);
}

:disabled input::placeholder,
[data-wh-form-disabled] input::placeholder {
  opacity: 1;
  color: var(--textcontrols_disabled_placeholder);
}

input[type=text],
input[type=url],
input[type=email],
input[type=password],
input[type=number],
input[type=date],
input[type=time],
input[type=search],
.wh-form__dateinputgroup,
.wh-form__timeinputgroup,
.datetime__part,
textarea,
select:not(.wh-rtd__toolbarstyle),
.multiselect__panel__trigger {
  padding-left: var(--textcontrols_padleft);
  padding-right: var(--textcontrols_padright);
  font: var(--textcontrols_font);
  height: var(--controls_height);
  border: 1px solid var(--controls_outline_color);
  border-radius: var(--controls_radius);
  background-color: #FFFFFF;
  color: var(--textcontrols_textcolor);
}
input[type=text]:focus,
input[type=url]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=date]:focus,
input[type=time]:focus,
input[type=search]:focus,
.wh-form__dateinputgroup:focus,
.wh-form__timeinputgroup:focus,
.datetime__part:focus,
textarea:focus,
select:not(.wh-rtd__toolbarstyle):focus,
.multiselect__panel__trigger:focus {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15); /* !important; */
}
input[type=text]:disabled, input[type=text][data-wh-form-disabled],
input[type=url]:disabled,
input[type=url][data-wh-form-disabled],
input[type=email]:disabled,
input[type=email][data-wh-form-disabled],
input[type=password]:disabled,
input[type=password][data-wh-form-disabled],
input[type=number]:disabled,
input[type=number][data-wh-form-disabled],
input[type=date]:disabled,
input[type=date][data-wh-form-disabled],
input[type=time]:disabled,
input[type=time][data-wh-form-disabled],
input[type=search]:disabled,
input[type=search][data-wh-form-disabled],
.wh-form__dateinputgroup:disabled,
.wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form__timeinputgroup:disabled,
.wh-form__timeinputgroup[data-wh-form-disabled],
.datetime__part:disabled,
.datetime__part[data-wh-form-disabled],
textarea:disabled,
textarea[data-wh-form-disabled],
select:not(.wh-rtd__toolbarstyle):disabled,
select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.multiselect__panel__trigger:disabled,
.multiselect__panel__trigger[data-wh-form-disabled] {
  color: var(--textcontrols_disabled_textcolor);
  border-color: var(--textcontrols_disabled_bordercolor);
  background-color: var(--textcontrols_disabled_bgcolor);
  box-shadow: none;
}

textarea {
  flex: 1 0 0px;
  padding-right: var(--textcontrols_padleft);
}

.wh-form__imgedit {
  border: 1px solid var(--controls_outline_color);
  border-radius: var(--controls_radius);
  background-color: #FFFFFF;
  color: var(--textcontrols_textcolor);
}
.wh-form__imgedit:focus {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15); /* !important; */
}
.wh-form__imgedit:disabled, .wh-form__imgedit[data-wh-form-disabled] {
  color: var(--textcontrols_disabled_textcolor);
  border-color: var(--textcontrols_disabled_bordercolor);
  background-color: var(--textcontrols_disabled_bgcolor);
  box-shadow: none;
}

.wh-form__rtd:focus-within {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15); /* !important; */
}

.wh-form__dateinputgroup:focus-within,
.wh-form__timeinputgroup:focus-within {
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15); /* !important; */
}

.wh-form__dateinputgroup__line.wh-form__dateinputgroup__line input:focus,
.wh-form__timeinputgroup__line.wh-form__timeinputgroup__line input:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: #F0F0F0;
}