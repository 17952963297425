.filteredoverview__feedbackandviewbar {
  color: var(--filteredoverview-feedbackbar-color);
  font: var(--filteredoverview-feedbackbar-font);
}

/* this might be a h2 because the "X results" will be used a heading for the results */
.filteredoverview__feedback {
  font: inherit;
}

html:not(.filteredoverview--filtersactive) .filtertags__title {
  display: none;
}

.filteredoverview__filtertags {
  display: none;
}

.filteredoverview--filtersactive .filteredoverview__filtertags {
  display: flex;
  align-items: baseline;
}

.filtertags__items {
  margin-top: 4px;
}

.resultscount__label--nofiltering,
.resultscount__label--noresults,
.resultscount__label--singleresult,
.resultscount__label--multipleresults {
  display: none;
}

.resultscount__label--nofiltering {
  display: inline;
}

html.filteredoverview--filtersactive .resultscount__label--nofiltering {
  display: none;
}

.filteredoverview--filtersactive.filteredoverview--noresults .resultscount__label--noresults, .filteredoverview--filtersactive.filteredoverview--singleresult .resultscount__label--singleresult, .filteredoverview--filtersactive.filteredoverview--multipleresults .resultscount__label--multipleresults {
  display: inline;
}

.filteredoverview--noresults .resultscount__value {
  display: none;
}