/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.widget-events {
  grid-column: 1/span 27;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  padding-top: var(--panels-padding-top);
  padding-bottom: var(--panels-padding-bottom);
}

.widget-events__background {
  position: absolute;
  left: 0;
  right: 0;
  height: 233px;
  background-color: #F7F7F7;
}

.widget-events__topbar {
  grid-column: 3/26;
  grid-row: 1;
  position: relative;
}

.widget-events__title {
  color: var(--tagcolor-darkpink);
}

.widget-events__overviewlink {
  color: var(--tagcolor-darkpink);
}

.widget-events__overviewlink::before,
.widget-events__overviewlink-mobile::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f105";
  font-size: 20px;
  margin-right: 10px;
}

.widget-events__blockswrapper {
  grid-column: 3/26;
  grid-row: 2;
  position: relative;
  z-index: 1;
}

.widget-events__blockswrapper {
  max-width: 100%;
}

.widget-events__blocks {
  display: flex;
}

/*
@supports (display: grid) and (display: contents)
{
  .widget-events__blocks
  {
    // Equal height in which both the top and bottom parts also are equal for each side-by-side item
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    grid-column-gap: 30px;
    column-gap: 30px;
  }

  .widget-events__blocks .widget-event__block
  {
    display: contents;
  }
*/
.widget-events__blocks {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 30px;
  column-gap: 30px;
}

.widget-event__block-link.widget-event__block-link {
  margin-right: 0;
  z-index: 2;
}

.widget-event__block-link:nth-child(1) {
  grid-column: 1;
  grid-row: 1/span 2;
}

.widget-event__block__top:nth-child(2) {
  grid-column: 1;
  grid-row: 1;
}

.widget-event__block__whenwhere:nth-child(3) {
  grid-column: 1;
  grid-row: 2;
}

.widget-event__block-link:nth-child(4) {
  grid-column: 2;
  grid-row: 1/span 2;
}

.widget-event__block__top:nth-child(5) {
  grid-column: 2;
  grid-row: 1;
}

.widget-event__block__whenwhere:nth-child(6) {
  grid-column: 2;
  grid-row: 2;
}

.widget-event__block-link:nth-child(7) {
  grid-column: 3;
  grid-row: 1/span 2;
}

.widget-event__block__top:nth-child(8) {
  grid-column: 3;
  grid-row: 1;
}

.widget-event__block__whenwhere:nth-child(9) {
  grid-column: 3;
  grid-row: 2;
}

.widget-event__block-link:nth-child(10) {
  grid-column: 4;
  grid-row: 1/span 2;
}

.widget-event__block__top:nth-child(11) {
  grid-column: 4;
  grid-row: 1;
}

.widget-event__block__whenwhere:nth-child(12) {
  grid-column: 4;
  grid-row: 2;
}

@media (max-width: 639px) {
  .widget-events__topbar {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .widget-events__title {
    font: var(--rtd-heading-weight-bold) 21px/24px var(--rtd-heading-fontfamily);
  }
  /*
    .widget-events__blocks > *:nth-child(1n+2)
    {
      display: none;
    }
  */
  .widget-events__overviewlink {
    display: none;
  }
  .widget-events__overviewlink-mobile {
    display: block;
    grid-row: 4;
    grid-column: 3/26;
    color: var(--tagcolor-darkpink);
    text-align: center;
    font: 18px var(--rtd-text-fontfamily);
  }
  .widget-events__blockswrapper {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  .widget-events__blockswrapper::-webkit-scrollbar {
    width: 0 !important;
  }
  .widget-events__blocks {
    grid-template-columns: 50% 50%;
  }
  .widget-event__block__top {
    scroll-snap-align: start;
  }
  .widget-events__bullets {
    margin-bottom: 15px;
    grid-row: 3;
    grid-column: 3/26;
    justify-content: center;
  }
}
@media (min-width: 640px) {
  .widget-events__overviewlink-mobile {
    display: none;
  }
  .widget-events__topbar {
    display: flex;
    align-items: baseline;
    font: 20px var(--rtd-text-fontfamily);
    padding-top: 40px;
    height: 110px;
  }
  .widget-events__title {
    font: var(--rtd-heading-weight-bold) 36px var(--rtd-heading-fontfamily);
  }
  .widget-events__overviewlink {
    margin-left: auto;
  }
  .widget-events__blocks > * {
    z-index: 1;
    margin-right: 20px;
    padding-top: 23px;
  }
  .widget-events__blocks > *:last-child {
    margin-right: 0;
  }
  .widget-events__item {
    margin-right: 30px;
  }
  .widget-events__morelink {
    margin-top: 30px;
  }
}
@media (max-width: 639px) {
  .widget-events__blocks {
    width: 400%;
    grid-template-columns: 25% 25% 25% 25%;
  }
  /*
    .widget-events__blocks > *:nth-child(1n+2)
    {
      display: none;
    }
  */
}
@media (min-width: 640px) and (max-width: 799px) {
  .widget-events__blocks {
    grid-template-columns: calc((100% - 30px) / 2) calc((100% - 30px) / 2);
  }
  /*
    .widget-events__blocks > *:nth-child(1n+3)
    {
      display: none;
    }
  */
  .widget-events__blocks > *:nth-child(1n+7) {
    display: none;
  }
}
@media (min-width: 800px) and (max-width: 999px) {
  .widget-events__blocks {
    grid-template-columns: calc((100% - 60px) / 3) calc((100% - 60px) / 3) calc((100% - 60px) / 3);
  }
  /*
    .widget-events__blocks > *:nth-child(1n+4)
    {
      display: none;
    }
  */
  .widget-events__blocks > *:nth-child(1n+10) {
    display: none;
  }
}