.page__contentsummary {
  margin-top: 30px;
}

.page__contentsummary a {
  color: var(--tagcolor-darkpurple);
  text-decoration: none;
}

.page__contentsummary a:hover {
  text-decoration: underline;
}