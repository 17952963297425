/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
/*

Based on SU2020 < 4TU ??

This version added:
- $menubar-color
- $menubar-pulldown-pointer with values "", "arrow", "pointer" replaced $menubar-pulldown-arrow (boolean)
- $menu-pulldown-center option working now
- $menu-pulldown-shifty
- $menubar-level*-font
- $menubar-level*-uppercase: false;
- $menubar-level*-padding
- $menubar-togglecolor

*/
/*
Student Union

$menubar-pulldown-shifty: 27px;

$menubar-pulldown-bgcolor:           var(--themecolor);
$menubar-pulldown-itemhover-bgcolor: var(--themecolor-dark);

$menubar-pulldown-padding: 7px 2px 12px 2px;

$menubar-level2-font:      700 16px/20px "Univers Next W02";
$menubar-level2-uppercase: true;
$menubar-level2-padding:   6px 20px 6px 20px;

$menubar-level3-font:      700 16px/20px "Univers Next W02";
$menubar-level3-uppercase: false;
$menubar-level3-padding:   6px 20px 6px 35px;

$menubar-level4-font:      300 16px/20px "Univers Next W02";
$menubar-level4-uppercase: false;
$menubar-level4-padding:   6px 20px 6px 52px;
*/
.header-menubar__content {
  height: 100%;
}

.header-menubar__showsidebar {
  display: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #FFFFFF;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: -10px;
}

.header-menubar__menucontainer {
  display: grid;
  grid-template-columns: 1fr;
}

.header-menubar__menubar {
  grid-column: 1;
  grid-row: 1;
}

.header-menubar__mobile {
  display: flex;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
}

.header-menubar__mobile .header-menubar__sitename {
  margin-left: 15px;
}

.header-menubar__menucontainer {
  width: 100%;
}

.header-menubar__menubar {
  flex: 1 1 0px;
  min-width: 0;
}

#header-menubar_sidebartoggle {
  margin-left: auto;
}

.header-menubar__mobile {
  display: none;
}

/*
We need at the the iPad resolution to fit the menu.
Below this we better just force the hamburger menu toggle to prevent a short flash of seeing the normal menu

iPad        768 x 1024
iPhone 6 -  375 x  667
iPhone X -  375 x  812 - may get full menubar if it fits on the screen
*/
@media (max-width: 767px) {
  .header-menubar__menubar {
    display: none;
  }
  .header-menubar__mobile {
    display: flex;
  }
  .header-menubar__showsidebar.header-menubar__showsidebar {
    display: flex;
  }
}
.header-menubar--hidemenuitems .header-menubar__mobile {
  display: flex;
}
.header-menubar--hidemenuitems .header-menubar__menubar {
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
}
.header-menubar--hidemenuitems .header-menubar__showsidebar {
  display: block;
}