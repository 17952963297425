/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
- De achtergrond/afbeelding moet achter zowel de titel als filterbalk liggen.
- De achtergrond en filterbalk scrollen los van elkaar.
  De achtergrond scrollt iets verder waardoor in feite de witruimte/padding om de filterbalk kleiner wordt bij scrollen.
- Er wordt een vaste hoeveelheid ruimte gereserveerd voor de titel in mobiel mode.
  (hoeveel is afhankelijk of de filterbalk en toggle naast elkaar staan of onder elkaar (voor kleinere mobiele schermen))
- De beschrijving in de header wordt niet getoond op mobiel
*/
.page-header--fullwidthimage .page-header__title {
  opacity: 1;
  transition: opacity 0.2s;
}
html.hidetitleinfilterheader .page-header--fullwidthimage .page-header__title {
  opacity: 0;
}
@media (max-width: 800px) {
  .page-header--fullwidthimage {
    height: 145px;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .page-header--fullwidthimage .page-header__textcontent {
    grid-column: 3/span 15;
    padding: 18px 0 0px 0;
    color: #FFFFFF;
  }
  .page-header--fullwidthimage .page-header__textcontent__text {
    display: none;
  }
}
@media (max-width: 500px) {
  .page-header--fullwidthimage {
    height: 186px !important;
  }
}
@media (min-width: 801px) {
  .page-header--fullwidthimage {
    height: 372px;
    background-size: cover;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .filteredoverview--showfilters .page-header--fullwidthimage {
    height: 210px;
    top: -70px;
  }
  .filteredoverview--showfilters .page-header--fullwidthimage .page-header__textcontent__text {
    display: none;
  }
  .page-header--fullwidthimage .page-header__textcontent {
    grid-column: 3/span 15;
    padding: 55px 0 25px 0;
    color: #FFFFFF;
  }
  .page-header--fullwidthimage .page-header__textcontent__text > * {
    font: 20px/28px var(--rtd-text-fontfamily);
    color: #FFFFFF;
  }
  .page-header--fullwidthimage .page-header__textcontent__text a {
    color: #FFFFFF;
    text-decoration: underline;
  }
  html.header--shrinkwrap .page-header--fullwidthimage {
    height: auto;
  }
  html.header--shrinkwrap .page-header--fullwidthimage .page-header__textcontent {
    padding: 46px 0 44px 0;
  }
}
@media (min-width: 640px) and (max-width: 999px) {
  .page-header--fullwidthimage .page-header__textcontent {
    grid-column: var(--pagegrid-col-verywide);
  }
}