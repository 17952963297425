/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget-image figure {
  margin: 0;
}

.widget-image__image {
  display: block;
  width: 100%;
}

.widget-image__image + * {
  margin-top: 5px;
}

.widget-image__copyrightholder {
  font-size: 15px;
  line-height: 18px;
  color: #767676;
}

.widget-image__caption.widget-image__caption > * {
  font-size: 15px;
  line-height: 18px;
}