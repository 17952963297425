/*@import "../shared/config.scss";*/
/*
$sidebar-search-font:         17px "Alegreya Sans";
$sidebar-search-icon-fontsize: 16px;
$sidebar-search-height:       37px;

$sidebar-search-bgcolor:      transparent;
$sidebar-search-font:         17px "Alegreya Sans";
$sidebar-search-radius:       3px;
$sidebar-search-icon-fontsize: 16px;
$sidebar-search-height:       37px;

$sidemainmenu_closebuttonoutside: false;

$sidemainmenu-position:       "right"; // "left" or "right"
$sidemainmenu-padding-top:    5px;
$sidemainmenu-padding-right:  25px;
$sidemainmenu-padding-bottom: 25px;
$sidemainmenu-padding-left:   25px;

$sidemainmenu-toggler-width:  25px;
$sidemainmenu-toggler-margin: 20px;

$sidebar-menu-item-color: #FFFFFF;
*/
.sidebar {
  --contentpanels-radius-small: 3px;
  --formcontrols-height: 37px;
  --textcontrols_placeholdercolor: #FFFFFF;
  --textcontrols_textcolor: #FFFFFF;
}

.sidebar__search__input.sidebar__search__input.sidebar__search__input {
  background: transparent;
  border: 1px solid #FFFFFF;
}

.sidebar__search__submit.sidebar__search__submit::before {
  color: #FFFFFF;
  font-size: 16px;
}

.sidebar__search__input.sidebar__search__input:focus {
  border-width: 2px;
}

.sidebar-home-item .sidemainmenu__item__link {
  align-items: center;
}

.sidebar-home-item .sidemainmenu__item__link::before {
  margin-right: 5px;
  content: "\f015";
  font: var(--fontawesome-regular);
  color: #FFFFFF;
}

html {
  /* NEW settings (in ZVI + JZOJP) */
  --sidebar-background: var(--themecolor-pink);
  --sidebar-box-shadow: 0 0 5px rgba(0, 0, 0, .5);
  /* NEW settings (SU + ACOI/ZVI) + JZOJP(may2024) */
  --sidebar-menu-itemhover-background: rgba(255, 255, 255, 0.2);
  --sidebar-menu-itemhover-textcolor: #FFFFFF;
  --sidebar-menu-itemhover-textdecoration: none;
  --sidemainmenu-padding-top: 5px;
  --sidemainmenu-padding-right: 24px;
  --sidemainmenu-padding-bottom: 24px;
  --sidemainmenu-padding-left: 24px;
  /* Sidebar header */
  --sidebar-logo-height: 30px;
  --sidebar-content-indent: 0px; /* To align content with the menu (in case the toggle icons are in front of the menu item titles) */
  --sidebar-closebutton-color: #FFFFFF;
  --sidebar-closebutton-size: 26px;
  --sidebar-secondarylinks-color: #FFFFFF;
  --sidebar-secondarylinks-font: 15px var(--rtd-text-fontfamily);
  --sidemainmenu-toggler-width: 25px;
  --sidemainmenu-toggler-margin: 20px;
  --sidebar-menu-toggle-color: #FFFFFF;
  --sidebar-menu-toggle-color-hover: #FFFFFF;
  --sidebar-menu-toggle-fontsize: 16px;
  /*
    --sidebar-menu-level1-toggleicon-font:              var(--fontawesome-regular);
    --sidebar-menu-level1-toggleicon-collapsed-content: var(--fa-chevron-right);
    --sidebar-menu-level1-toggleicon-expanded-content:  var(--fa-chevron-down);
    --sidebar-menu-level1-toggleicon-fontsize:          14px;
  */
  /* Togglers level 1 */
  --sidebar-menu-level1-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level1-toggleicon-collapsed-content: var(--fa-angle-right);
  --sidebar-menu-level1-toggleicon-expanded-content: var(--fa-angle-down);
  --sidebar-menu-level1-toggleicon-fontsize: 16px;
  /* Togglers level 2 */
  --sidebar-menu-level2-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level2-toggleicon-collapsed-content: var(--fa-angle-right);
  --sidebar-menu-level2-toggleicon-expanded-content: var(--fa-angle-down);
  --sidebar-menu-level2-toggleicon-fontsize: 16px;
  /* Togglers level 3 */
  --sidebar-menu-level3-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level3-toggleicon-collapsed-content: var(--fa-angle-right);
  --sidebar-menu-level3-toggleicon-expanded-content: var(--fa-angle-down);
  --sidebar-menu-level3-toggleicon-fontsize: 16px;
  /* Menu item at level 1 */
  --sidebar-menu-level1-font: 500 16px/20px "Alegreya Sans";
  --sidebar-menu-level1-padding: 8px 10px 7px 10px;
  --sidebar-menu-level1-expandedbgcolor: transparent;
  --sidebar-menu-level1-textcolor: #FFFFFF;
  /* Menu item at level 2 */
  /* Levels 2, 3 and 4 are within the pulldown */
  --sidebar-menu-level2-font: 300 16px/20px "Alegreya Sans";
  --sidebar-menu-level2-padding: 8px 10px 7px 10px;
  --sidebar-menu-level2-expandedbgcolor: transparent;
  --sidebar-menu-level2-textcolor: #FFFFFF;
  /* Menu item at level 3 */
  --sidebar-menu-level3-font: 300 16px/20px "Alegreya Sans";
  --sidebar-menu-level3-padding: 8px 10px 7px 25px;
  --sidebar-menu-level3-textcolor: #FFFFFF;
  /* Menu item at level 4 */
  --sidebar-menu-level4-font: 300 15px/20px "Alegreya Sans";
  --sidebar-menu-level4-padding: 7px 10px 6px 40px;
  --sidebar-menu-level4-textcolor: #FFFFFF;
}

.sidemainmenu__item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.sidemainmenu__item:last-child {
  border-bottom: none;
}

.sidemainmenu-level1__item.sidemainmenu__item--expand {
  background-color: #B82B5E;
}

.sidemainmenu-level2__item.sidemainmenu__item--expand {
  background-color: #9F0A4A;
}

.sidemainmenu__item__link.sidemainmenu__item__link {
  /*
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 2px;
  */
  text-underline-offset: 3px;
  display: flex;
}

.sidemainmenu__item__link.sidemainmenu__item__link > * {
  flex: 1 1 0px;
}

.sidemainmenu__item__toggle.sidemainmenu__item__toggle {
  flex: none;
  position: relative;
  order: 2;
  margin-left: auto;
  padding-left: 10px;
  display: block;
  overflow: hidden;
  text-underline-offset: 30px;
  text-decoration-color: transparent;
}

/*
  .sidemainmenu-level1__selected.sidemainmenu-level1__selected
, .sidemainmenu-level2__selected.sidemainmenu-level2__selected
, .sidemainmenu-level3__selected.sidemainmenu-level3__selected
, .sidemainmenu-level4__selected.sidemainmenu-level4__selected
{
  text-decoration:           underline;
  text-decoration-color:     var(--color-salmon);
  text-decoration-thickness: 2px;
  text-underline-offset:     4px;
}
*/
.sidebar__header.sidebar__header {
  margin-left: 10px;
}

.sidebar__header__close:hover {
  color: var(--color-theme);
}

.sidebar__header__close:hover::before {
  font-weight: 900;
}

.sidebar__search,
.sidebar__relatedwebsites,
.sidebar__languages {
  margin-top: 20px;
}

.sidebar__secondarylinks {
  margin-top: 46px;
}

/* Highlight the current path */
.sidemainmenu-level2__itemlink.sidemainmenu--currentpath,
.sidemainmenu-level2__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level3__item,
.sidemainmenu-level2__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level4__item,
.page__currentlevel_nav .sidemainmenu-level1__item {
  position: relative;
}

.sidemainmenu-level2__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level3__item::after,
.page__currentlevel_nav .sidemainmenu-level1__item::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  border-left: 5px solid var(--color-purple3);
}

.sidemainmenu-level2__itemlink.sidemainmenu--currentpath::after,
.page__currentlevel_nav .sidemainmenu-level1__item.sidemainmenu--currentpath::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  border-left: 5px solid var(--color-purple1);
}

.sidemainmenu-level3__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level4__item {
  /*  outline: 2px solid #F00;*/
}

.sidemainmenu-level3__itemlink.sidemainmenu--currentpath + ul .sidemainmenu-level4__item::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  border-left: 5px solid #F5F5F5; /* var(--color-purple3); */
}

.sidemainmenu-level4__item:first-child {
  padding-top: 2px; /* instead of 9px */
}

.sidemainmenu-level4__item:last-child {
  padding-bottom: 5px; /* instead of 9px */
}