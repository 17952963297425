/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
html.fullscreenslideshowactive {
  overflow: hidden;
}

.fullscreenslideshow {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  color: #FFFFFF;
  background-color: var(--tagcolor-darkpink);
}

.fullscreenslideshow--active {
  display: block;
}

.fullscreenslideshow__title,
.fullscreenslideshow__close,
.fullscreenslideshow__bullets {
  position: relative;
  z-index: 10;
}

.fullscreenslideshow__title {
  position: absolute;
  width: 100%;
  padding-left: 120px;
  padding-right: 120px;
  top: 40px;
  font: bold 28px/32px var(--rtd-text-fontfamily);
  text-align: center;
}

.fullscreenslideshow__close {
  position: absolute;
  top: 40px;
  right: 30px;
  cursor: pointer;
}

.fullscreenslideshow__close::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  font-size: 30px;
}

.fullscreenslideshow__previousbutton,
.fullscreenslideshow__nextbutton {
  display: grid;
  z-index: 1;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 50%;
  width: 38px;
  height: 45px;
  margin-top: -22px;
  color: #FFFFFF;
  cursor: pointer;
}

.fullscreenslideshow__previousbutton svg,
.fullscreenslideshow__nextbutton svg {
  grid-column: 1;
  grid-row: 1;
}

.fullscreenslideshow__previousbutton::after,
.fullscreenslideshow__nextbutton::after {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
}

.fullscreenslideshow__previousbutton:focus,
.fullscreenslideshow__nextbutton:focus,
.fullscreenslideshow__previousbutton:hover,
.fullscreenslideshow__nextbutton:hover {
  outline: 0;
  color: var(--tagcolor-darkpink);
}

.fullscreenslideshow__previousbutton svg,
.fullscreenslideshow__nextbutton svg {
  fill: var(--tagcolor-darkpink);
}

.fullscreenslideshow__previousbutton:focus svg,
.fullscreenslideshow__nextbutton:focus svg {
  fill: rgba(255, 255, 255, 0.5);
}

.fullscreenslideshow__previousbutton:hover svg,
.fullscreenslideshow__nextbutton:hover svg {
  fill: #FFFFFF;
}

.fullscreenslideshow__previousbutton {
  left: 30px;
}

.fullscreenslideshow__previousbutton::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f104";
  font-size: 28px;
  margin-right: 2px;
}

.fullscreenslideshow__nextbutton {
  right: 30px;
}

.fullscreenslideshow__nextbutton::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f105";
  font-size: 28px;
  margin-left: 1px;
}

.fullscreenslideshow__bullets {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
}

.fullscreenslideshow__bullets > * {
  padding: 5px;
  opacity: 0.5;
}

.fullscreenslideshow__bullets > *::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.fullscreenslideshow__bullet {
  cursor: pointer;
}

.fullscreenslideshow__bullet--selected {
  opacity: 1;
}

.fullscreenslideshow__items {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

/*
.widget-fullscreenslideshow__items::-webkit-scrollbar
{
  display: none;
  // width: 0 !important
}
.widget-fullscreenslideshow__items
{
  overflow: -moz-scrollbars-none; // old Firefox up till 63
  scrollbar-width: none; // Firefox 64+ (dec 2018)
}
*/
.fullscreenslideshow__item {
  padding-top: 114px;
  flex: 0 0 100%;
  scroll-snap-align: start;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fullscreenslideshow__item__idx {
  position: absolute;
  top: 40px;
  left: 25px;
  font: 28px var(--rtd-text-fontfamily);
}

.fullscreenslideshow__item__image {
  width: 100%;
  height: 100%;
  max-width: calc(100% - 196px);
  max-height: calc(100% - 200px);
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.fullscreenslideshow__item__caption {
  font: 18px var(--rtd-text-fontfamily);
  text-align: center;
}

.fullscreenslideshow__item__caption + .fullscreenslideshow__item__copyright {
  margin-top: 9px;
}

.fullscreenslideshow__item__copyright {
  font: 16px var(--rtd-text-fontfamily);
  font-style: italic;
  text-align: center;
}

.fullscreenslideshow__item__caption .fullscreenslideshow__item__copyright {
  width: 75%;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 550px) {
  .fullscreenslideshow__previousbutton,
  .fullscreenslideshow__nextbutton {
    top: auto;
    bottom: 30px;
  }
  .fullscreenslideshow__bullets {
    position: absolute;
    bottom: 38px;
    left: 80px;
    right: 80px;
    flex-wrap: wrap;
    justify-content: center;
  }
}