@charset "UTF-8";
/*

Notes on styling checkboxes directly:

- Set the appearance to none to make it a "normal" element which supported ::before and ::after
- Webkit browsers (en Opera) already allowed styling of checkboxes/radiobuttons in 2012
- Since Firefox 54 (juni 2017) also supports this
- Internet Explorer isn't relevant anymore

Also see:
- https://gitlab.webhare.com/webharebv/accessibility/-/issues/7
  ("Afstappen van gebruik <label> als weergave voor checkbox/radiobuttons?"")

*/
.wh-styledinput {
  /*
    &:focus-visible + label
    {
      border-color: #FFFFFF;
      box-shadow: 0 0 3px 1px var(--formcontrol-checkradio-checked-bordercolor);
    }

    // NOTE: We work around SASS freaking out with the @supports
    //@supports not (:focus-visible)
    // @supports not (*:focus-visible)
    @supports not (*:focus-visible)
    {
      &:focus + label
      {
        border-color: #FFFFFF;
        box-shadow: 0 0 3px 1px var(--formcontrol-checkradio-checked-bordercolor);
      }
    }

    &:not(:checked):focus + label
    {
      border-color: #000000;
    }

    &:checked:focus + label
    {
  / *
      outline: 2px dotted #212121;
      outline-offset: 2px;
      outline: -webkit-focus-ring-color auto 5px;
  * /
    }
  */
}
.wh-styledinput input[type=check] {
  position: relative;
  top: var(--formcontrol-check-shift);
}
.wh-styledinput input[type=radio] {
  position: relative;
  top: var(--formcontrol-radio-shift);
}
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  flex: none; /* we never want to be sqeezed + the Publisher forms may give us flex: 1; */
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--formcontrol-checkradio-size);
  max-width: var(--formcontrol-checkradio-size);
  flex: 0 0 var(--formcontrol-checkradio-size);
  height: var(--formcontrol-checkradio-size);
  vertical-align: baseline;
  cursor: pointer;
  border: var(--formcontrol-checkradio-borderwidth) solid var(--formcontrol-checkradio-bordercolor);
  background-color: #fff;
}
.wh-styledinput input[type=radio][disabled],
.wh-styledinput input[type=checkbox][disabled] {
  border-color: var(--controls_outline_color-disabled);
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  color: var(--formcontrol-checkradio-label-color);
}
.wh-styledinput input[type=checkbox]::after {
  color: var(--formcontrol-checkradio-checked-color);
  font-family: var(--font-awesome);
  font-weight: var(--font-awesome-weight);
  font-size: var(--font-awesome-size);
  line-height: var(--formcontrol-checkradio-size);
  margin-left: var(--font-awesome-offsetx);
  margin-top: var(--font-awesome-offsety);
  content: "​";
}
.wh-styledinput input[type=checkbox]:checked {
  background-color: var(--formcontrol-checkradio-checked-bgcolor);
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
}
.wh-styledinput input[type=checkbox]:checked::after {
  color: var(--formcontrol-checkradio-checked-color);
}
.wh-styledinput input[type=radio] {
  border-radius: 50%;
}
.wh-styledinput input[type=radio]::after {
  position: relative;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.wh-styledinput input[type=radio][disabled]::after {
  background-color: var(--formcontrol-checkradio-disabled-bgcolor);
  border-color: var(--formcontrol-checkradio-disabled-bgcolor);
}
.wh-styledinput input[type=radio][disabled]:checked::after {
  background-color: var(--controls_outline_color-disabled);
}
.wh-styledinput input[type=radio]:checked {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wh-styledinput input[type=radio]::after {
  content: "​";
  width: var(--formcontrol-radio-innersize);
  height: var(--formcontrol-radio-innersize);
}
.wh-styledinput input[type=radio]:checked {
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
}
.wh-styledinput input[type=radio]:checked::after {
  color: var(--formcontrol-checkradio-checked-color);
  border-radius: 50%;
  background-color: var(--formcontrol-radio-checked-color);
}
.wh-styledinput input[type=checkbox] {
  border-radius: var(--formcontrol-checkradio-borderradius);
}
.wh-styledinput input[type=checkbox]:checked {
  border-color: var(--formcontrol-checkradio-checked-bordercolor);
}
.wh-styledinput input[type=checkbox]:checked::after {
  content: "\f00c";
}
.wh-styledinput input[type=checkbox][disabled]::after {
  content: "";
}
.wh-styledinput input[type=checkbox][disabled] {
  background-color: var(--formcontrol-checkradio-disabled-bgcolor);
}
.wh-styledinput input[type=checkbox][disabled]:checked::after {
  color: var(--controls_outline_color-disabled);
}
.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox]:not(:checked),
.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:not(:checked) {
  border-color: var(--controls_outline_color-error);
}