.praktijkvoorbeeld {
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 0;
  word-break: break-word;
}

.praktijkvoorbeeld__meta {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.praktijkvoorbeeld__top {
  margin-bottom: 15px;
  position: relative;
  padding-top: 55%;
  background-color: #EEEEEE;
  overflow: hidden;
}

.widget-praktijkvoorbeelden .praktijkvoorbeeld__top, .widget-praktijkvoorbeelden .praktijkvoorbeeld__top__image,
.widget-praktijkvoorbeelden .widget-pv__introblock::before {
  padding-top: 74%;
}

.praktijkvoorbeeld__top::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  content: url("../../web/img/pageheader-overlay-corner.svg");
  width: 66px;
  height: 39px;
  z-index: 1;
}

.praktijkvoorbeeld__top__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}

.praktijkvoorbeeld__top__image.b-lazy {
  opacity: 0;
}

.praktijkvoorbeeld__top__image.b-lazy.b-loaded {
  opacity: 1;
}

.praktijkvoorbeeld__top__icon {
  position: absolute;
  right: 14px;
  bottom: 0px;
  background: url("../../web/img/honeycomb-bg.svg");
  width: 41px;
  height: 48px;
  background-size: 41px 48px;
  text-align: center;
  z-index: 2;
}

.praktijkvoorbeeld__top__icon::after {
  content: "";
  color: #FFFFFF;
  font-size: 26px;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f105";
  margin-left: 2px;
  line-height: 42px;
  transition: background-color 0.25s;
}

.praktijkvoorbeeld--external .praktijkvoorbeeld__top__icon::after {
  content: "\f340";
  font-size: 22px;
  line-height: 48px;
  margin-left: -1px;
}

.praktijkvoorbeeld__region,
.praktijkvoorbeeld__title,
.praktijkvoorbeeld__source,
.praktijkvoorbeeld__description,
.praktijkvoorbeeld__organizations {
  padding-left: 15px;
  padding-right: 15px;
}

.praktijkvoorbeeld__region {
  order: -1;
  color: var(--tagcolor-darkpurple);
  font: 300 13px var(--rtd-text-fontfamily);
  text-transform: uppercase;
  margin-bottom: 4px;
}

.praktijkvoorbeeld__title {
  font: 500 23px/28px var(--rtd-heading-fontfamily);
  color: var(--tagcolor-darkpurple);
}

.praktijkvoorbeeld__description {
  margin-top: 12px;
  font: 300 16px/22px var(--rtd-text-fontfamily);
  color: #000000;
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.widget-pagehighlights .praktijkvoorbeeld__description {
  -webkit-line-clamp: 5;
}

.praktijkvoorbeeld__description + * {
  margin-top: 15px;
}

.praktijkvoorbeeld__organizations {
  font: 300 15px/20px var(--rtd-text-fontfamily);
  color: var(--tagcolor-darkpurple);
}

.praktijkvoorbeeld__source {
  color: var(--tagcolor-darkpink);
  font: 300 15px/23px var(--rtd-text-fontfamily);
  font-style: italic;
}

.praktijkvoorbeeld:hover .praktijkvoorbeeld__top__image {
  transform: scale(1.1);
}
.praktijkvoorbeeld:hover .praktijkvoorbeeld__top::after {
  background-color: var(--tagcolor-darkpurple);
}
.praktijkvoorbeeld:hover .praktijkvoorbeeld__region, .praktijkvoorbeeld:hover .praktijkvoorbeeld__title,
.praktijkvoorbeeld:hover .praktijkvoorbeeld__organizations {
  color: var(--tagcolor-darkpink);
}