html {
  /*
  ZVI
  --widget-rovid-video-border: 0px;

  --widget-rovid-controlbar-height:        36px;
  --widget-rovid-controlbar-slider-height:  5px;
  --widget-rovid-controlbar-slider-radius:  4px;
  --widget-rovid-controlbar-text-font:     15px/var(--widget-rovid-controlbar-height) var(--rtd-text-fontfamily);
  --widget-rovid-playprogress-knob-size:   15px;

  --widget-rovid-background:         #EEEEEE;
  --widget-rovid-radius:             9px;
  */
  --widget-rovid-video-border: 0px;
  --widget-rovid-controlbar-height: 50px;
  --widget-rovid-controlbar-slider-height: 5px;
  --widget-rovid-controlbar-slider-radius: 4px;
  --widget-rovid-controlbar-text-font: 14px/var(--widget-rovid-controlbar-height) Arial;
  --widget-rovid-playprogress-knob-size: 15px;
  --widget-rovid-background: #FFFFFF;
  --widget-rovid-radius: 0px;
}

/*
.widget--rovid
{
  --accordion-header-minheight: 50px;
  --accordion-header-padding:   5px 0;
  --accordion-item-line-color: var(--color-theme);

  --accordion-header-color: var(--color-theme);
  --accordion-header-font:  16px/24px var(--rtd-text-font);

  --accordion-header-symbol-color:        var(--color-theme);
  --accordion-header-symbol-font:         var(--fontawesome-light);

  --accordion-header-symbol-size:         14px;
  --accordion-header-symbol-content:      var(--fa-chevron-down);
  --accordion-header-symbol-open-content: var(--fa-chevron-up);
  --accordion-header-symbol-shift-y:      6px;

  / *
  --accordion-header-symbol-size:         18px;
  --accordion-header-symbol-content:      var(--fa-angle-down);
  --accordion-header-symbol-open-content: var(--fa-angle-up);
  * /

  --accordion-item-indent: 18px;
  --accordion-header-hover-background: #E1E1E1;
}

.widget-accordion__item__header-icon
{
  padding-right: 22px;
}

.w-details__item__header::before
{
  order: 5;
}
*/