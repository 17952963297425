/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
NOTES:

IE and Edge-legacy require a polyfill for summary+details to work
(https://caniuse.com/details)

The <summary> element cannot be display: flex; in Safari.
Also see:
- https://bugs.webkit.org/show_bug.cgi?id=190065
- https://github.com/philipwalton/flexbugs#flexbug-9

About accordion's and useability:
https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html

*/
.widget-accordion input {
  display: none;
}

.widget-accordion__description {
  margin-bottom: 15px;
}

.widget-accordion__item:last-child {
  border-bottom: 1px solid #C5C5C5;
}

.widget-accordion details summary {
  list-style-type: none;
}
.widget-accordion details summary::-webkit-details-marker {
  display: none;
}
.widget-accordion details summary::marker {
  display: none;
}

.widget-accordion__item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #C5C5C5;
  min-height: 49px;
  padding: 6px 0;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.widget-accordion__item__header::after {
  color: #55095f;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f067";
  margin-left: 20px;
  margin-right: 20px;
}

.widget-accordion__item__header:hover {
  background-color: #F5F5F5;
}

.widget-accordion__item__header:hover::after {
  font-weight: bold;
  transform: translate(1px, -1px);
}

.widget-accordion__item[open] .widget-accordion__item__header::after {
  content: "\f068";
}

.widget-accordion__item__body__content {
  padding-top: 5px;
  padding-bottom: 22.5px;
}