/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
.page__contentarea > .searchresults:first-child {
  margin-top: 25px;
}

html.page--search .page__contentarea {
  padding-bottom: 60px;
}

.search__header {
  grid-column: 1/span 27;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  height: 177px;
  background-color: #F7F7F7;
  grid-template-rows: 1fr min-content min-content;
}

.search__header__title {
  grid-column: 5/span 19;
  grid-row: 1;
  align-self: center;
  color: var(--tagcolor-darkpurple);
}

@media (max-width: 640px) {
  .search__header__title {
    font-size: 25px;
  }
}
.search__header__title__resultscount {
  font-weight: normal;
}

.searchpage__searchcontrol {
  position: relative;
}
@media (min-width: 501px) {
  .searchpage__searchcontrol {
    display: none;
  }
}

.search__header__query {
  grid-column: 5/span 19;
  grid-row: 2;
}

.search__header__query + .search__header__category {
  margin-top: 20px;
}

.search__header__category {
  border: 0;
  padding: 0;
  margin: 0;
  grid-column: 5/span 19;
  grid-row: 3;
  border-bottom: 1px solid #C5C5C5;
}

.search__header__category__content {
  display: flex;
}

fieldset.search__header__category > legend {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.search__header__category input {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  background-color: transparent;
  border: 0;
  margin: 0;
}

.search__header__category input + label {
  display: block;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  font: 18px var(--rtd-text-fontfamily);
  padding-bottom: 15px;
  padding-right: 30px;
  margin-right: 30px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
@media (max-width: 640px) {
  .search__header__category input + label {
    padding-right: 14px;
    margin-right: 15px;
  }
}

.search__header__category input:checked + label {
  border-bottom-color: var(--tagcolor-darkpurple);
  font-weight: bold;
}

.search__header__category input:focus-visible + label {
  outline: var(--tabstrip-item-focus-outline);
  outline-offset: var(--tabstrip-item-focus-outline-offset);
}

.searchresults {
  margin-top: 54px;
}

.searchpage__searchcontrol .searchcontrol__input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 41px;
  font: 18px var(--rtd-text-fontfamily);
  border: 1px solid #AAAAAA;
  border-radius: 0px;
  padding: 1px 40px 1px 10px;
}

.searchpage__searchcontrol .searchcontrol__input:focus {
  border: 1px solid #000000;
  outline: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.searchpage__searchcontrol .searchcontrol__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 2px;
  top: 0;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchpage__searchcontrol .searchcontrol__submit::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f002";
  font-size: 16px;
}

.searchpage__searchcontrol .searchcontrol__submit:hover::before {
  color: var(--themecolor);
}

.searchresults {
  margin-top: 15px;
  grid-column: 1/span 27;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.searchresult.searchresult.searchresult {
  display: block;
  text-decoration: none;
  color: #666666;
}

.searchresult__content {
  padding-top: 15px;
  padding-bottom: 15px;
}

.searchresult__image,
.searchresult__imageplaceholder {
  width: 100%;
  padding-top: 75%;
  border-radius: 0;
}

.searchresult__image {
  background-size: cover;
}

.searchresult__imageplaceholder {
  background-color: #F0F0F0;
}

.searchresult:hover .searchresult__imageplaceholder {
  background-color: #E0E0E0;
}

.searchresult__metacol {
  flex: 1 1 0px;
  min-width: 0;
}

.searchresult__date {
  color: #767676;
  font: 500 15px/24px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  margin-bottom: 5px;
}

.searchresult__title {
  color: var(--tagcolor-darkpurple);
  margin-bottom: 5px;
}

.searchresult__description {
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.searchresult__description .consilio-match {
  color: #000000;
  font-weight: bold;
}

.searchresult__meta {
  margin-top: 10px;
  font: 15px/18px var(--rtd-text-fontfamily);
  color: #767676;
}

a.searchresult:hover {
  background-color: #F3F3F1;
}

@media (min-width: 600px) {
  .searchresult {
    text-decoration: none;
    color: #666666;
  }
  .searchresult::after {
    content: "";
    flex: 0 100 300px;
    height: 100%;
  }
  .searchresult__content {
    display: flex;
  }
  .searchresult__imagecol {
    flex: none;
    width: 160px;
    margin-right: 30px;
  }
  .searchresult__image {
    width: 100%;
    padding-top: 75%;
  }
  .searchresult + .searchresult {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  html.page--search .page__contentarea > .page-header__title,
  .searchpage__searchcontrol {
    grid-column: 5/span 19;
  }
  .search__header__title,
  .search__header__query,
  .search__header__category {
    grid-column: 5/span 19;
    margin-left: 190px;
    content: "a";
  }
  .searchresult {
    grid-column: 4/span 21;
    padding-left: 15px;
    padding-right: 15px;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
}
@media (max-width: 1024px) {
  html.page--search .page__contentarea > .page-header__title,
  .searchpage__searchcontrol {
    grid-column: 3/26;
  }
  .search__header__title,
  .search__header__query,
  .search__header__category {
    grid-column: 3/26;
    margin-left: 190px;
    content: "b";
  }
  .searchpage__heading__content,
  .searchresult {
    grid-column: 1/span 27;
    padding-left: 15px;
    padding-right: 15px;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
}
@media (max-width: 599px) {
  html.page--search .page__contentarea > .page-header__title,
  .searchpage__searchcontrol {
    grid-column: 3/26;
  }
  .search__header__title,
  .search__header__query,
  .search__header__category {
    grid-column: 3/26;
    margin-left: 0;
    content: "c";
  }
  .searchresult__imagecol {
    display: none;
  }
  .searchresult {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
}