html {
  --color-green: #1E8527;
  --color-green-heading: var(--color-green-bright);
  --color-magenta: #c50d5a;
  --color-green-light-background: #F1F9F1;
  --color-green-large-on-light-background: var(--color-green);
  --color-green-small-on-light-background: #218229; /* 4.6:1 contrast on the lightgreen background */
  /* used for:
     - FFW submenu
     - FFW mainmenu pulldown item hover
     - FFW quote widget background
     - (generic) form infotext background
  */
  --color-sublegrey: #F9F9F9;
  --color-green-small-on-subtlegrey: #208328; /* 4.6:1 als subtlegrey als achtergrondkleur */
  --color-error: #EE0000; /* lightest accessible red, 4.53:1 contrast on white */
  --color-grey-border: #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767677; /* WCAG 4.53:1 contrastratio */
}

.wh-form__fieldgroup--custom-info.wh-form__fieldgroup--custom-info.wh-form__fieldgroup--custom-info {
  grid-column: var(--pagegrid-col-default);
}

.wh-form__fieldgroup--custom-info {
  margin-top: var(--formlayout-fieldlines-spacing) !important;
  margin-bottom: 30px !important;
  padding: var(--forms-infopanel-padding) !important;
  background-color: var(--forms-infopanel-background);
}

h1.wh-form__grouptitle.wh-form__grouptitle {
  margin-top: 0;
}

.wh-form-addition__explainrequired {
  display: flex;
  align-items: center;
  color: var(--forms-infopanel-color);
  font: var(--forms-infopanel-font);
}

.wh-form-addition__explainrequired::before {
  font: var(--forms-infopanel-icon-font);
  font-size: var(--forms-infopanel-icon-size);
  content: var(--fa-info-circle);
  color: var(--color-theme);
  margin-right: 15px;
}

.wh-form__infotextwrapper {
  display: flex;
  align-items: baseline;
  /*
  background-color: var(--color-sublegrey);
  padding: 5px var(--textcontrols_padleft);
  padding-top: 8px;
  */
  margin-top: 5px;
  border-radius: 0 0 4px 4px;
  z-index: -1;
}

.wh-form__fieldgroup--checkboxgroup > .wh-form__fields > .wh-form__infotextwrapper,
.wh-form__fieldgroup--radiogroup > .wh-form__fields > .wh-form__infotextwrapper {
  margin-top: 10px;
  border-radius: 4px;
}

.wh-form__optiondata.wh-form__optiondata {
  --formcontrols-height: 36px;
}

.wh-form .wh-form__fieldgroup--checkboxgroup * + .wh-form__fieldline--subfields {
  margin-top: 6px !important;
}

.wh-form .wh-form__fieldgroup--checkboxgroup * + .wh-form__fieldline--subfields .wh-form__subfield input {
  vertical-align: middle;
}

/*
.wh-form__infotextwrapper::before
{
  @include info-icon;

  position: relative;
  top: 2px;
}
*/
.wh-form__infotext {
  --rtd-text-color: var(--formlayout-fieldgroup-info-color);
  --rtd-text-font: var(--formlayout-fieldgroup-info-font);
}

.wh-form__infotext > :first-child {
  margin-top: 0;
}

.wh-form__infotext > :last-child {
  margin-bottom: 0;
}

.wh-form__infotext ul.unordered.unordered,
.wh-form__infotext ol.ordered.ordered {
  padding-left: 16px;
}

/*
.wh-form__fieldgroup:focus-within .wh-form__infotextwrapper
{
  background-color: #F1F1F1;
}
*/
.wh-form__fieldgroup:focus-within .wh-form__infotextwrapper::before {
  color: var(--color-green-small-on-light-background);
}

.wh-form__fieldgroup--error .wh-form__infotextwrapper.wh-form__infotextwrapper {
  background: transparent;
  z-index: 0; /* -1 would cause us to be behind the red background of the fieldgroup */
}