/* Stretch page and push footer to end of viewport or page */
html {
  display: flex;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: visible;
}

.page__body {
  flex: 1 0 auto;
}

body > * {
  flex: none;
}

.page__balloon {
  flex: 1 0 60px;
}

@media (max-width: 500px) {
  .page__balloon {
    flex-basis: 30px;
  }
}