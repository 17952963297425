/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
button.skiplinks {
  position: absolute;
  left: 220px;
  top: 9px;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 44px;
  color: #FFFFFF;
  background-color: #c10061;
  text-decoration: none;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
}

button.skiplinks:focus {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

main {
  scroll-margin-top: 127px;
}

main:focus {
  outline: 0;
}