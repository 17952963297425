.widget--rovid button:before,
.widget--rovid__fullscreencontent button:before {
  display: none;
}
.widget--rovid video,
.widget--rovid__fullscreencontent video {
  cursor: pointer;
}
.widget--rovid .video-js,
.widget--rovid__fullscreencontent .video-js {
  width: 100%;
  /*
      .vjs-slider
      {
        // background: #7D7D7D;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: var(--widget-rovid-controlbar-slider-radius);
      }
  */
}
.widget--rovid .video-js .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  height: 121px;
  width: 121px;
  transform: translate(-50%, -50%);
  border: 0;
  background: none;
  border-radius: 0;
  background: url("../../web/img/video-play.svg");
}
.widget--rovid .video-js .vjs-big-play-button .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}
.widget--rovid .video-js .vjs-control-bar,
.widget--rovid__fullscreencontent .video-js .vjs-control-bar {
  width: auto;
  /*
        position: absolute;
        left: 10px;
        right: 10px;
        top: auto;
        bottom: 12px;
        border-radius: 6px;
  */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  height: var(--widget-rovid-controlbar-height);
  background-color: rgba(0, 0, 0, 0.7);
}
.widget--rovid .video-js .vjs-play-control,
.widget--rovid__fullscreencontent .video-js .vjs-play-control {
  display: inline-flex;
  align-items: center;
}
.widget--rovid .video-js .vjs-control,
.widget--rovid__fullscreencontent .video-js .vjs-control {
  outline: none;
}
.widget--rovid .video-js .vjs-time-control, .widget--rovid .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid .video-js .vjs-play-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-time-control,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-control .vjs-icon-placeholder:before {
  line-height: var(--widget-rovid-controlbar-height);
}
.widget--rovid .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  font-size: 24px;
}
.widget--rovid .video-js .vjs-play-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-control .vjs-icon-placeholder:before {
  font-size: 26px;
}
.widget--rovid .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 20px;
}
.widget--rovid .video-js .vjs-time-control,
.widget--rovid__fullscreencontent .video-js .vjs-time-control {
  font-size: 14px;
  padding-right: 6px;
}
.widget--rovid .video-js .vjs-control.vjs-play-control,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-play-control {
  order: 0;
}
.widget--rovid .video-js .vjs-control.vjs-currenttime,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-currenttime {
  order: 1;
}
.widget--rovid .video-js .vjs-control.vjs-progress-control,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-progress-control {
  order: 2;
  padding: 0 6px;
}
.widget--rovid .video-js .vjs-control.vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-duration {
  order: 3;
}
.widget--rovid .video-js .vjs-captions-toggle,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle {
  order: 3;
}
.widget--rovid .video-js .vjs-control.vjs-volume-panel,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-volume-panel {
  order: 5;
}
.widget--rovid .video-js .vjs-fullscreen-control,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control {
  order: 6;
}
.widget--rovid .video-js .vjs-time-control.vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-time-control.vjs-duration {
  display: block;
}
.widget--rovid .video-js .vjs-remaining-time,
.widget--rovid__fullscreencontent .video-js .vjs-remaining-time {
  display: none;
}
.widget--rovid .video-js .vjs-load-progress,
.widget--rovid__fullscreencontent .video-js .vjs-load-progress {
  display: none;
}
.widget--rovid .video-js .vjs-play-progress,
.widget--rovid__fullscreencontent .video-js .vjs-play-progress {
  background-color: #0DB24C;
  border-radius: var(--widget-rovid-controlbar-slider-radius);
}
.widget--rovid .video-js .vjs-current-time, .widget--rovid .video-js .vjs-remaining-time-display,
.widget--rovid .video-js .vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-current-time,
.widget--rovid__fullscreencontent .video-js .vjs-remaining-time-display,
.widget--rovid__fullscreencontent .video-js .vjs-duration {
  display: block;
  font: var(--widget-rovid-controlbar-text-font);
  padding: 0;
  opacity: 0.8;
}
.widget--rovid .video-js .vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control,
.widget--rovid .video-js .vjs-volume-bar,
.widget--rovid__fullscreencontent .video-js .vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar {
  height: 100%;
}
.widget--rovid .video-js .vjs-volume-bar,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar {
  margin: 0;
  display: flex;
  align-items: center;
}
.widget--rovid .video-js .vjs-volume-bar:focus,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar:focus {
  outline: 0;
  box-shadow: none;
}
.widget--rovid .video-js .vjs-volume-bar .vjs-volume-level:before,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar .vjs-volume-level:before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -4.5px;
}
.widget--rovid .video-js .vjs-volume-bar:focus .vjs-volume-level:before,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar:focus .vjs-volume-level:before {
  outline: none;
  box-shadow: 0 0 2px rgb(255, 255, 255);
}
.widget--rovid .video-js .vjs-slider-horizontal .vjs-volume-level,
.widget--rovid__fullscreencontent .video-js .vjs-slider-horizontal .vjs-volume-level {
  border-radius: var(--widget-rovid-controlbar-slider-radius);
  height: var(--widget-rovid-controlbar-slider-height);
}
.widget--rovid .video-js .vjs-volume-level.vjs-volume-level,
.widget--rovid__fullscreencontent .video-js .vjs-volume-level.vjs-volume-level {
  position: relative;
  z-index: 1;
}
.widget--rovid .video-js .vjs-volume-bar::after,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  height: var(--widget-rovid-controlbar-slider-height);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: var(--widget-rovid-controlbar-slider-radius);
}
.widget--rovid .video-js .vjs-picture-in-picture-control,
.widget--rovid__fullscreencontent .video-js .vjs-picture-in-picture-control {
  display: none;
}
.widget--rovid .video-js .vjs-captions-toggle, .widget--rovid .video-js .wh-rovid-switchad,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle,
.widget--rovid__fullscreencontent .video-js .wh-rovid-switchad {
  width: auto;
  padding: 0 8px;
  cursor: pointer;
}
.widget--rovid .video-js .vjs-captions-toggle.cc, .widget--rovid .video-js .wh-rovid-switchad.cc,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.cc,
.widget--rovid__fullscreencontent .video-js .wh-rovid-switchad.cc {
  margin-left: 2px;
  margin-right: 0;
}
.widget--rovid .video-js .vjs-captions-toggle.ad, .widget--rovid .video-js .wh-rovid-switchad.ad,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.ad,
.widget--rovid__fullscreencontent .video-js .wh-rovid-switchad.ad {
  margin-right: 3px;
}
.widget--rovid .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder, .widget--rovid .video-js .wh-rovid-switchad.vjs-on .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .wh-rovid-switchad.vjs-on .vjs-icon-placeholder {
  opacity: 1;
}
.widget--rovid .video-js .vjs-captions-toggle .vjs-icon-placeholder,
.widget--rovid .video-js .wh-rovid-switchad .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .wh-rovid-switchad .vjs-icon-placeholder {
  color: #fff;
  display: block;
  cursor: pointer;
  border: 1px solid #fff;
  font-family: Arial;
  font-size: 8px;
  display: flex;
  align-items: center;
  padding: 3px 3px;
  opacity: 0.6;
  letter-spacing: 0.7px;
}
.widget--rovid .video-js .vjs-progress-holder,
.widget--rovid__fullscreencontent .video-js .vjs-progress-holder {
  height: 5px;
  border-radius: 8px;
}
.widget--rovid .video-js .vjs-progress-holder:focus-visible,
.widget--rovid__fullscreencontent .video-js .vjs-progress-holder:focus-visible {
  box-shadow: 0 0 1px 1px rgb(255, 255, 255);
  outline: 0;
}
.widget--rovid .video-js .vjs-play-progress:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-progress:before {
  content: "";
  width: var(--widget-rovid-playprogress-knob-size);
  height: var(--widget-rovid-playprogress-knob-size);
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: calc(-1 * (var(--widget-rovid-playprogress-knob-size) - 5px) / 2);
}
.widget--rovid .video-js.vjs-has-started .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js.vjs-has-started .vjs-big-play-button {
  display: block;
  opacity: 0;
}
.widget--rovid .video-js.vjs-has-started + .widget--rovid__duration,
.widget--rovid__fullscreencontent .video-js.vjs-has-started + .widget--rovid__duration {
  display: block;
}
.widget--rovid .video-js .vjs-text-track-cue,
.widget--rovid__fullscreencontent .video-js .vjs-text-track-cue {
  transform: translateY(-30px);
  line-height: 30px !important;
}
.widget--rovid .video-js .vjs-text-track-cue > div,
.widget--rovid__fullscreencontent .video-js .vjs-text-track-cue > div {
  padding: 5px;
}

.video-js .vjs-control.vjs-control:focus:before,
.video-js .vjs-control.vjs-control:hover:before,
.video-js .vjs-control.vjs-control:focus {
  text-shadow: 0 0 4px rgb(255, 255, 255), 1px 1px 1px rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.1);
}

.vjs-slider::focus,
.widget--rovid .video-js .vjs-volume-bar::after,
.vjs-volume-bar:focus .vjs-volume-level {
  box-shadow: 0 0 1px 1px rgb(0, 0, 0), 0 0 1px 2.5px rgb(255, 255, 255);
}

.vjs-volume-control {
  padding-left: 2px;
  padding-right: 2px;
}

.widget--rovid .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder {
  background-color: var(--color-green);
  color: #FFFFFF;
}