dialog.filteredoverview__teaser {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.filteredoverview__teaser {
  display: none;
  position: fixed;
  margin-top: auto;
  z-index: 99999;
  bottom: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
  pointer-events: none;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.site-dialog .filteredoverview__teaser {
  margin: var(--pagegrid-gutteroutside-mobile);
  width: stretch;
  width: -webkit-fill-available;
}

.filteredoverview__teaser__button {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-default);
  min-height: var(--filteredoverview-teaserbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  border: var(--filteredoverview-teaserbar-border);
  border-radius: var(--filteredoverview-teaserbar-borderradius);
  background-color: var(--filteredoverview-teaserbar-background);
  box-shadow: 3px 2px 4px 6px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

html.filteredoverview--showfilters .filteredoverview__teaser {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

/*
.filteredoverview__teaser--aboveresults
{
  opacity: 1;
  pointer-events: auto;
}
*/
.filteredoverview__teaser__button {
  font: 600 18px/30px var(--rtd-text-fontfamily);
  color: var(--filteredoverview-teaserbar-color);
  transition: background-color 0.25s;
  test: var(--filteredoverview-teaserbar-icon);
  /*
    &:focus
    {
      box-shadow: 0 0 0 2px #000;
      outline: 0;

      // Let Webkit use it's default focus indicator
      @supports (outline: -webkit-focus-ring-color auto 5px)
      {
        box-shadow: none;
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
    &:active:focus
    {
      box-shadow: none;
      outline: none;
    }
  */
}
.filteredoverview__teaser__button:hover {
  background-color: var(--filteredoverview-teaserbar-hover-background);
  color: #FFFFFF;
}

.filteredoverview__teaser__button::after {
  content: ">";
  margin-left: 15px;
  font: var(--filteredoverview-teaserbar-icon-font);
  content: var(--filteredoverview-teaserbar-icon);
  font-size: var(--filteredoverview-teaserbar-icon-size);
  margin-top: 2px;
}

@media (min-width: 376px) {
  .filteredoverview__teaser__button {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 375px) {
  .filteredoverview__teaser__button {
    padding-left: 18px;
    padding-right: 18px;
  }
}