/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.widget-news {
  grid-column: 1/span 27;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-news__topbar {
  grid-column: 1/span 27;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  background-color: var(--tagcolor-darkpink);
}

.widget-news__title {
  grid-column: 3/26;
  align-self: center;
  color: #FFFFFF;
}

.widget-news__content {
  grid-column: 1/span 27;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-news__blocks_background {
  grid-column: 1/span 27;
  grid-row: 1;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  position: absolute;
  width: 100%;
  grid-row: 2;
  background-color: #9F0A4A;
}

.widget-news__blocks_background__sizer {
  display: flex;
  grid-column: 3/26;
}

.widget-news__blocks_background__sizer__item::before {
  content: "";
  display: block;
  padding-top: 66.57%;
}

@media (max-width: 640px) {
  .widget-news {
    padding-bottom: 40px;
  }
  .widget-news__topbar {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .widget-news__title {
    font: var(--rtd-heading-weight-bold) 21px/24px var(--rtd-heading-fontfamily);
  }
  .widget-news__content {
    position: relative;
  }
  .widget-news__blocks_background {
    grid-row: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 110px;
  }
  .widget-news__blocks {
    grid-column: 3/26;
    grid-row: 1;
  }
  .widget-news__moreitems__content.widget-news__moreitems__content {
    padding: 0;
  }
  .widget-news__moreitems__itemslist {
    background-color: #FFFFFF;
    box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 15px 18px;
  }
  .widget-news__moreitems__item:first-child {
    padding-top: 0;
  }
  .widget-news__morelink {
    margin-top: 12px;
  }
  /*
  .widget-news__topbar::before
  {
    content: "1col";
    display: block;
  }
  */
  .widget-news__blocks > *:nth-child(1),
  .widget-news__blocks > *:nth-child(2),
  .widget-news__blocks_background__sizer > *:nth-child(1n+2) {
    display: none;
  }
}
@media (min-width: 640px) {
  .widget-news {
    padding-bottom: 60px;
  }
  .widget-news__topbar {
    height: 110px;
  }
  .widget-news__title {
    font: var(--rtd-heading-weight-bold) 36px var(--rtd-heading-fontfamily);
  }
  .widget-news__blocks {
    grid-column: 3/26;
    grid-row: 1;
    display: flex;
    justify-content: space-between;
  }
  .widget-news__blocks > * {
    background-color: #FFFFFF;
    box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  .widget-news__blocks > *,
  .widget-news__blocks_background__sizer > * {
    flex: 1 1 0px;
    min-width: 0;
  }
  .widget-news__moreitems {
    order: 10;
  }
  .widget-news__item,
  .widget-news__blocks_background__sizer > * {
    margin-right: 30px;
  }
  .widget-news__blocks_background__sizer > *:first-child {
    margin-right: 0;
  }
  .news__moreitems__itemslist {
    margin-bottom: auto;
  }
  .widget-news__morelink {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 640px) and (max-width: 799px) {
  .widget-news__topbar {
    grid-column: 1/span 27;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    height: 110px;
  }
  .widget-news__moreitems__itemslist > *:nth-child(1) {
    display: none;
  }
  .widget-news__moreitems__item:nth-child(2) {
    padding-top: 0;
  }
  .widget-news__title {
    font: var(--rtd-heading-weight-bold) 21/24px var(--rtd-heading-fontfamily);
  }
  .widget-news__blocks > *:nth-child(2),
  .widget-news__blocks_background__sizer > *:nth-child(1n+3) {
    display: none;
  }
}
@media only screen and (min-width: 800px) {
  .widget-news__topbar {
    height: 110px;
  }
  .widget-news__moreitems__itemslist > *:nth-child(1),
  .widget-news__moreitems__itemslist > *:nth-child(2) {
    display: none;
  }
  .widget-news__moreitems__item:nth-child(3) {
    padding-top: 0;
  }
}