/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
html.page--regiobeelden .page__body, html.page--regiobeelden .page__contentarea:not(.page__contentarea--rtddoc),
html.page--regiobeelden .page__contentarea:not(.page__contentarea--rtddoc) > :first-child {
  margin-top: 0 !important;
}
html.page--regiobeelden .page__contentarea:not(.page__contentarea--rtddoc) .page__shareoptions {
  grid-column: 1/span 27;
  width: 100%;
  margin-top: 0;
  border-top: 1px solid var(--tagcolor-darkpink);
  padding: 30px 0;
  margin-bottom: 0;
}
@media (min-width: 800px) {
  html.page--regiobeelden .page__contentarea:not(.page__contentarea--rtddoc) .page__balloon {
    flex: 0 0 0px;
  }
}

.regiobeelden__divider {
  border-top: 1px solid var(--tagcolor-darkpink);
}

.regiobeelden__intro {
  color: #333333;
  padding: 25px 0;
}

.regiobeelden__intro h1 {
  font: 48px/48px var(--rtd-text-fontfamily);
  color: #ca005d;
}

.regiobeelden__intro p {
  font: 19px/32px var(--rtd-text-fontfamily);
}

.regiobeelden__overview {
  display: flex;
}

.regiobeelden__map {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #F4F4F4;
}

.regiobeelden__map__caption {
  margin-top: 30px;
  width: 100%;
  display: inline-block;
  border-radius: 3px;
  background-color: #E7E7E7;
  color: #5D5D5D;
  padding: 15px;
  text-align: center;
}

.regiobeelden__map__caption__returnbutton {
  margin-left: auto;
  margin-right: auto;
}

.regiobeelden__map__caption__title {
  font: 15px/16px var(--rtd-text-fontfamily);
  color: #333333;
  font-weight: bold;
}

.regiobeelden__map__caption__description {
  font: 14px/16px var(--rtd-text-fontfamily);
  font-style: italic;
}

.regiobeelden__map__caption__title + .regiobeelden__map__caption__description {
  margin-top: 5px;
}

@media (max-width: 799px) {
  .regiobeelden__intro,
  .regiobeelden__overview {
    grid-column: 3/26;
  }
  .regiobeelden__divider {
    grid-column: 1/span 27;
  }
  .regiobeelden__map {
    display: none;
  }
  .regiobeelden__list {
    width: 100%;
  }
  .regiobeelden__overview {
    grid-column: 3/26;
  }
  .regiobeelden__list__filterpanel {
    padding: 20px 0 20px 0;
  }
}
@media (min-width: 800px) {
  .regiobeelden__intro,
  .regiobeelden__overview {
    grid-column: 3/26;
  }
  .regiobeelden__divider {
    grid-column: 1/span 27;
  }
  .regiobeelden__overview {
    grid-column: 1/span 25;
  }
  .regiobeelden__map,
  .regiobeelden__list {
    flex: 0 0 50%;
    min-width: 0;
  }
  .regiobeelden__map {
    padding: 30px;
  }
  .regiobeelden__map__content {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
  }
  /*
    .regiobeelden__list__filterpanel
    {
      position: -webkit-sticky;
      position: sticky;
      top: 73px;
      background-color: #FFFFFF;
      z-index: 1;

      box-shadow: 0 2px 8px 3px rgba(0,0,0,0.1);
    }
  */
  .regiobeelden__map svg,
  .regiobeelden__map img,
  .regiobeelden__map__caption {
    display: block;
    margin-left: auto;
    width: 465px;
    max-width: 100%;
  }
  .regiobeelden__overview[data-regioindeling=roaz] .regiokaart-zorgkantoren,
  .regiobeelden__overview[data-regioindeling=roaz] .for-zorgkantoren {
    display: none;
  }
  .regiobeelden__overview:not([data-regioindeling=roaz]) .regiokaart-roaz,
  .regiobeelden__overview:not([data-regioindeling=roaz]) .for-roaz {
    display: none;
  }
  .regiobeelden__overview[data-regioindeling=custom] .regiobeelden__map__content {
    pointer-events: none;
  }
  .regiobeelden__overview[data-regioindeling=custom] .regiobeelden__map__caption {
    display: none;
  }
  .regiobeelden__overview[data-regioindeling=custom] .zorgregiokaart__regio {
    fill: #D5D5D5;
  }
  .regiobeelden__list {
    padding-bottom: 200px;
  }
  .regiobeelden__list__filterpanel,
  .regio {
    max-width: 660px;
  }
  .regiobeelden__list__filterpanel {
    padding: 10px 0 10px 32px;
  }
  .regio {
    padding-left: 32px;
    padding-right: 0;
  }
}
@media (min-width: 1100px) {
  .regiobeelden__map {
    padding: 100px 30px;
  }
}
.zorgregiokaart__regio.regio--active {
  cursor: pointer;
}

.zorgregiokaart__regio {
  transition: fill 0.15s, opacity 0.15s;
}

/*
svg .zorgregiokaart__regios > g:not(.regio--active) //not(:hover)
{
  fill: #BED3E7;
}
*/
.zorgregiokaart--activeselection .zorgregiokaart__regio {
  opacity: 0.5;
}

.zorgregiokaart--activeselection .zorgregiokaart__regio.regio--active,
.zorgregiokaart:hover .zorgregiokaart__regio {
  opacity: 0.25;
}

.zorgregiokaart--activeselection g#Zorgkantoor-Utrecht.regio--active {
  opacity: 0.48;
}

svg .zorgregiokaart > g.regio--active {
  cursor: pointer;
}

.zorgregiokaart__regio.regio--active:hover,
.zorgregiokaart--activeselection .zorgregiokaart__regio.regio--selected {
  z-index: 10;
  opacity: 1 !important;
  transition: none;
  filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.7));
}

.regio__description,
.regio__contact,
.regio__other {
  display: none;
}

.regio--expanded .regio__description, .regio--expanded .regio__contact,
.regio--expanded .regio__other {
  display: block;
}
.regio--expanded .regio__readmore {
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.regiobeelden__list__returnbutton,
.regiobeelden__map__caption__returnbutton {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  display: none;
  align-items: center;
  padding: 0;
  font-size: 16px;
  font-family: inherit;
  background-color: transparent;
  color: #5D5D5D;
  cursor: pointer;
}

.regiobeelden__list__returnbutton span,
.regiobeelden__map__caption__returnbutton span {
  font-size: 16px;
  margin-right: 10px;
}

.regiobeelden__list__returnbutton:hover,
.regiobeelden__map__caption__returnbutton:hover {
  text-decoration: underline;
}

.regiobeelden__overview--overig .regiobeelden__list__returnbutton,
.regiobeelden__overview--activeselection .regiobeelden__list__returnbutton,
.regiobeelden__overview--overig .regiobeelden__map__caption__returnbutton,
.regiobeelden__overview--activeselection .regiobeelden__map__caption__returnbutton {
  display: flex;
}

.regiobeelden__overview--overig .regiobeelden__list__filterpanel label,
.regiobeelden__overview--activeselection .regiobeelden__list__filterpanel label,
.regiobeelden__overview--overig .regiobeelden__map__caption__text,
.regiobeelden__overview--activeselection .regiobeelden__map__caption__text {
  display: none;
}

.regiobeelden__list__filterpanel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 94px;
  background-color: #FFFFFF;
  color: #333333;
}

.regiobeelden__list__filterpanel label {
  color: #5D5D5D;
  font: 16px/24px var(--rtd-text-fontfamily);
  text-transform: uppercase;
  margin-right: 15px;
}

.regiobeelden__list__filterpanel select {
  padding-right: 50px;
}

/*
  .regiobeelden__list-zorgregions
, .regiobeelden__list-customregions
{
  display: none;
}

.regiobeelden__overview--zorgkantoorregio .regiobeelden__list-zorgregions
{
  display: block;
}
.regiobeelden__overview--overig .regiobeelden__list-customregions
{
  display: block;
}
*/
.regio {
  position: relative;
}

.regio .widget--withtitle {
  margin-top: 30px;
}

.rtdcontent--regio a {
  color: #ca005d;
}

.regio__content {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #B9B9B9;
}

.regiobeelden__list .regio--selected::before {
  content: "";
  position: absolute;
  left: 12px;
  top: 0px;
  bottom: 0px;
  width: 8px;
  background-color: var(--tagcolor-darkpink);
  opacity: 0.39;
}
@media (max-width: 799px) {
  .regiobeelden__list .regio--selected::before {
    left: -15px;
  }
}

.regio__link {
  text-decoration: none;
}

.regio__title {
  color: #56145F;
  font: var(--rtd-h3-font);
}

.regio__title:hover {
  text-decoration: underline;
}

.regio .widget-downloadsandlinks h3 {
  font: var(--rtd-h3-font);
}

.regio__custommeta {
  color: #333333;
  font: 300 15px/24px var(--rtd-text-fontfamily);
  font-style: italic;
}

.regio__custommeta a {
  color: #333333;
  text-decoration: underline;
}

.regio__intro,
.regio__description {
  margin-top: 10px;
  color: #333333;
  --rtd-text-font: var(--rtd-text-small-font);
  --rtd-h3-font: var(--rtd-h4-font);
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  /*
    display: -webkit-box; // ONLY works with -webkit-box
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  */
}

.regio__readmore:focus-visible + .regio__description {
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 5px;
}

.regio__readmore {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  align-items: baseline;
  color: var(--rtd-link-color);
  font: 14px/24px var(--rtd-text-fontfamily);
  text-transform: uppercase;
}

.regio__readmore::before {
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  width: 24px;
}

.regio--expanded .regio__readmore::before {
  content: "\f068";
}

.regio__other {
  margin-top: 25px;
  color: #5F2067;
  font: 16px/28px var(--rtd-text-fontfamily);
  padding: 15px 15px 0 15px;
  background-color: #F9E9F1;
}

.regio__other__list {
  list-style-type: none;
  margin: 15px 0 0 0;
  padding: 0;
}

.regio__other__list li,
.regio__other__list a {
  min-height: 50px;
  color: inherit;
  font: bold 15px/51px var(--rtd-text-fontfamily);
}

.regio__other__list li {
  border-top: 1px solid #c8a9c6;
  display: flex;
  align-items: center;
}

.regio__other__list li::before {
  flex: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f061";
  margin-left: 7px;
  margin-right: 15px;
}

.regio__other__list a {
  text-decoration: none;
}

.regio__other__list a:hover {
  text-decoration: underline;
}