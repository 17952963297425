/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
.newsitemsoverview.newsitemsoverview {
  margin-top: 50px;
}

.newsitemsoverview__searchpanel.newsitemsoverview__searchpanel.newsitemsoverview__searchpanel.newsitemsoverview__searchpanel {
  margin-top: 24px;
  grid-column: 11/span 15;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /*
    @media (min-width: $pageheader-smallhoneycomb-mobileuptill + 1)
    {
      grid-column: 10 / span -3;
    }
  */
}
@media (max-width: 800px) {
  .newsitemsoverview__searchpanel.newsitemsoverview__searchpanel.newsitemsoverview__searchpanel.newsitemsoverview__searchpanel {
    grid-column: 3/26;
  }
}

.newsitemsoverview__searchpanel__query.newsitemsoverview__searchpanel__query {
  width: 100%;
  height: 46px;
  font: 19px var(--rtd-text-fontfamily);
  border-radius: 2px;
  padding-left: 10px;
  font: 20px var(--rtd-text-fontfamily);
}

.newsitemsoverview__searchpanel__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 24px;
  color: #c10061;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 5px;
  padding-right: 15px;
}

.newsitemblock {
  display: block;
  background-color: #FFFFFF;
  box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  opacity: 1;
  touch-action: manipulation;
  cursor: pointer;
  text-decoration: none;
}

.newsitemblock__imagewrapper {
  overflow: hidden;
}

.newsitemblock__image {
  padding-top: 66.57%;
}

.newsitemblock__image {
  display: block; /* in case it's an <a> */
  background-size: cover;
  transform: scale(1); /* intent to scale */
  transition: transform 0.15s;
}

.newsitemblock:hover .newsitemblock__image {
  transform: scale(1.1);
}

.newsitemblock__meta {
  padding: 22px 17px 25px 17px;
}

.newsitemblock__date {
  color: #ca005d;
  font: 17px var(--rtd-heading-fontfamily);
}

.newsitemblock__title {
  color: var(--tagcolor-darkpurple);
  font: var(--rtd-heading-weight-bold) 20px/22px var(--rtd-heading-fontfamily);
}

.newsitemblock__description {
  color: #3F3F3F;
  font: 17px/22px var(--rtd-heading-fontfamily);
}

@media (max-width: 500px) {
  .newsitemsoverview > * {
    flex: 0 0 100%; /* only used by carrousel mode for mobile */
  }
  .newsitemsoverview > * + * {
    margin-top: 20px;
  }
}
@media (min-width: 501px) {
  .newsitemsoverview {
    grid-column: 3/26;
    display: flex;
    flex-flow: row wrap;
    margin-left: -30px;
  }
  .newsitemsoverview > * {
    margin-bottom: 20px;
    margin-left: 30px;
  }
}
/*
Most iPad's currently have 768px on the small side
2 column
*/
@media (min-width: 501px) and (max-width: 899px) {
  .newsitemsoverview {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
  .newsitemsoverview > * {
    flex: 0 0 auto;
    width: calc(50% - 30px);
    margin-bottom: 20px;
  }
}
@media (min-width: 900px) {
  .newsitemsoverview > * {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
  .newsitemsoverview > * {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 30px);
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .eventsoverview > * {
    flex: 0 0 100%; /* only used by carrousel mode for mobile */
  }
  .eventsoverview > * + * {
    margin-top: 20px;
  }
}
@media (min-width: 501px) {
  .eventsoverview {
    grid-column: 3/26;
    display: flex;
    flex-flow: row wrap;
    margin-left: -30px;
  }
  .eventsoverview > * {
    margin-bottom: 20px;
    margin-left: 30px;
  }
}
/*
Most iPad's currently have 768px on the small side
2 column
*/
@media (min-width: 501px) and (max-width: 899px) {
  .eventsoverview {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
  .eventsoverview > * {
    flex: 0 0 auto;
    width: calc(50% - 30px);
    margin-bottom: 20px;
  }
}
@media (min-width: 900px) {
  .eventsoverview > * {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
  .eventsoverview > * {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 30px);
    margin-bottom: 20px;
  }
}