.resultscontainer--documentsoverview {
  display: flex;
  flex-direction: column;
  row-gap: var(--overview-items-gap-v);
}

.resultscontainer--documentsoverview,
.praktijkvoorbeelden__map {
  --button-height: 34px;
  --button-font: 16px var(--rtd-heading-fontfamily);
  --button-padding: 2px 15px 2px 17px;
  --button-background: var(--tagcolor-darkpurple);
  --button-hover-background: var(--themecolor-purple-hover);
  --button-outline-border: 1px solid var(--tagcolor-darkpurple);
  --button-outline-textcolor: var(--tagcolor-darkpurple);
  --button-outline-hover-background: var(--tagcolor-darkpurple);
}

.praktijkvoorbeelden__map {
  --button-height: 32px;
  --button-font: 15px var(--rtd-heading-fontfamily);
  --button-padding: 2px 11px 2px 11px;
}
.praktijkvoorbeelden__map .wh-form__button .icon {
  margin-right: 7px;
  position: relative;
  top: -1px;
}

.jzojp-documenttile__buttonbar .wh-form__button--solid .icon {
  color: #FFFFFF;
}

.jzojp-documenttile-pdfbutton {
  min-width: 265px; /* zodat de breedte gelijk is tussen plannen en voorstellen knoppen */
}

.jzojp-documenttile-pdfbutton .icon {
  margin-right: 12px;
}

.jzojp-documenttile-moreinfo .icon {
  margin-right: 10px;
}

.jzojp-documenttile-moreinfo .icon::before {
  font-size: 14px;
}

.jzojp-documenttile {
  display: flex;
  box-shadow: none;
  border: 1px solid #B3B3B3;
  padding: 20px var(--overview-item-padding-h) 26px var(--overview-item-padding-h);
  text-decoration: none;
}

.jzojp-documenttile__region {
  color: var(--tagcolor-darkpurple);
  font: 300 13px var(--rtd-text-fontfamily);
  text-transform: uppercase;
  margin-bottom: 4px;
}

.jzojp-documenttile__title {
  font: 500 22px/28px var(--rtd-heading-fontfamily);
  color: var(--tagcolor-darkpurple);
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

/*
.jzojp-documenttile-icon
{
  color: var(--tagcolor-pink);
  // font-size: 19px;
  font-size: 18px;
  margin-right: 10px;
}

.jzojp-documenttile h2 a
{
  text-decoration: none;

  outline-offset: 4px;
}

.jzojp-documenttile__title a
{
  color: inherit;
}
*/
.jzojp-documenttile__title__label {
  margin-right: 15px;
}

.jzojp-documenttile__title__filespecs {
  font-weight: normal;
  font-size: 14px;
}

.jzojp-documenttile__description {
  margin-top: 0px;
  font: 300 15px/22px var(--rtd-text-fontfamily);
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.jzojp-documenttile__description + * {
  margin-top: 4px;
}

.jzojp-documenttile__organizations {
  font: 300 15px/20px var(--rtd-text-fontfamily);
  color: var(--tagcolor-darkpurple);
}

.jzojp-documenttile__source {
  color: var(--tagcolor-darkpink);
  font: 300 15px/23px var(--rtd-text-fontfamily);
  font-style: italic;
}

.jzojp-documenttile__buttonbar {
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
}

/*
.jzojp-documenttile:has(a:hover)
{
  background-color: #FBFBFB;

    .jzojp-documenttile__region
  , .jzojp-documenttile__title
  , .jzojp-documenttile__organizations
  {
    color: var(--tagcolor-darkpink);
  }

  .jzojp-documenttile__title
  {
    text-decoration: underline;
  }
}
*/