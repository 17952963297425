/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.footer {
  color: #FFFFFF;
}

.footer__top {
  background-color: var(--tagcolor-darkpink);
}

.footer__top__aboutus {
  display: flex;
  align-items: center;
  font: 18px/23px var(--rtd-text-fontfamily);
}

.footer__top {
  position: relative;
}

.footer__top__sociallinks__links {
  display: flex;
}

.footer__top__sociallinks__links > * + * {
  margin-left: 15px;
}

.footer__socialitem {
  display: grid;
  color: inherit;
  text-decoration: none;
}

.footer__socialitem svg {
  grid-column: 1;
  grid-row: 1;
  width: 42px;
  fill: #9F0A4A;
  stroke: #9F0A4A;
}

.footer__socialitem .fab {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
  font-size: 20px;
}

.footer__socialitem:hover svg {
  fill: #FFFFFF;
  stroke: #FFFFFF;
}

.footer__socialitem:hover .fab {
  color: var(--tagcolor-darkpink);
}

.footer__heading {
  font: var(--rtd-heading-weight-bold) 18px var(--rtd-heading-fontfamily);
  margin-bottom: 10px;
}

.footer__linkcolumn__header {
  font-weight: bold;
  margin-bottom: 4px;
}

.footer__bottom {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  background-color: #93214A;
  color: #FFFFFF;
  font: 15px var(--rtd-text-fontfamily);
}

.footer__bottom__menu {
  display: flex;
  align-items: center;
  list-style: none;
}

.footer__bottom__menu li + li {
  margin-left: 18px;
}

.footer__bottom__menu a {
  color: #FFFFFF;
  text-decoration: none;
}

.footer__bottom__menu a:hover {
  text-decoration: underline;
}

.footer__subscribebutton {
  height: 37px;
  padding-left: 15px;
  padding-right: 15px;
}

/*
.footer__newslettersignup
{
  position: relative;
}

.footer__newslettersignup__query
{
  width: 100%;
  height: 37px;

  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 3px;

  background-color: transparent;
  color: #FFFFFF;

  padding-left: 15px;
  padding-right: 35px;

  font: 17px var(--rtd-text-fontfamily);

  &::placeholder { opacity: 0.7; color: #FFFFFF; } // Chrome 57, Edge 12, Firefox 51, Opera 44, Safari 10.1, iOS 10.3
}

.footer__newslettersignup__query:focus
{
  border: 1px solid rgba(255, 255, 255, 1);
}

.footer__newslettersignup__submit
{
  @include clearbuttonelementstyling;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  padding-left: 5px;
  padding-right: 13px;

  font-size: 20px;
  color: #FFFFFF;
}
*/
.footer__top__mainmenu {
  font: 18px var(--rtd-text-fontfamily);
}

.footer__top__quicklinks {
  font: 18px var(--rtd-text-fontfamily);
}

.footer__top__mainmenu__menu,
.footer__top__quicklinks__menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer__top__mainmenu__menu a,
.footer__top__quicklinks__menu a {
  /* Increase hitarea to be at least 24px high.
     (line-height 18px + 2 * 2px padding = 24px)
     Block to make padding work, but limit width to max-content
     so the whitespace isn't part of the anchor.
  */
  display: block;
  max-width: max-content;
  padding: 2px 0;
  color: #FFFFFF;
  text-decoration: none;
}

@media (max-width: 350px) {
  .footer__top__aboutus br {
    display: none;
  }
}
@media (max-width: 799px) {
  .footer__top {
    padding-top: 15px;
    padding-bottom: 14px;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
    display: flex;
    flex-direction: column;
  }
  .footer__top__aboutus {
    order: 0;
  }
  .footer__top__newsletter {
    order: 1;
  }
  .footer__top__sociallinks {
    order: 2;
  }
  .footer__top__aboutus {
    grid-column: 3/26;
    font: 15px var(--rtd-text-fontfamily);
  }
  /*
  accessibility auditor wil graag dat we deze op mobiel
  niet verbetergen (maar eigenlijk is het dubbelop content:
  herhaling van de hoofdmenu items en bottombarlink)
  */
  /*
      .footer__top__mainmenu
    , .footer__top__quicklinks
    {
      display: none;
    }
  */
  .footer__top__mainmenu,
  .footer__top__quicklinks,
  .footer__top__sociallinks,
  .footer__top__newsletter {
    margin-top: 25px;
  }
  .footer__top__sociallinks {
    display: flex;
    align-items: center;
  }
  .footer__top__sociallinks > .footer__heading {
    margin-bottom: 0;
  }
  .footer__top__sociallinks__links {
    margin-left: 15px;
  }
  .footer__top__aboutus__logo {
    width: 50px;
    height: 60px;
    margin-right: 15px;
  }
  .footer__bottom {
    height: 60px;
  }
  .footer_bottom__identity {
    margin-top: 3px;
    order: 2;
  }
  .footer__bottom__content {
    grid-column: 3/26;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer__bottom__menu {
    order: 1;
    margin: 0;
    padding: 0;
  }
}
@media (min-width: 800px) {
  .footer__top {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: auto 37px auto 40px auto;
    padding-top: 38px;
    padding-bottom: 18px;
  }
  .footer__top__aboutus {
    grid-column: 3/26;
    grid-row: 1;
  }
  .footer__top__mainmenu {
    grid-column: 3/14;
    grid-row: 3;
  }
  .footer__top__quicklinks {
    grid-column: 15/26;
    grid-row: 3;
  }
  .footer__top__sociallinks {
    grid-column: 3/14;
    grid-row: 5;
  }
  .footer__top__newsletter {
    grid-column: 15/26;
    grid-row: 5;
  }
  .footer__top__aboutus__logo {
    width: 82px;
    height: 96px;
    margin-right: 15px;
  }
  .footer__bottom {
    height: 60px;
  }
  .footer__bottom__content {
    grid-column: 3/26;
    display: flex;
    align-items: center;
  }
  .footer__bottom__menu {
    margin-left: auto;
  }
}
@media (min-width: 1000px) {
  .footer__top {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: auto 30px auto;
    padding-top: 38px;
    padding-bottom: 12px;
  }
  .footer__top__aboutus {
    align-self: start;
    grid-column: 3/span 11;
    grid-row: 1;
  }
  .footer__top__mainmenu,
  .footer__top__quicklinks {
    margin-top: 26px;
    font: 18px/24px var(--rtd-text-fontfamily);
  }
  .footer__top__mainmenu li + li,
  .footer__top__quicklinks li + li {
    margin-top: 4px;
  }
  .footer__subscribebutton {
    width: 100%;
    justify-content: space-between;
  }
  .footer__top__mainmenu {
    grid-column: 15/span 5;
    grid-row: 1;
  }
  .footer__top__quicklinks {
    grid-column: 21/span 5;
    grid-row: 1;
  }
  .footer__top__sociallinks {
    grid-column: 3/span 15;
    grid-row: 3;
  }
  .footer__top__newsletter {
    grid-column: 21/span 5;
    grid-row: 3;
  }
  .footer__top__aboutus__logo {
    width: 82px;
    height: 96px;
    margin-right: 20px;
  }
  .footer__bottom {
    height: 60px;
  }
  .footer__bottom__content {
    grid-column: 3/26;
    display: flex;
    align-items: center;
  }
  .footer__bottom__menu {
    margin-left: auto;
  }
}