#slidemenu-container::backdrop {
  animation: hidepagecontent 1s;
  animation-direction: reverse;
}

html.sidebar-enabled #slidemenu-container::backdrop {
  /*
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    transition: background-color 0.25s
              , backdrop-filter 0.25s
              , -webkit-backdrop-filter 0.25s;
  */
  z-index: 3;
  animation: hidepagecontent 1s;
  animation-fill-mode: forwards;
}

@keyframes hidepagecontent {
  0% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}