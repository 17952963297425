.widget-downloadsandlinks ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widgetdl__item__icon,
.widgetdl__item__meta {
  pointer-events: none;
}

.widgetdl__item__anchor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
}

.widgetdl__item__icon,
.widgetdl__item__title,
.widgetdl__item__filesize {
  color: var(--tagcolor-darkpurple);
}

/*
.regio__content
{
  .widgetdl__item
  {
    display: flex;
    align-items: baseline;

    position: relative;
  }
  .widgetdl__item + .widgetdl__item
  {
    margin-top: 5px;
  }
  .widgetdl__item__icon
  {
    flex: none;
    font-size: 18px;

    text-align: center;
    width: 21px;
    margin-right: 5px;
  }
  .widgetdl__item__icon.fa-external-link
  {
    font-size: 15px;
    position: relative;
    left: 1px;
  }
  .widgetdl__item__title
  {
    text-decoration: underline;
  }
  .widgetdl__item__filesize
  {
    display: none;
  }
}
*/
/* Large version when used directly in an RTD document */
.widgetdl__item {
  display: flex;
  align-items: baseline;
  border-top: 1px solid #C5C5C5;
  padding: 11px;
  position: relative;
}

.widgetdl__item.widgetdl__item:hover {
  background-color: #F5F5F5;
}

.widgetdl__item:last-child {
  border-bottom: 1px solid #C5C5C5;
}

.widgetdl__item__icon {
  flex: none;
  font-size: 18px;
  text-align: center;
  width: 20px;
  margin-right: 10px;
}

.widgetdl__item__icon::before {
  vertical-align: middle;
  color: var(--tagcolor-darkpink);
}

.widgetdl__item__icon.fa-arrow-right {
  font-size: 13px;
}

.widgetdl__item__icon.fa-external-link-alt {
  font-size: 16px;
}

.widgetdl__item__meta {
  font-size: 16px;
  line-height: 24px;
}

.widgetdl__item__title {
  font: var(--rtd-heading-weight-bold) 20px/28px var(--rtd-text-fontfamily);
}

.widgetdl__item__filesize {
  margin-left: 10px;
  white-space: nowrap;
}

.widgetdl__item__description {
  margin-top: 5px;
  color: #333333;
}

.widgetdl__item__description > p {
  font-size: 18px;
  line-height: 24px;
}