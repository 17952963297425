/*
NOTE:

Flex solution:
https://googlechrome.github.io/samples/css-flexbox-abspos/
- In modern browser we could also make the __preview flex
- Absolute positioned flex children will align/justify as if they are the only content within the flex container.
  (this way we could center the button)
*/
.wh-video {
  display: grid;
}

.wh-video__innerframe {
  grid-column: 1;
  grid-row: 1;
}

.wh-video__innerframe__preview {
  display: grid;
}

.widget-video__requireconsent__coloroverlay,
.widget-video__requireconsent__text {
  grid-column: 1;
  grid-row: 1;
}

.wh-video__innerframe__preview picture,
.wh-video__playbutton {
  grid-columns: 1;
  grid-row: 1;
}

.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  /*
  We didn't use grid alignment yet because it failed to center in IE
  align-self: center;
  justify-self: center;
  */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
  justify-self: center;
  width: 120px;
  height: 120px;
}
.wh-video__playbutton::before, .wh-video__playbutton::after {
  position: absolute;
  width: 74px;
  height: 74px;
}
.wh-video__playbutton::before {
  opacity: 0.8;
  transform: scale(1);
}
.wh-video__playbutton::before {
  opacity: 0.8;
  transform: scale(1);
}

.wh-video__playbutton:hover::before {
  opacity: 0.95;
  transform: scale(1.1);
}

.wh-video__playbutton.wh-video__playbutton {
  width: 120px;
  height: 120px;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 0.2s, transform 0.2s;
}

.wh-video__playbutton.wh-video__playbutton:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

button.widget-video__playbutton {
  cursor: pointer;
}

.widget-video__playbutton svg,
.wh-video__playbutton svg {
  width: 100%; /* approx 45px * 1,4 */
  height: 100%;
}

.widget-video__playbutton svg use,
.wh-video__playbutton svg use {
  width: 100%;
  height: 100%;
}

.widget-video__playbutton svg,
.wh-video__playbutton svg {
  --transition: fill 0.25s;
}

.widget-video__playbutton,
.wh-video__playbutton {
  filter: drop-shadow(2px 4px 6px black);
}

.widget-video__playbutton svg,
.wh-video__playbutton svg {
  --color-background: rgba(255,255,255,0.8);
  --color-icon: rgba(0,0,0,0.7);
  --stroke: 1px solid #000000;
}

.widget-video__playbutton:hover svg,
.wh-video__playbutton:hover svg {
  --color-background: #000000;
  --color-icon: rgba(255,255,255,0.8);
  --stroke: 1px solid #000000;
}

.widget-video--lightbg {
  outline: 1px solid #DDDDDD;
}

.widget-video--lightbg .widget-video__playbutton,
.widget-video--lightbg .wh-video__playbutton {
  filter: drop-shadow(2px 4px 6px black);
}

.widget-video--lightbg .widget-video__playbutton svg,
.widget-video--lightbg .wh-video__playbutton svg {
  --color-background: rgba(255,255,255,0.9);
  --color-icon: #000000;
  --stroke: 1px solid #000000;
}

.widget-video--lightbg .widget-video__playbutton:hover svg,
.widget-video--lightbg .wh-video__playbutton:hover svg {
  --color-background: #000000;
  --color-icon: rgba(255,255,255,0.8);
  --stroke: 1px solid #000000;
}

html:not([data-wh-consent=all]) .widget-video__playbutton, html:not([data-wh-consent=all]) .wh-video__playbutton {
  display: none;
}

[data-wh-consent=all] .widget-video__requireconsent__coloroverlay, [data-wh-consent=all] .widget-video__requireconsent__text {
  opacity: 0;
  pointer-events: none;
}
[data-wh-consent=all] .widget-video__playbutton {
  opacity: 1;
  pointer-events: auto;
}
[data-wh-consent=all] .widget-video__playbutton:hover {
  transform: scale(1.1);
}