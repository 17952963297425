.widget-event__block {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
  text-decoration: none;
}

.widget-event__block__top {
  width: 100%;
  min-width: 0;
  background-color: var(--tagcolor-darkpink);
  color: #FFFFFF;
}

.widget-event__block__whenwhere {
  color: #FFFFFF;
  width: 100%;
  min-width: 0;
  height: 100%;
}

.page--eventsoverview .widget-event__block__top {
  flex: 1 0 auto;
}

.page--eventsoverview .widget-event__block__whenwhere {
  height: 116px;
}

.eventsoverview .widget-event__block__location__text {
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.widget-event__block__dateheader {
  display: flex;
  justify-content: center;
  padding: 23px 0;
}

.widget-event__block__day svg,
.widget-event__block__month svg,
.widget-event__block__day .number,
.widget-event__block__month .number {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
  display: block;
}

.widget-event__block__day svg {
  width: 56px;
  height: 65px;
  fill: #FFFFFF;
}

.widget-event__block__month svg {
  width: 56px;
  height: 65px;
  fill: var(--tagcolor-darkerpink);
}

.widget-event__block__day {
  display: grid;
  color: var(--tagcolor-darkpink);
  font: bold 28px var(--rtd-text-fontfamily);
}

.widget-event__block__month {
  display: grid;
  font: bold 20px var(--rtd-text-fontfamily);
  margin-left: 10px;
}

.widget-event__block__title {
  padding: 0 17px;
  font: bold 20px var(--rtd-text-fontfamily);
}

.widget-event__block__description {
  padding: 0 17px;
  font: 16px var(--rtd-text-fontfamily);
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.widget-event__block__title + .widget-event__block__description {
  margin-top: 18px;
  margin-bottom: 30px;
}

.widget-event__block__whenwhere {
  margin-top: auto;
  background-color: var(--tagcolor-darkerpink);
  padding: 15px 17px;
  font: 17px/22px var(--rtd-text-fontfamily);
}

.widget-event__block__whenwhere > * {
  display: flex;
  align-items: baseline;
}

.widget-event__block__date::before,
.widget-event__block__time::before,
.widget-event__block__location::before {
  flex: none;
  width: 15px;
  font-size: 15px;
  margin-right: 10px;
  text-align: center;
}

.widget-event__block__date::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f073";
}

.widget-event__block__time::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f017";
}

.widget-event__block__location::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f3c5";
}