/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/* Viewmode selection bar */
.page--praktijkvoorbeelden .filteredoverview__viewmode {
  margin-left: 15px;
  border: 0;
  padding: 0;
  margin: 0;
}
.page--praktijkvoorbeelden .filteredoverview__viewmode__content {
  display: flex;
}
.page--praktijkvoorbeelden fieldset.filteredoverview__viewmode > legend {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
.page--praktijkvoorbeelden .filteredoverview__viewmode label svg,
.page--praktijkvoorbeelden .filteredoverview__viewmode label span {
  margin-right: 7px;
  stroke: #767676;
}
.page--praktijkvoorbeelden .filteredoverview__viewmode input {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  background-color: transparent;
  border: 0;
  margin: 0;
}
.page--praktijkvoorbeelden .filteredoverview__viewmode input + label {
  display: flex;
  align-items: center;
  color: #767676;
  font: 17px var(--rtd-text-fontfamily);
  height: var(--controls_height);
  padding-left: 13px;
  padding-right: 13px;
  border-bottom: 2px solid #C5C5C5;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.page--praktijkvoorbeelden .filteredoverview__viewmode input:checked + label {
  background-color: var(--color-theme-light);
  border-bottom-color: var(--color-theme);
  color: var(--color-theme);
}
.page--praktijkvoorbeelden .filteredoverview__viewmode input:checked + label svg {
  stroke: var(--color-theme);
}
.page--praktijkvoorbeelden .filteredoverview__viewmode input:focus-visible + label {
  outline: var(--tabstrip-item-focus-outline);
  outline-offset: var(--tabstrip-item-focus-outline-offset);
}