/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

@media (max-width: 500px) {
  :root {
    --menubar-height: 48px;
  }
}
.page-navpath {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page-navpath__content {
  grid-column: var(--pagegrid-col-default);
  /*
    @media (max-width: $minwidth-centercontent - 1)
    {
      @include layout--verywide;
    }

    @media (min-width: $minwidth-centercontent)
    {
      @include layout--narrow;
    }
  */
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  font: 14px/19px "Alegreya Sans";
  color: #767676;
  margin-top: 8px;
  margin-left: -5px;
}

.navpath__item {
  color: inherit;
  text-decoration: none;
  padding: 3px 5px 3px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-underline-offset: 2px;
}

a.navpath__item:hover {
  text-decoration: underline;
  color: #333333;
}

.page__content__backlink {
  display: flex;
  align-items: baseline;
  color: var(--rtd-link-color);
  font: 18px var(--rtd-text-fontfamily);
  text-decoration: none;
  text-underline-offset: 2px;
}

.page__content__backlink + * {
  margin-top: 30px;
}

.page__content__backlink .label {
  text-decoration: underline;
}

.page__content__backlink .icon {
  margin-right: 12px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  top: 2px;
}

.page__content__backlink .icon::before {
  font: var(--fontawesome-light);
  font-size: 18px;
  content: var(--fa-chevron-circle-left);
}

.page__shareoptions {
  grid-column: var(--pagegrid-col-default);
  /*
    @media (max-width: $minwidth-centercontent - 1)
    {
      @include layout--verywide;
    }

    @media (min-width: $minwidth-centercontent)
    {
      @include layout--narrow;
    }
  */
  margin-top: 65px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.page__shareoptions__title {
  font: 13px var(--rtd-text-fontfamily);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.page__shareoption + .page__shareoption {
  margin-left: 5px;
}

.page__shareoption {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #c10061;
  width: 42px;
  height: 49px;
  background: url("./web/img/sharebutton-border.png") no-repeat center center;
  background-size: 38px 45px;
  cursor: pointer;
}

.page__shareoption > span {
  font-size: 18px;
}

html {
  /* part of 4TU grid */
  /*
    --pagegrid-gutteroutside-mobile: $pagegrid-gutteroutside-mobile;

    --pagegrid-gutter-left:      3 / 6;
    --pagegrid-gutter-left-full: 3 / 8; / * no spacing to verywide content * /
    --pagegrid-gutter-right:     23 / 26; / * next to verynarrow * /

    --pagegrid-col-verynarrow:     9 / span 11;
  */
  --pagegrid-col-narrow: 7 / span 15;
  --pagegrid-col-wide: 5 / span 19;
  --pagegrid-col-wide-include-gutter: 4 / span 21;
  --pagegrid-col-verywide: 3 / 26;
  --pagegrid-col-verywide-outsidegutter: 2 / 27;
  --pagegrid-col-default-left: 7 / span 7; /* 2 column / left */
  --pagegrid-col-default-right: 15 / span 7;
  --pagegrid-col-verywide-left: 3 / 14;
  --pagegrid-col-verywide-right: 15 / 26;
  /*
    --pagegrid-col-viewportstart-to-middle:  1 / 14;
    --pagegrid-col-middle-to-viewportend:   15 / 28;

    --pagegrid-col-verywide-focusleft:           3 / 14;
    --pagegrid-col-verywide-right-extraspacing: 15 / 26;

    // --pagegrid-col-verywide-focusright:       15 / 27;
    --pagegrid-col-verywide-focusright:       15 / 26;
    --pagegrid-col-verywide-left-extraspacing: 3 / 14; / * keep an extra column spacing * /


    --pagegrid-col-fullwidth:                1 / 28;


    --zindex-overlap-deeplinks:   10;
    --zindex-overlap-contentarea: 11;
  */
}

@media (max-width: 1023px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-narrow);
  }
}