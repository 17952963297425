/*
NOTE:
- iPad Mini -  768
- iPad Air  -  820
- iPad Pro  - 1024



*/
/* JZOJP specific below here -------------------------------------- */
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
@media (min-width: 800px) {
  .page-header--default {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-row: minmax(0, 1fr) min-content;
    position: relative;
    /*
    .page-header__image::after
    {
      content: "";
      display: block;

      position: absolute;
      right: 0;
      bottom: 0;
      content: url("../web/img/pageheader-overlay-corner.svg");
    }
    */
  }
  .page-header--default .page-header__image {
    grid-column: 3/26;
    grid-row: 1/span 2;
    /* 1110 x 408 */
    background-size: cover;
    position: relative;
  }
  .page-header--default .page-header__image::before {
    content: "";
    display: block;
    padding-top: 36.7567567568%;
  }
  .page-header--default .page-header__coloroverlay {
    grid-column: 3/26;
    grid-row: 2;
    mix-blend-mode: darken;
    position: relative;
    overflow: hidden;
  }
  .page-header--default .page-header__coloroverlay img {
    position: absolute;
    left: -17%;
  }
  .page-header--default .page-header__corner-cutout {
    grid-column: 3/26;
    grid-row: 2;
    align-self: end;
    justify-self: end;
    z-index: 1;
    width: 141px;
  }
  .page-header--default .page-header__textcontent {
    position: relative;
    z-index: 1;
    grid-column: var(--pagegrid-col-default);
    /*
      @media (max-width: $minwidth-centercontent - 1)
      {
        @include layout--verywide;
      }

      @media (min-width: $minwidth-centercontent)
      {
        @include layout--narrow;
      }
    */
    grid-row: 2;
    padding: 55px 0 25px 0;
    font: bold 44px/48px "Alegreya Sans";
    color: #FFFFFF;
  }
}
@media (min-width: 800px) and (max-width: 1023px) {
  .page-header--default .page-header__image, .page-header--default .page-header__coloroverlay {
    grid-column: 1/span 27;
  }
  .page-header--default .page-header__coloroverlay img {
    left: -25%;
  }
  .page-header--default .page-header__corner-cutout {
    grid-column: 1/span 27;
    grid-row: 2;
    align-self: end;
    justify-self: end;
    z-index: 1;
  }
  .page-header--default .page-header__textcontent {
    padding-top: 70px;
  }
}
@media (max-width: 799px) {
  .page-header--default {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    /*
      // force aspect ratio
      .page-header__image::before
      {
        content: "";
        display: block;
        // padding-top: 36.757;
        padding-top: calc(408 / 1110 * 100%);
      }
    */
  }
  .page-header--default .page-header__coloroverlay, .page-header--default .page-header__corner-cutout {
    display: none;
  }
  .page-header--default .page-header__image {
    /* 1110 x 408 */
    grid-column: 1/span 27;
    background-size: cover;
    position: relative;
    height: 48.6vw;
  }
  .page-header--default .page-header__textcontent {
    margin-top: 25px;
    grid-column: 3/26;
  }
  .page-header--default .page-header__title {
    font: var(--rtd-heading-weight-bold) 24px/28px var(--rtd-heading-fontfamily);
    color: #55095f;
  }
}