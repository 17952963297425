@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans:300,300i,400,500&display=swap");
@import url("../../../shared/web/fontawesome5/css/all.css");


html
{
  /* copied from https://www.dejuistezorgopdejuisteplek.nl/ */
  font-family: "Alegreya Sans", sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.625;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;

  overflow-y: scroll;

  color: #333333;



  /* Desktop mainmenu navigation bar */
  --menubar-height:        73px;
  --topbar-height:         62px;

  --pageheader-background-neutral: #F3F2F4;


  /*////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Whitespace
  */

  /* White at the top and bottom of panels with an background color*/
  --panels-padding-top:       30px;
  --panels-padding-bottom:    30px;



  /************************************************************
   *
   *  Color scheme (FIXME: needs cleanup)
   */


/*
2024

Form error - #E30000 on #FFF2F2 (4.5:1 contrast)
           - #CD202C on #FFF2F2 (4.5:1 contrast) - iets subtieler rood
*/

  --color-grey-bg:       #F3F2F4;
  --rtd-text-color-on-grey: #6E6E6E;

  /*
.widget-newsletter__moreinfo
   color: #6A6A6A; // 4.5:1 contrast on #F7F7F7
  */

  --textcontrols-error-color:        #CD202C;
  --textcontrols-error-background:   #FFF2F2;
  --textcontrols-error-border-color: #CD202C;

  /* WARNING: ARC Toolkit won't check contrast for items which;
              use a partially transparent background.
              So don't use rgba(r, g, b, a);
  */
  --tagcolor-darkpink:   #C80D5E;
  --tagcolor-darkerpink: #9F0A4A; /* used in 2020 design */
  --tagcolor-pink:       #DC55AC;
  --tagcolor-lightblue:  #369DD1;
  --tagcolor-yellow:     #EEBB00;
  --tagcolor-orange:     #D65600;
  --tagcolor-green:      #4C991C;

  --tagcolor-darkpurple: #56145F;
  /*--tagcolor-darkpurple-lighter: rgba(86, 20, 95, 0.15); / * for hover effects */
  --tagcolor-darkpurple-lighter: #E6DCE7;


  --color-theme:       var(--tagcolor-darkpurple);
  --color-theme-light: var(--tagcolor-darkpurple-lighter);


  /* Colors for buttons ------------------------------------------*/
  --themecolor-blue:         #3a7aa5;
  --themecolor-blue-hover:   #2a5a7b;

/* buttons background color #ca005d*/
  --themecolor-pink:         #c10061;
  --themecolor-pink-hover:   #8f0047;

  --themecolor-pink-buttons: #CA005D; /* FIXME: find out reason (history) of why this is a different pink */
  --themecolor-pink-buttons-hover: #b10051;

  --themecolor-green:        #428619; /* was #458818 before? */
  --themecolor-green-hover:  #336511;

  --themecolor-purple:       #55095f;
  --themecolor-purple-hover: #3e0746;
  /* -------------------------------------------------------------*/



  --color-error:           #EE0000; /* lightest accessible red, 4.53:1 contrast on white */
  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767677; /* WCAG 4.53:1 contrastratio */
  --color-input-placeholder: var(--color-grey-text-small);
  --color-input-disabled-placeholder: var(--color-grey-text-small);


  --formcontrols-themecolor: var(--tagcolor-darkpurple);



  /************************************************************
   *
   *  RTD textual
   */

  --rtd-text-fontfamily:    "Alegreya Sans", sans-serif;

  --rtd-heading-color:      var(--tagcolor-darkpurple);
  --rtd-heading-fontfamily: "Alegreya Sans", sans-serif;
  --rtd-heading-offset:     0;
  --rtd-heading-weight:        500;
  --rtd-heading-weight-medium: 500;
  --rtd-heading-weight-bold:   500; /* NOTE: we CANNOT use 600, this weight is missing in Google Fonts for the Alegreya Sans font and 700 is too bold */

  --rtd-intro-font:      23px/32px "Alegreya Sans", Sans-Serif;
  --rtd-text-font:       20px/28px "Alegreya Sans", Sans-Serif;
  --rtd-text-small-font: 18px/26px "Alegreya Sans", Sans-Serif;
  --rtd-table-font:      var(--rtd-text-small-font);

  --rtd-h1-font: var(--rtd-heading-weight) 44px/48px var(--rtd-heading-fontfamily);
  --rtd-h2-font: var(--rtd-heading-weight) 28px/32px var(--rtd-heading-fontfamily);
  --rtd-h3-font: var(--rtd-heading-weight-medium) 23px/28px var(--rtd-heading-fontfamily); /* medium weight */
  --rtd-h4-font: var(--rtd-heading-weight-medium) 20px/25px var(--rtd-heading-fontfamily); /* not in design */

  --rtd-link-color: var(--tagcolor-darkpink);
  --rtd-link-hover-color: var(--tagcolor-darkpink);


  /************************************************************
   *
   *  RTD whitespace
   */

  --rtd-spacing-default: 30px;


  /************************************************************
   *
   *  Form controls
   */

  /* Shared settings for controls
     - textual inputs, upload, ...
  */
  --controls_height:                  44px;
  --controls_radius:                   3px;
  --controls_outline_color:          var(--color-grey-border);
  --controls_outline-color_disabled: #BFBFBF;
  --controls_outline-color_error:    #CD202C;
  --controls_outline-color_focus:    #222222;
  --controls_backgroundcolor_error:  #FFEAEA;

  --controls_textcolor_error:        #EE0000; /* lightest red which passes accessibility tests (4.53:1 contrast on white) */

  --textcontrols_font:                 16px var(--rtd-text-fontfamily); /*var(--rtd-font-text);*/
  --textcontrols_placeholdercolor:     var(--color-grey-text-small);
  --textcontrols_textcolor:            #1D1D26;

  --textcontrols_disabled_bgcolor:     #F4F4F4;
  --textcontrols_disabled_bordercolor: #C5C5C5;
  --textcontrols_disabled_placeholder: inherit;
  --textcontrols_disabled_textcolor:   #282828;

  --textcontrols_focus_bordercolor:   #0067B4;

  --textcontrols_padleft:             15px;
  --textcontrols_padright:            15px;


  --controls-icon-color: var(--color-blue-dark);



  /************************************************************
   *
   *  Form controls (checkbox and radio buttons)
   */

  --font-awesome:        "Font Awesome 5 Pro";
  --font-awesome-regular: ;
  --font-awesome-weight:  600;
  --font-awesome-size:    17px;
  --font-awesome-offsetx: 0px;
  --font-awesome-offsety: 0px;

  --fontawesome-light:   300 14px 'Font Awesome 5 Pro';
  --fontawesome-regular: 400 14px 'Font Awesome 5 Pro';
  --fontawesome-solid:   900 14px 'Font Awesome 5 Pro';


  /* Checkboxes and radiobuttons */
  --formcontrol-checkradio-size:                26px;
  --formcontrol-checkradio-bordercolor:         var(--tagcolor-darkpurple);
  --formcontrol-checkradio-borderwidth:         1px;
  --formcontrol-checkradio-borderradius:        3px;
  --formcontrol-radio-innersize:                18px;

  --formcontrol-check-shift:                    3px;
  --formcontrol-radio-shift:                    0px;

  --formcontrol-checkradio-checked-color:       var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bgcolor:     #FFFFFF;
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-disabled-bgcolor:    #F2F2F2;
  --formcontrol-radio-checked-color:            var(--formcontrols-themecolor);

  --formcontrol-checkradio-label-color: #979797;



  /************************************************************
   *
   *  Page components / Buttons
   */

  --formcontrols-height:      46px;

  --button-themecolor:        var(--themecolor-pink-buttons);

  /* NOTE: if the font-size is below 16px iOS will zoom into the control when it recieves focus*/
  --button-fontfamily:        var(--rtd-text-fontfamily);
  --button-font:              20px var(--button-fontfamily);
  --button-focus-outline:     2px solid var(--themecolor-blue);
  --button-focus-outline-offset: 2px;
  --button-height:            46px;

  --button-borderradius:      2px;

  --button-background:        var(--button-themecolor);
  --button-hover-background:  var(--button-themecolor-hover);

  --button-disabled-backgroundcolor: #AAAAAA;
  --button-disabled-textcolor:       #666666;

  --button-outline-background: #FFFFFF;
  --button-outline-border:     1px solid var(--button-themecolor);
  --button-outline-textcolor:  var(--button-themecolor);

  --button-outline-hover-background: var(--button-themecolor);
  --button-outline-hover-border:     var(--button-outline-border);
  --button-outline-hover-textcolor:  #FFFFFF;

/*
  --button-disabled-backgroundcolor: #AAAAAA;
  --button-text-alignment-tweak:  0;
*/

  /* JZOJP */
  --button-padding:           var(--rtd-heading-offset) 30px 0 30px;


  --tabstrip-item-focus-outline:        2px solid var(--themecolor-blue);
  --tabstrip-item-focus-outline-offset: 2px;



  /************************************************************
   *
   *  Page components / Header filterbar (query / toggle filters)
   */

  --header-filterpanel-radius: 27px;
  --header-filterpanel-border-width: 2px;
  --header-filterpanel-rowheight: 49px; /* was 55px */
}


body
{
  /************************************************************
   *
   *  Page components / Filtered overview teaser bar
   */

  --filteredoverview-feedbackbar-color:          #000000;
  --filteredoverview-feedbackbar-font:           17px/30px var(--rtd-text-fontfamily);

  --filteredoverview-teaserbar-color:            #FFFFFF;
  --filteredoverview-teaserbar-height:           65px;
  --filteredoverview-teaserbar-background:       var(--tagcolor-darkpink);
  --filteredoverview-teaserbar-hover-background: var(--tagcolor-darkerpink);
  --filteredoverview-teaserbar-icon-font:        var(--fontawesome-light);
  --filteredoverview-teaserbar-icon:             var(--fa-arrow-circle-right);
  --filteredoverview-teaserbar-icon-size:        28px;
}


@media (max-width: 800px)
{
  html
  {
    --rtd-intro-font: 20px/24px "Alegreya Sans", Sans-Serif;
    --rtd-text-font:  18px/24px "Alegreya Sans", Sans-Serif;
    --rtd-table-font: 18px/24px "Alegreya Sans", Sans-Serif;
    --rtd-h1-font:    var(--rtd-heading-weight) 24px/28px "Alegreya Sans", Sans-Serif;
    --rtd-h2-font:    var(--rtd-heading-weight) 21px/24px "Alegreya Sans", Sans-Serif;
    --rtd-h3-font:    500 20px/24px "Alegreya Sans", Sans-Serif;
  }
}


@media (max-width: 640px)
{
  html
  {
    --button-padding:           var(--rtd-heading-offset) 20px 0 20px;
  }
}



body
{
  background-color: #FFFFFF; /* required for WCAG compliance */
  color: #333333; /* required for WCAG compliance */
}



.widget-linkslist ul
{
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget-linkslist li
{
  display: flex;
  align-items: baseline;
}
.widget-linkslist ul .far
{
  margin-right: 13px;
  font-size: 14px;
  color: var(--tagcolor-darkpink);
}
.widget-linkslist a
{
  color: var(--rtd-link-color);
}



  h1
, h2
, h3
, .style_as_h1
, .wh-form__grouptitle
, .widget__title
{
  font-family: var(--rtd-heading-fontfamily);
  font-weight: var(--rtd-heading-weight);
}

.widget__subtitle
{
  font: 16px var(--rtd-text-fontfamily);
  margin-bottom: 6px;
}


h1
, .style_as_h1
{
  font: var(--rtd-h1-font);
  margin: 0;
}
.page__contentarea--formwebtool h1
{
  color: var(--rtd-heading-color);
}


  .page__body h1.page-header__title
, .page__body .pageheader__date
{
  color: var(--rtd-heading-color);
}

/* KEEP IN SYNC WITH h2_styling mixin (/shared/config.scss)
   FIXME: ook bij dit webdesign?
*/
h2
, .widget__title
{
  font: var(--rtd-h2-font);
  color: var(--rtd-heading-color);
  margin: 0;
}

  h3
, .style_as_h3
, .wh-form__grouptitle
, .widget-accordion__item__header
{
  font: var(--rtd-h3-font);
  color: var(--rtd-heading-color);
  margin: 0;
}

  p.normal
, p.normal_small
, p.subtitle
, p.credits
, ol.ordered
, ul.unordered
{
  color: #333333;
  margin: 0;
}

  p.normal
, ol.ordered
, ul.unordered
, .widget-linkslist
, .searchresult__description
{
  font: var(--rtd-text-font);
}

  p.intro
, .searchresult__title
{
  font: var(--rtd-intro-font);
  color: #333333;
  margin: 0;
}

p.subtitle
{
  font: 16px/24px "Alegreya Sans", Sans-Serif;
}

p.normal_small
{
  font: var(--rtd-text-small-font);
}

p.credits
{
  font: 15px/18px "Alegreya Sans", Sans-Serif;
}



a
{
  background-color: transparent; /* needed to comply to WCAG ? */
  /*color: #56145F;*/
}

b
{
  font-weight: 600; /* use semi-bold instead of bold */
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}



/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}



/*
NOTE: A lot of this ul/ol/li restyling work could be done by simply using li::marker in Safari 11.1+, iOS 11.3+ and Firefox 68+.
      However Chrome, IE and Edge don't support this yet :(;

      https://caniuse.com/#feat=css-marker-pseudo
      https://bugs.chromium.org/p/chromium/issues/detail?id=457718
*/

ul.unordered
{
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0; /* we want to be able to use a bold text as header directly above the list: <p><b>heading</b></p> */
  padding-left: 0;
}

ul.unordered ul
{
  list-style: none;
  margin-left: 25px;
  padding-left: 0;
}

ul.unordered li
{
  /*
  display: flex;
  align-items: baseline;
  */
  padding-left: 20px;
}

ul.unordered li::before
{
  position: absolute;
  margin-left: -20px;
  margin-top: 12px;

  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--themecolor-pink-buttons);

  /*content: "•"; */
  color: #56145F;
  font-size: 18px;
  margin-right: 10px;
}


ol.ordered
{
  list-style: none;
  counter-reset: li;

  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0; /* we want to be able to use a bold text as header directly above the list: <p><b>heading</b></p> */
  padding-left: 0;
}
ol.ordered > li
{
  /*
  display: flex;
  align-items: baseline;
  */
  counter-increment: li;

  padding-left: 20px;
}

ol.ordered > li::before
{
  position: absolute;
  margin-left: -20px;
  margin-top: 0px;

  content: counter(li) ".";
  display: block;

  /*font: bold 14px Raleway, sans-serif;*/
  margin-right: 10px;

/*
  font-family: "Alegreya Sans";
  line-height: 28px;
*/
  color: #56145F;

  width: 30px;
  text-align: left;
  /*direction: rtl;*/
}



  .wh-video a
, p.normal a
, p.normal_small a
, p.credits  a
, ul.unordered a
, ol.ordered a
, h2.heading2 a
, h3.heading3 a
, .page__contentarea--formwebtool a
{
  /*color: inherit;*/
  color: var(--rtd-link-color);
  text-decoration: none;
}


  .wh-video a:hover
, p.normal a:hover
, p.normal_small a:hover
, p.credits a:hover
, ul.unordered a:hover
, ol.ordered a:hover
, h2.heading2 a:hover
, h3.heading3 a:hover
{
  text-decoration: underline;
}




/* RTD vertical whitespace ***********************************************************/

h1
{
/*  margin-bottom: 15px;*/
}

.widget
{
  margin-top: 50px;
}

.widget--withtitle
{
  margin-top: 50px;
}

.widget--notitle
{
  margin-top: 30px;
}

h2.heading2
{
  margin-top: 50px;
}

h3.heading3
{
  margin-top: 35px;
}

  .wh-video
, p.normal
{
  margin-top: var(--rtd-spacing-default);
}

  .widget-button
, h2.heading2 + .widget
, h3.heading3 + .widget
, p.credits
, p.subtitle
{
  margin-top: 15px;
}

  h2.heading2 + p.normal
, h3.heading3 + p.normal
{
  margin-top: 10px;
}

  ul.unordered
, ol.ordered
, p + .widget-linkslist
{
  margin-top: 0;
}



/* Mobile whitespace */
@media (max-width: 640px)
{
  h1
  {
    margin-bottom: 15px;
  }

  .widget
  {
    margin-top: 30px; /* instead of 50px */
  }

  h2.heading2
  {
    margin-top: 35px; /* instead of 50px */
  }

  h3.heading3
  {
    margin-top: 25px; /* instead of 35px */
  }

    .wh-video
  , p.normal
  {
    /* when changing this value also change $paragraph_space_between in config.scss */
    margin-top: 20px; /* instead of 30px */
  }

    .widget-button
  , h2.heading2 + .widget
  , h3.heading3 + .widget
  , p.credits
  , p.subtitle
  {
    margin-top: 15px;
  }

    h2.heading2 + p.normal
  , h3.heading3 + p.normal
  {
    margin-top: 10px;
  }

    ul.unordered
  , ol.ordered
  , p + .widget-linkslist
  {
    margin-top: 0;
  }
}




/* any RTD content (descriptions, column content) */
  .wh-rtd > *:first-child
, .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}
  .wh-rtd > *:last-child
, .rtdcontent > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}

.page__contentarea--rtddoc > *:first-child
{
  margin-top: 30px;
}


body
{
  /* Social networks */
  --fa-twitch: "\f1e8";
  --fa-twitter: "\f099";
  --fa-twitter-square: "\f081";
  --fa-facebook: "\f09a";
  --fa-facebook-f: "\f39e";
  --fa-facebook-messenger: "\f39f";
  --fa-facebook-square: "\f082";
  --fa-linkedin: "\f08c";
  --fa-linkedin-in: "\f0e1";
  --fa-instagram: "\f16d";
  --fa-whatsapp: "\f232";
  --fa-youtube:   "\f167";



  --fa-play: "\f04b";
  /*--fa-play-circle: "f144";*/


  --fa-search: "\f002";

  --fa-minus: "\f068";
  --fa-plus:  "\f067";

  --fa-external-link: "\f08e";
  --fa-external-link-alt: "\f35d";


  --fa-arrow-left:    "\f060";
  --fa-arrow-right:   "\f061";
  --fa-arrow-down:    "\f063";

  --fa-arrow-from-left: "\f343";
  --fa-arrow-to-right: "\f340";

  /* Angle */
  --fa-angle-left:    "\f104";
  --fa-angle-right:   "\f105";
  --fa-angle-up:      "\f106";
  --fa-angle-down:    "\f107";

  --fa-calendar:      "\f133";
  --fa-calendar-alt:  "\f073";
  --fa-calendar-day:  "\f783";

  --fa-check:         "\f00c";

  --fa-clock:         "\f017";

  /* Chevron */
  --fa-chevron-left:  "\f053";
  --fa-chevron-right: "\f054";
  --fa-chevron-up:    "\f077";
  --fa-chevron-down:  "\f078";

  --fa-chevron-double-left: "\f323";
  --fa-chevron-double-right: "\f324";

  --fa-cross:         "\f654";

  /* Circle */
  --fa-arrow-circle-left:  "\f0a8";
  --fa-arrow-circle-right: "\f0a9";
  --fa-arrow-circle-down:  "\f0ab";
  --fa-info-circle: "\f05a";
  --fa-plus-circle: "\f055";
  --fa-minus-circle: "\f056";
  --fa-times-circle: "\f057";

  --fa-arrow-alt-from-left: "\f347";

  --fa-times:         "\f00d";

  --fa-user:  "\f007";

  --fa-power-off: "\f011";

  --fa-trash-alt: "\f2ed";

  --fa-pencil: "\f040";

  --fa-browser: "\f37e";

  /* --fa-project-diagram: "\f542"; */
  --fa-flask: "\f0c3";

  --fa-envelope: "\f0e0";
  --fa-print: "\f02f";

  --fa-long-arrow-right: "\f178";

  --fa-sliders-h: "\f1de";

}
