/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.pvoverview-filters {
  /*
  NOTE: Er zijn teveel filters om sticky te gebruiken.
  Men moet naar de onderste filters kunnen scrollen.
    position: sticky;
    top: 130px;
  */
  align-self: start;
  --font-awesome-size: 12px;
  --formcontrol-checkradio-size: 18px;
  --formcontrol-checkradio-bordercolor: #898989;
  --formcontrol-checkradio-checked-color: #FFFFFF;
  --formcontrol-checkradio-checked-bgcolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-disabled-bgcolor: #F2F2F2;
  --formcontrol-radio-checked-color: var(--formcontrols-themecolor);
}
.pvoverview-filters button[type=submit] {
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  margin: 0;
}

.pvoverview-filters__heading {
  display: flex;
  align-items: center;
  margin-bottom: var(--pvheading-marginbottom);
  color: var(--color-theme);
}

.pvoverview-filters__headingtext {
  font: var(--pvheading-font);
}

.pvoverview-filters__close {
  border: 0;
  background: none;
  padding: 10px; /* enlarge hit area */
  margin-left: auto;
  margin-right: -10px;
  cursor: pointer;
}

.pvoverview-filters__close:hover {
  background-color: var(--color-theme-light);
}

.pvoverview-filters__close::before {
  content: var(--fa-times);
  font: var(--fontawesome-light);
  font-size: 25px;
  color: var(--color-theme);
}

.searchsidebar__query-container {
  display: flex;
  flex-wrap: wrap; /* to get the autosuggest below the input at 100% width */
}

.searchsidebar__query {
  position: relative; /* over the autosuggest dropdown */
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #B3B3B3;
  border-radius: 60px;
  background-color: #FFFFFF;
}

.searchsidebar__query:focus-within {
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}

.searchsidebar__query::before {
  margin-left: 12px;
  margin-right: 12px;
  content: var(--fa-search);
  font: var(--fontawesome-solid);
  font-size: 16px;
  color: var(--color-theme);
}

.searchsidebar__query-input.searchsidebar__query-input {
  height: auto;
  align-self: stretch;
  flex: 1 0 0;
  border: 0;
  background-color: transparent;
  padding-left: 0;
  padding-right: 20px;
}

.searchsidebar__query-input::placeholder {
  font: 17px var(--rtd-text-fontfamily);
  color: #898989;
  opacity: 1;
}

/* override focus specified in textual-input.scss */
.searchsidebar__query-input.searchsidebar__query-input:focus {
  box-shadow: none;
  outline: none; /* we want the focus to show on our parent */
}

.pvoverview-filters__group + .pvoverview-filters__group {
  margin-top: 25px;
}

.pvoverview-filters__grouptitle {
  font: var(--rtd-heading-weight-bold) 18px/28px var(--rtd-heading-fontfamily);
  color: var(--color-theme);
  margin-top: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #B3B3B3;
  margin-bottom: 8px;
}

.pvoverview-filters__option {
  display: flex;
  align-items: baseline;
  padding: 5px 0;
  font: 15px/21px var(--rtd-text-fontfamily);
}

.pvoverview-filters__moreoptions[aria-hidden] {
  display: none;
}

.pvoverview-filters__option input[type=checkbox] {
  margin-right: 10px;
}

.searchsidebar__resetbutton {
  margin-top: 30px;
  background: #FFFFFF;
  text-decoration: underline;
  border: 1px solid var(--tagcolor-pink);
  color: var(--tagcolor-darkpink);
  font: 15px var(--rtd-text-fontfamily);
  height: 40px;
  padding: 0 15px;
  padding-top: 2px; /* fix vertical alignment */
  cursor: pointer;
}

.searchsidebar__resetbutton:hover {
  background-color: var(--tagcolor-pink);
  color: #FFFFFF;
}

@media (max-width: 799px) {
  .pvoverview-filters {
    --pvheading-font: var(--rtd-heading-weight-bold) 24px/var(--pvheading-height) var(--rtd-heading-fontfamily);
  }
  .pvoverview-filters__heading {
    margin-top: 0;
    margin-bottom: var(--pvheading-marginbottom);
  }
  .pvoverview-filters__headingtext {
    display: none;
  }
  .pvoverview-filters__mobileheadingtext {
    display: block;
  }
}
@media (min-width: 800px) {
  .pvoverview-filters.pvoverview-filters {
    --pvheading-font: var(--rtd-heading-weight-bold) 24px/var(--pvheading-height) var(--rtd-heading-fontfamily);
  }
  .pvoverview-filters__close {
    display: none;
  }
  .pvoverview-filters__heading {
    margin-bottom: var(--pvheading-marginbottom);
  }
  .pvoverview-filters__headingtext {
    display: block;
  }
  .pvoverview-filters__mobileheadingtext {
    display: none;
  }
  .searchsidebar__resetbutton {
    margin-top: 30px;
    position: sticky;
    bottom: 20px;
    box-shadow: 0 0 10px 12px rgba(255, 255, 255, 0.8);
  }
}