.widget--rovid {
  background: var(--widget-rovid-background);
  border-radius: var(--widget-rovid-radius);
}

.widget--rovid__posterimage {
  width: 100%;
}

.widget-rovid__videowrapper {
  margin: var(--widget-rovid-video-border) var(--widget-rovid-video-border) 0 var(--widget-rovid-video-border);
  border-radius: var(--widget-rovid-radius);
}

/*
.widget--rovid > .video-js
{
  width: auto !important;
  width: -webkit-fill-available !important;
  height: auto !important;
  aspect-ratio: 16 / 9;
}
*/
.widget--rovid .video-js {
  background: none;
}

.widget--rovid .vjs-poster,
.widget--rovid .vjs-tech {
  border-radius: var(--widget-rovid-radius);
  aspect-ratio: 16/9;
  overflow: hidden;
}

/*
.widget--rovid > .video-js
{
  display: grid;
  grid-template-rows: 100%;
}

  .widget--rovid > .video-js > .vjs-poster
, .widget--rovid > .video-js > .vjs-tech
{
  grid-column: 1;
  grid-row: 1;
  position: relative !important;
}
*/
.widget--rovid__top {
  position: relative;
}

.widget--rovid__posterimage {
  display: block;
}

.widget--rovid__showvideo {
  position: absolute;
  left: 10px;
  bottom: 10px;
  /*
  background-image: url("../../web/img/video-play.green.svg");
  background-size: cover;
  background-repeat: no-repeat;
  */
}

button.widget--rovid__showvideo {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.widget--rovid .spc-accordion {
  --accordion-item-line-top: 0;
  --accordion-item-line-bottom: 0;
}

.vjs-poster::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  cursor: pointer;
  /* Center this button within the preview area */
  position: absolute; /* align-self only works in Safari/iOS when using absolute */
  align-self: center;
  justify-self: center;
}

/*
  .widget--rovid__showvideo svg // mini rovid
, .vjs-big-play-button svg
{
  --color-background: var(--color-green);
  --color-icon: #FFFFFF;
  --transition: fill 0.25s;
}

.widget--rovid__showvideo svg // mini rovid
{
  width: 38px; / * approx 30px * 1,4 (the shadow) * /
  height: 38px;
}

.vjs-big-play-button svg
{
  width: 57px; / * approx 45px * 1,4 * /
  height: 57px;
}

  .widget--rovid__showvideo svg use
, .vjs-big-play-button svg use
{
  // Make the loaded image use the size of the SVG container
  width: 100%;
  height: 100%;
}

  .widget--rovid__showvideo:hover svg
, .vjs-big-play-button:hover svg
{
  --color-background: #FFFFFF;
  --color-icon: var(--color-green);
}
*/
.widget--rovid__duration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #FFFFFF;
  font: 12px Arial;
  padding: 2px 4px;
}

/* ROVID specific content */
.w-details__details--srt {
  color: var(--rtd-text-color);
  font: var(--rtd-text-font);
}

a.widget--rovid__download {
  padding: 4px 0 4px 20px;
  display: block;
  text-decoration: none;
  color: #595959;
}

a.widget--rovid__download:hover {
  background: #E0E0E0;
  border-left: 3px solid #B0B0B0;
  padding-left: 17px;
}

.widget--rovid__download__title {
  flex: 1 1 0;
  color: var(--rtd-link-color);
  font: var(--rtd-link-font);
  line-height: 25px;
  font-weight: bold;
  text-decoration: underline;
}

a.widget--rovid__download {
  margin-top: 2px;
}

.widget--rovid .widget-accordion__item__body__content p {
  font-size: 90%;
}

.widget--rovid .widget-accordion__item__body__content p + p {
  margin-top: 15px;
}

.wh-rovid-switchad {
  flex: none;
  order: 4;
}
.wh-rovid-switchad .vjs-icon-placeholder::before {
  content: "AD";
  position: static;
  font-size: inherit;
  line-height: inherit;
}

.wh-rovid-switchad .vjs-control-text::before {
  display: block;
  content: "Audio descriptie";
}