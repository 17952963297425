.widget-video__requireconsent__coloroverlay {
  background-color: #428619;
  mix-blend-mode: darken;
}

.widget-video__requireconsent__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* needed for IE */
  color: #FFFFFF;
  font: 18px var(--rtd-text-fontfamily);
}

.widget-video__requireconsent__text__heading {
  color: #FFFFFF;
  font: var(--rtd-h2-font);
  margin-bottom: 8px;
}

.widget-video__requireconsent__accept {
  text-decoration: underline;
  cursor: pointer;
}

.widget-video__requireconsent__coloroverlay,
.widget-video__requireconsent__text {
  opacity: 1;
  transition: opacity 0.5s;
}