/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
.widget-projects {
  grid-column: 1/span 27;
  background-color: #F3F2F4;
  padding-top: 30px;
  padding-bottom: 60px;
}

.widget-projects {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-projects__title {
  grid-column: 3/26;
  margin-bottom: 30px;
  color: var(--tagcolor-darkpurple);
}

.widget-projects__content {
  grid-column: 3/26;
}

.widget-projects__content > * {
  background-color: #FFFFFF;
  box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 500px) {
  .widget-projects__content > * {
    flex: 0 0 100%; /* only used by carrousel mode for mobile */
  }
}
@media (min-width: 501px) {
  .widget-projects__content {
    display: flex;
    flex-flow: row wrap;
    margin-left: -30px;
  }
  .widget-projects__content > * {
    margin-bottom: 20px;
    margin-left: 30px;
  }
}
/*
Most iPad's currently have 768px on the small side
*/
@media only screen and (min-width: 501px) and (max-width: 799px) {
  .widget-projects__content > * {
    flex: 0 0 auto;
    width: calc(50% - 30px);
  }
  /*
    .widget-blocks--carrousel .widget-projects__content > *
    {
      width: calc(100% / 2 - 30px / 2);
    }
  */
  .widget-projects__content > * {
    margin-bottom: 20px;
  }
  .blockswidget__items {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
}
@media only screen and (min-width: 800px) {
  .widget-projects__content > * {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 30px);
  }
  /*
    .widget-blocks--carrousel .widget-projects__content > *
    {
      width: calc(100% / 3 - 30px / 3 * 2);
    }
  */
  .widget-projects__content > * {
    margin-bottom: 20px;
  }
  .blockswidget__items {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
}
@media (max-width: 1199px) {
  .widget-projects__content {
    padding: 10px; /* for the box-shadow */
    grid-column: 3/26;
    height: 100%;
    display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }
  .widget-projects__content {
    flex-wrap: nowrap !important;
    margin-bottom: 0;
  }
  .widget-projects__content::after {
    content: "";
    padding-right: 100vw;
  }
  .widget-projects__content > * {
    background-color: #FFFFFF;
    margin-bottom: 0;
  }
  .widget-projects__content::-webkit-scrollbar {
    display: none;
  }
  .widget-projects__content {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }
  .widget-projects__content > * {
    scroll-margin-left: 30px;
    scroll-snap-margin-left: 30px;
  }
  /*
      .blockswidget__bullets
      {
        margin-top: 20px;
      }
  */
}
@media only screen and (min-width: 500px) {
  .widget-projects__content > *:nth-child(2n+1) {
    scroll-snap-align: start;
  }
}
@media only screen and (min-width: 800px) {
  .widget-projects__content > *:nth-child(3n+1) {
    scroll-snap-align: start;
  }
}