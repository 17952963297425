/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*

Button:
- link
- outline
- solid


Defining a button
    a.wh-form-button
    button.wh-form-button
    *.wh-form-button

Layout modifyers:
    .wh-form-button--outline - Overrides the default solid style of the button
    .wh-form-button--solid

Communicating intent (icons):
    .wh-form__button--backward
    .wh-form__button--forward

*/
.regio__content .simplebutton {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  outline-offset: var(--button-focus-outline-offset);
  cursor: pointer;
  background-color: var(--button-outline-background);
  border: var(--button-outline-border);
  color: var(--button-outline-textcolor);
  --button-outline-border: 1px solid var(--tagcolor-darkpurple);
  --button-outline-textcolor: var(--tagcolor-darkpurple);
  --button-outline-hover-background: var(--tagcolor-darkpurple);
  border-radius: 3px;
  height: 44px;
  font-weight: normal;
  padding: 0 13px 0 13px;
}
.regio__content .simplebutton:focus {
  outline: var(--button-focus-outline);
}
.regio__content .simplebutton:hover {
  background: var(--button-outline-hover-background);
  color: var(--button-outline-hover-textcolor);
  text-decoration: none;
}
.regio__content .simplebutton .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}
.regio__content .simplebutton::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: var(--fa-arrow-to-right);
  font-size: 18px;
  margin-left: 20px;
}

a.simplebutton.simplebutton:focus,
.wh-form__button.wh-form__button:focus,
select:not(.wh-rtd__toolbarstyle):focus {
  outline: var(--button-focus-outline);
  outline-offset: var(--button-focus-outline-offset);
}

.page-header {
  --button-background: var(--themecolor-pink);
  --button-hover-background: var(--themecolor-pink-hover);
}
.page-header .simplebutton {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  outline-offset: var(--button-focus-outline-offset);
  cursor: pointer;
  background: var(--button-background);
  color: #FFFFFF;
  border: 0;
}
.page-header .simplebutton:focus {
  outline: var(--button-focus-outline);
}
.page-header .simplebutton:hover {
  background: var(--button-hover-background);
  color: #FFFFFF;
  text-decoration: none;
}
.page-header .simplebutton .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}
.page-header .simplebutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px;
}

.page-header__textcontent__text .wh-form__button {
  white-space: normal;
  word-break: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
}

.wh-form__button {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  outline-offset: var(--button-focus-outline-offset);
  cursor: pointer;
}
.wh-form__button:focus {
  outline: var(--button-focus-outline);
}

.wh-form__button:not(.wh-form__button--solid),
.wh-form__button--outline {
  background-color: var(--button-outline-background);
  border: var(--button-outline-border);
  color: var(--button-outline-textcolor);
}
.wh-form__button:not(.wh-form__button--solid):hover,
.wh-form__button--outline:hover {
  background: var(--button-outline-hover-background);
  color: var(--button-outline-hover-textcolor);
  text-decoration: none;
}
.wh-form__button:not(.wh-form__button--solid) .wh-form__button--disabled,
.wh-form__button--outline .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form__buttongroup .wh-form__button {
  background-color: var(--button-outline-background);
  border: var(--button-outline-border);
  color: var(--button-outline-textcolor);
}
.wh-form__buttongroup .wh-form__button:hover {
  background: var(--button-outline-hover-background);
  color: var(--button-outline-hover-textcolor);
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form__buttongroup .wh-form__button--next,
.wh-form__buttongroup .wh-form__button--submit {
  background: var(--button-background);
  color: #FFFFFF;
  border: 0;
}
.wh-form__buttongroup .wh-form__button--next:hover,
.wh-form__buttongroup .wh-form__button--submit:hover {
  background: var(--button-hover-background);
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button--next .wh-form__button--disabled,
.wh-form__buttongroup .wh-form__button--submit .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form__button.wh-form__button--solid,
.wh-form__button.wh-form__uploadfieldselect {
  background: var(--button-background);
  color: #FFFFFF;
  border: 0;
}
.wh-form__button.wh-form__button--solid:hover,
.wh-form__button.wh-form__uploadfieldselect:hover {
  background: var(--button-hover-background);
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__button.wh-form__button--solid .wh-form__button--disabled,
.wh-form__button.wh-form__uploadfieldselect .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form .wh-form__button {
  height: var(--formcontrols-height);
}

.wh-form__navbuttons .wh-form__button {
  height: var(--button-height);
}

.wh-form__button--arrow::before,
.spc-textbutton--arrow::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 13px;
  margin-right: 10px;
}

.wh-form__button--backward::before,
.wh-form .wh-form__button--previous::before,
.spc-textbutton--backward::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-arrow-left);
  font-size: 13px;
}

.wh-form__button--backward::before,
.spc-textbutton--backward::before {
  margin-right: 15px;
}

.wh-form .wh-form__button--previous::before {
  margin-right: auto;
}

.spc-textbutton .wh-form__button--backward::before {
  margin-right: 15px;
}

.wh-form__button--forward::after,
.wh-form .wh-form__button--next::after,
.wh-form .wh-form__button--submit::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 13px;
  vertical-align: middle;
  margin-left: 35px;
}

.spc-textbutton--forward::before,
.widget-news__morelink::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px;
}

.widget-news__morelink::before {
  font-size: 17px;
}

.spc-textbutton .wh-form__button--forward::after {
  margin-left: 15px;
}

.widget-cta .wh-form__button--forward::after {
  margin-left: 70px;
}

.wh-form__button--next,
.wh-form__button--submit {
  justify-content: space-between;
}

.wh-form__button--purple {
  border-color: var(--tagcolor-darkpurple);
  color: var(--tagcolor-darkpurple);
}

form.wh-form--submitting .wh-form__button--submit {
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}

.spc-textbutton {
  color: var(--button-themecolor);
  text-decoration: underline;
}

.spc-textbutton:hover {
  color: var(--button-themecolor-hover);
  text-decoration: none;
}

.spc-textbutton.wh-form__button--blue {
  color: var(--themecolor-blue);
}

.spc-textbutton.wh-form__button--blue:hover {
  color: var(--themecolor-blue-hover);
}

.spc-textbutton.wh-form__button--pink {
  color: var(--themecolor-pink);
}

.spc-textbutton.wh-form__button--darkblue:hover {
  color: var(--themecolor-pink-hover);
}

.spc-textbutton.wh-form__button--green {
  color: var(--themecolor-green);
}

.spc-textbutton.wh-form__button--green:hover {
  color: var(--themecolor-green-hover);
}

.spc-textbutton.wh-form__button--purple {
  color: var(--themecolor-purple);
}

.spc-textbutton.wh-form__button--purple:hover {
  color: var(--themecolor-purple-hover);
}

.wh-form__button--outline.wh-form__button--blue {
  --button-outline-background: #FFFFFF;
  --button-outline-border: 1px solid var(--themecolor-blue);
  --button-outline-textcolor: var(--themecolor-blue);
  --button-outline-hover-background: var(--themecolor-blue);
}

.wh-form__button--outline.wh-form__button--pink {
  --button-outline-background: #FFFFFF;
  --button-outline-border: 1px solid var(--themecolor-pink);
  --button-outline-textcolor: var(--themecolor-pink);
  --button-outline-hover-background: var(--themecolor-pink);
}

.wh-form__button--outline.wh-form__button--green {
  --button-outline-background: #FFFFFF;
  --button-outline-border: 1px solid var(--themecolor-green);
  --button-outline-textcolor: var(--themecolor-green);
  --button-outline-hover-background: var(--themecolor-green);
}

.wh-form__button--outline.wh-form__button--purple {
  --button-outline-background: #FFFFFF;
  --button-outline-border: 1px solid var(--themecolor-purple);
  --button-outline-textcolor: var(--themecolor-purple);
  --button-outline-hover-background: var(--themecolor-purple);
}

.wh-form__button--solid.wh-form__button--blue {
  --button-background: var(--themecolor-blue);
  --button-hover-background: var(--themecolor-blue-hover);
}

.wh-form__button--solid.wh-form__button--pink {
  --button-background: var(--themecolor-pink);
  --button-hover-background: var(--themecolor-pink-hover);
}

.wh-form__button--solid.wh-form__button--green {
  --button-background: var(--themecolor-green);
  --button-hover-background: var(--themecolor-green-hover);
}

.wh-form__button--solid.wh-form__button--purple {
  --button-background: var(--themecolor-purple);
  --button-hover-background: var(--themecolor-purple);
}

/*
.widget-cta
.widget-richcontent
*/
.widget--background .wh-form__button.wh-form__button,
.widget--withdarkbg .wh-form__button.wh-form__button {
  --button-background: transparent;
  --button-outline-background: transparent;
  --button-outline-border: 1px solid #FFFFFF;
  --button-outline-textcolor: #FFFFFF;
  --button-outline-hover-background: transparent;
}

.widget--background-blue {
  --button-focus-outline: 3px solid rgba(255,255,255,0.5);
  --button-focus-outline-offset: 5px;
}

.widget--background-blue p a:focus {
  padding: 6px 8px;
  margin-left: -8px;
  margin-right: -8px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  outline: 2px solid rgb(0, 0, 0);
  outline-offset: 0;
}

.widget--background .wh-form__button.wh-form__button:hover,
.widget--withdarkbg .wh-form__button.wh-form__button:hover {
  --button-outline-hover-background: rgba(0, 0, 0, 0.2);
}

.widget--background .spc-textbutton,
.widget--withdarkbg .spc-textbutton {
  color: #FFFFFF;
}