/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget-news__item {
  text-decoration: none;
  position: relative;
  z-index: 0;
  padding-bottom: 15px;
}

.widget-news__item__top {
  margin-bottom: 15px;
  position: relative;
  padding-top: 66.57%;
  background-color: #EEEEEE;
  overflow: hidden;
}

.widget-news__item__top__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 66.57%;
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}

.widget-news__item__top__image.b-lazy {
  opacity: 0;
}

.widget-news__item__top__image.b-lazy.b-loaded {
  opacity: 1;
}

.widget-news__item__title,
.widget-news__item__when {
  padding-left: 15px;
  padding-right: 15px;
}

.widget-news__item__region {
  color: var(--tagcolor-darkpurple);
  font: 300 13px var(--rtd-text-fontfamily);
  text-transform: uppercase;
  margin-bottom: 4px;
}

.widget-news__item__title {
  font: var(--rtd-heading-weight) 20px/24px var(--rtd-text-fontfamily);
  color: var(--tagcolor-darkpink);
}

.widget-news__item__when {
  margin-top: 3px;
  font: bold 17px var(--rtd-text-fontfamily);
  color: #3F3F3F;
}

/*
description removed in 8-may-2024

.widget-news__item__description
{
  margin-top: 5px;
}

.widget-news__item__description
{
  font: 300 17px/22px var(--rtd-text-fontfamily);
  color: #000000;

  / *
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  * /
  display: -webkit-box; // ONLY with -webkit-box
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.widget-news__item__description + *
{
  margin-top: 15px;
}
*/
.widget-news__item:hover .widget-news__item__top__image {
  transform: scale(1.1);
}
.widget-news__item:hover .widget-news__item__title {
  color: var(--tagcolor-darkpurple);
}

.widget-news__moreitems__content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 15px 18px;
}

.widget-news__moreitems__item {
  display: block;
  padding-bottom: 15px;
  text-decoration: none;
}

/*
.widget-news__moreitems__item + .widget-news__moreitems__item
{
  border-top: 1px solid #D8D8D8;
  padding-top: 15px;
}
*/
.widget-news__moreitems__item {
  border-bottom: 1px solid #D8D8D8;
  padding-top: 15px;
  padding-bottom: 15px;
}

.widget-news__moreitems__item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-news__moreitems__item__title {
  font: var(--rtd-heading-weight) 20px/22px var(--rtd-text-fontfamily);
  color: var(--tagcolor-darkpink);
}

.widget-news__moreitems__item:hover .widget-news__moreitems__item__title {
  color: var(--tagcolor-darkpurple);
}

.widget-news__moreitems__item__when {
  margin-top: 3px;
  font: var(--rtd-heading-weight-bold) 17px var(--rtd-text-fontfamily);
  color: #3F3F3F;
}