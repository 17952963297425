/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*

Views
- Mobile         -> stacked, image above
- Tablet/desktop -> Image floating top right

*/
.widget-cta {
  z-index: 10;
}
.widget-cta.widget--background-blue {
  background-color: #3a7aa5;
}
.widget-cta.widget--background-pink {
  background-color: #c10061;
}
.widget-cta.widget--background-green {
  background-color: #428619;
}
.widget-cta.widget--background-purple {
  background-color: #55095f;
}

/*
  .widget-cta.widget--background-darkblue
, .widget-cta.widget--background-orange
, .widget-cta.widget--background-red
*/
.widget-cta.widget--withdarkbg .widget__title, .widget-cta.widget--withdarkbg .widget__subtitle,
.widget-cta.widget--withdarkbg p, .widget-cta.widget--withdarkbg ol, .widget-cta.widget--withdarkbg ul, .widget-cta.widget--withdarkbg h1, .widget-cta.widget--withdarkbg h2, .widget-cta.widget--withdarkbg h3 {
  color: #FFFFFF;
}
.widget-cta.widget--withdarkbg .rtdcontent a {
  color: #FFFFFF !important;
  text-decoration: underline;
}

.widget-cta--withbackground .widget-cta__text {
  padding-bottom: 30px;
}

.widget-cta__image {
  background-size: cover;
}

.widget-cta__subtitle + .widget-cta__title {
  margin-top: 4px;
}

.widget-cta__text > * + .widget-cta__description,
.widget-cta__text > * + .widget-cta__button {
  margin-top: 25px;
}

@media (max-width: 699px) {
  .widget-cta {
    grid-column: 1/span 27;
  }
  .widget-cta__text {
    padding-top: var(--panels-padding-top);
    padding-bottom: var(--panels-padding-bottom);
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
}
@media (min-width: 700px) {
  .widget-cta {
    grid-column: 1/span 27;
    display: grid;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-cta__content {
    grid-column: 1/span 27;
    display: grid;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-cta__text {
    padding-top: var(--panels-padding-top);
    padding-bottom: var(--panels-padding-bottom);
  }
  .widget-cta--focusleft .widget-cta__text {
    grid-column: 15/span 11;
    grid-row: 1;
  }
  .widget-cta--focusleft .widget-cta__imagewrapper {
    grid-column: 1/span 13;
    grid-row: 1;
  }
  .widget-cta--focusright .widget-cta__text {
    grid-column: 3/span 11;
    grid-row: 1;
  }
  .widget-cta--focusright .widget-cta__imagewrapper {
    grid-column: 15/span 13;
    grid-row: 1;
  }
  .widget-cta__image {
    width: 100%;
    height: 100%;
  }
}