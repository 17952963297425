/* Colors for buttons ------------------------------------------*/
/* -------------------------------------------------------------*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
Student Union
--autosuggest-bgcolor:       #FFFFFF;
--autosuggest-bgcolor-hover: #EEEEEE;
--autosuggest-radius:        10px;
--autosuggest-textcolor:     #000000;
--autosuggest-item-hpad:     26px;
--autosuggest-item-vpad:     4px;
*/
:root {
  --autosuggest-bgcolor: #F7F7F7;
  --autosuggest-bgcolor-hover: #DDDDDD;
  --autosuggest-textcolor: #333333;
  --autosuggest-radius: 0;
  --autosuggest-item-hpad: 18px;
  --autosuggest-item-vpad: 10px;
}

.wh-autocomplete-values li {
  list-style: none;
}

.wh-autocomplete-values li.suggestion {
  margin: 0;
  padding: var(--autosuggest-item-vpad) var(--autosuggest-item-hpad);
  cursor: pointer;
}

.wh-autocomplete-values li.suggestion:hover {
  background-color: var(--autosuggest-bgcolor-hover);
}

.wh-autocomplete-values li.suggestion:focus {
  outline: 0;
  background-color: var(--autosuggest-bgcolor-hover);
}

.wh-autocomplete-values li.suggestion .match {
  font-weight: bold;
}

.searchsidebar__query-container {
  --autosuggest-item-hpad: 15px;
  --autosuggest-item-vpad: 5px;
  position: relative;
}
.searchsidebar__query-container .wh-autocomplete-container {
  width: 100%; /* inherit the height of our flex container (and force a wrap so we are shown below the input) */
  position: absolute;
  top: 15px;
}
.searchsidebar__query-container .wh-autocomplete-container::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 10px 12px rgba(255, 255, 255, 0.8);
}
.searchsidebar__query-container .wh-autocomplete-values {
  position: relative;
  margin: 0;
  padding: 0;
  padding-top: 28px;
  padding-bottom: 8px;
  width: 100%;
  border: 1px solid #AAAAAA;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.07);
  background-color: var(--autosuggest-bgcolor);
  color: var(--autosuggest-textcolor);
  font: 18px var(--rtd-text-fontfamily);
}

.newsitemsoverview__searchpanel {
  display: flex;
  flex-wrap: wrap;
}
.newsitemsoverview__searchpanel .wh-autocomplete-container {
  width: 100%;
  z-index: 1;
}

.spccomp-searchpanel .wh-autocomplete-container,
.newsitemsoverview__searchpanel .wh-autocomplete-container,
.page-header__sitesearch-wrapper .wh-autocomplete-container {
  position: relative;
  padding-left: 0;
}
.spccomp-searchpanel .wh-autocomplete-values,
.newsitemsoverview__searchpanel .wh-autocomplete-values,
.page-header__sitesearch-wrapper .wh-autocomplete-values {
  position: absolute;
  width: 100%;
  border: 1px solid #AAAAAA;
  border-top: 0;
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.07);
  background-color: var(--autosuggest-bgcolor);
  color: var(--autosuggest-textcolor);
  font: 19px var(--rtd-text-fontfamily);
  margin: 0;
  padding-top: calc(var(--autosuggest-item-hpad) * 0.5);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0 0 var(--autosuggest-radius) var(--autosuggest-radius);
}
.spccomp-searchpanel .wh-autocomplete-values:empty::before,
.newsitemsoverview__searchpanel .wh-autocomplete-values:empty::before,
.page-header__sitesearch-wrapper .wh-autocomplete-values:empty::before {
  content: "Geen matches";
  padding-left: 25px;
}
.spccomp-searchpanel .wh-autocomplete-values li.suggestion,
.newsitemsoverview__searchpanel .wh-autocomplete-values li.suggestion,
.page-header__sitesearch-wrapper .wh-autocomplete-values li.suggestion {
  padding-left: calc(var(--autosuggest-item-hpad) + 40px);
}
.spccomp-searchpanel .wh-autocomplete-values li.suggestion::before,
.newsitemsoverview__searchpanel .wh-autocomplete-values li.suggestion::before,
.page-header__sitesearch-wrapper .wh-autocomplete-values li.suggestion::before {
  position: absolute;
  left: var(--autosuggest-item-hpad);
  color: #767676;
  font-size: 19px;
  margin-right: 23px;
  content: "\f002";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

.page-header__sitesearch-wrapper .wh-autocomplete-values {
  padding-top: 40px;
  padding-bottom: 5px;
  margin-top: -27px;
  border-radius: 0 0 8px 8px;
}